let current = 1
const ids = new Map()

export const generateKey = (object: any) => {
  if (ids.has(object)) {
    return ids.get(object)
  } else {
    const id = String(current++)
    ids.set(object, id)
    return id
  }
}
