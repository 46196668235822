/** @jsx jsx */
import { useMemo, useState } from 'react'
// @ts-ignore
import { jsx, Box, Flex, ThemeUIStyleObject } from 'theme-ui'
import { Hamburger } from '~/components/hamburger'
import useHeaderScrolling from '~/hooks/use-header-scolling'
import { Logo } from '~/components/logo'
import { HeaderVariantType } from '~/@types/models'
import HeaderWrapper from '~/components/header/header-wrapper'
import HeaderCartOpener from '~/components/header/header-cart-opener'
import MenuMobile from '~/components/menu-mobile'
import { useMenu } from '~/context/menu-context'
import MobileContent from './mobileContent'
import { MenuMobileWithImages } from '../menu-mobile/withImages'
import { MenuMobileProvider } from '../menu-mobile/withImages/context'

type Props = {
  headerVariant: HeaderVariantType
  sxProps?: ThemeUIStyleObject
}

const HeaderMobile = ({ headerVariant }: Props) => {
  const { isScrolling } = useHeaderScrolling()
  const [isNavOpen, setIsNavOpen] = useState(false)
  const isDarkSolid = headerVariant === 'dark_solid'
  const lighters = ['light', 'light_blue_gradient', 'transparent']

  const styleSettings = useMemo(() => {
    return {
      logo: {
        color: isDarkSolid ? 'main' : 'initial',
      },
      leftMenu: {
        isLight: (lighters.includes(headerVariant) && !isScrolling) || (isDarkSolid && 'main'),
      },
    }
  }, [headerVariant, isScrolling])

  return (
    <MenuMobileProvider>
      <HeaderWrapper display="MOBILE" headerVariant={headerVariant}>
        <MobileContent headerVariant={headerVariant}>
          {!isNavOpen && (
            <Hamburger
              isOpenNav={isNavOpen}
              setIsOpenNav={setIsNavOpen}
              isLight={styleSettings.leftMenu.isLight}
            />
          )}
        </MobileContent>
        {isNavOpen && (
          <MenuMobileWithImages isScrolling={isScrolling}>
            <Hamburger isOpenNav={isNavOpen} setIsOpenNav={setIsNavOpen} isLight={false} />
          </MenuMobileWithImages>
        )}
      </HeaderWrapper>
    </MenuMobileProvider>
  )
}

export default HeaderMobile
