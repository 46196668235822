/** @jsx jsx */
import { jsx, SxStyleProp, Box } from 'theme-ui'
import { FC, SVGProps } from 'react'

type Props = SVGProps<SVGSVGElement> &
  SxStyleProp & {
    color?: string
    width?: number
    height?: number
  }

export const PlusMenuExpand: FC<Props> = ({ color, height = 16, width = 13, ...rest }) => {
  return (
    <Box
      as="svg"
      {...rest}
      // @ts-ignore
      width={width}
      height={height}
      viewBox="0 0 16 16"
    >
      <polygon
        fill={color}
        points="9.04346875,6.95653125 9.04346875,0 6.95653125,0 6.95653125,6.95653125 0,6.95653125 0,9.04346875 6.95653125,9.04346875 6.95653125,16 
			9.04346875,16 9.04346875,9.04346875 16,9.04346875 16,6.95653125"
      />
      <rect y="6.95653125" width="16" height="2.08696875" />
    </Box>
  )
}
