import { ProductVariantType } from '~/@types/models'
import { rugSizes } from '~/utils/product'

const calculatePreorderMessage = () => {
  const today = new Date()
  const dayOfMonth = today.getDate()
  const nextMonth = new Date()
  nextMonth.setMonth(today.getMonth() + 1)
  const nextMonthName = nextMonth.toLocaleString('en-EN', { month: 'long' })
  const currentYear = today.getFullYear()

  if (dayOfMonth >= 1 && dayOfMonth <= 10) {
    return `Expected to Ship Early ${nextMonthName} ${currentYear}`
  } else {
    return `Expected to Ship Late ${nextMonthName} ${currentYear}`
  }
}

const isRunnerRug = (variant: ProductVariantType) => {
  return variant.selectedOptions.find(
    option =>
      option.name === 'Size' &&
      (option.value === rugSizes.runnersSize2x10 ||
        option.value === rugSizes.runnersSize3x12 ||
        option.value === rugSizes.runnersSize2_2x10)
  )
}

const isCircularRug = (variant: ProductVariantType) => {
  return variant.selectedOptions.find(
    option => option.name === 'Size' && option.value === rugSizes.circularSize6
  )
}

export { calculatePreorderMessage, isRunnerRug, isCircularRug }
