/** @jsx jsx */
import { ThemeUIStyleObject, jsx } from 'theme-ui'
import { Fragment } from 'react'
import HeaderMobile from '~/components/header/mobile'
import HeaderDesktop from '~/components/header/desktop'
import { graphql, useStaticQuery } from 'gatsby'
import { HeaderVariantType, NavLinkType } from '~/@types/models'

type Props = {
  variant?: HeaderVariantType
  stickyMenu?: boolean
}

const menuQuery = graphql`
  query menuQuery {
    menu: settingsJson(templateKey: { eq: "menu-v2" }) {
      list {
        name
        visible
        link
        target
        divider
        customStyles
        submenu {
          name
          link
          target
          divider
          type
          imgSrc
          submenu {
            name
            link
            target
            divider
            bottomLevel
            submenu {
              name
              link
              bottomLevel
            }
          }
        }
      }
    }
  }
`

type QueryResult = {
  menu: {
    list: NavLinkType[]
  }
}

const Header = ({ variant = 'dark', stickyMenu = false }: Props) => {
  const {
    menu: { list: menu },
  } = useStaticQuery<QueryResult>(menuQuery)

  const filtered = menu.filter(item => item.visible)

  return (
    <Fragment>
      <HeaderDesktop headerVariant={variant} stickyMenu={stickyMenu} />
      <HeaderMobile headerVariant={variant} />
    </Fragment>
  )
}

export default Header
