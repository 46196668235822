import { filter, find, head, not, path, pipe, prop, propEq, sort } from 'ramda'
import { formatMoney } from '~/utils/format'
import { CheckoutLineItem } from '~/@types/models'
import { getArray } from '~/utils/common'
import { isValidTradeCustomerDiscount } from '~/utils/is-valid-trade-customer-discount'

const VALUE_OF_CUSTOMER_DISCOUNT = 25

export const getPriceWithTradeDiscount = (amount: number): number =>
  ((100 - VALUE_OF_CUSTOMER_DISCOUNT) / 100) * amount

const getVariantAmountNumber = pipe(path(['price', 'amount']), Number)
const getPriceAmountNumber = pipe(path(['price', 'amount']), Number)
const getCompareAtPriceAmountNumber = pipe(path(['compareAtPrice', 'amount']), Number)

export const getSelectedSize = pipe(prop('selectedOptions'), getArray, find(propEq('name', 'Size')))

export const isSwatch = pipe(getSelectedSize, value => {
  if (!value) {
    return false
  }
  return value.value === 'Swatch'
})

const isNotSwatch = pipe(isSwatch, not)

const getSelectedSale = pipe(
  prop('selectedOptions'),
  getArray,
  find(option => option.value.includes('FINAL SALE'))
)

const isSale = pipe(getSelectedSale, value => {
  if (!value) {
    return false
  }
  return true
})

const sortVariantsByAmount = sort((a, b) => {
  const variantAmountA = getVariantAmountNumber(a)
  const variantAmountB = getVariantAmountNumber(b)
  return variantAmountA - variantAmountB
})

const sortVariantsWithoutSwatch = pipe(sortVariantsByAmount, filter(isNotSwatch))

const getLowestPrice = (list: boolean, isTradeCustomer: boolean) =>
  pipe(
    data => {
      if (list && data.length === 1) {
        return pipe(head)(data)
      }
      if (list) {
        return pipe(sortVariantsWithoutSwatch, head)(data)
      }
      return data
    },
    data => {
      const price = getPriceAmountNumber(data)
      const compareAtPrice = getCompareAtPriceAmountNumber(data)
      if (isTradeCustomer && (!compareAtPrice || compareAtPrice === price)) {
        return getPriceWithTradeDiscount(price)
      }
      return path(['price', 'amount'])(data)
    },
    String,
    formatMoney
  )

const setIsSale = (list: boolean) =>
  pipe(
    data => {
      if (list) {
        return pipe(sortVariantsWithoutSwatch, head)(data)
      }
      return data
    },
    data => {
      const price = getPriceAmountNumber(data)
      const compareAtPrice = getCompareAtPriceAmountNumber(data)
      return price < compareAtPrice
    }
  )

const getCompareAtPrice = (list: boolean) =>
  pipe(
    data => {
      if (list) {
        return pipe(sortVariantsWithoutSwatch, head)(data)
      }
      return data
    },
    path(['compareAtPrice', 'amount']),
    String,
    formatMoney
  )

const lineItemPrice = (isTrade: boolean, item: CheckoutLineItem) => {
  const onSale = pipe(path(['variant']), isSale)(item)

  const priceNew = pipe(path(['variant', 'price', 'amount']), Number, amount => {
    const isValidTradeDiscount = isValidTradeCustomerDiscount({
      isTradeCustomer: isTrade,
      productType: item.productType || item.title,
    })

    if (!onSale && isValidTradeDiscount) {
      return getPriceWithTradeDiscount(amount)
    }

    return amount
  })(item)
  return priceNew * item.quantity
}

export const lineItemsSummary = (isTrade: boolean, items: CheckoutLineItem[]): number => {
  const initialValue = 0

  return items.reduce((sum, obj: CheckoutLineItem) => {
    const isTradeCustomer = !obj.variant.compareAtPrice ? isTrade : !obj.variant.compareAtPrice
    const price = lineItemPrice(isTradeCustomer, obj)
    return sum + price
  }, initialValue)
}

export { getLowestPrice, setIsSale, getCompareAtPrice, lineItemPrice, isSale }
