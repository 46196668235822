import React from 'react'
import './styles.css'

export interface AspectRatioProps {
  ratio?: number
  children: React.ReactNode
}

function calculateAspectRatioPercentage(ratioDecimal: number) {
  if (ratioDecimal <= 0) {
    throw new Error('Aspect ratio must be a positive number.')
  }

  const percentage = (1 / ratioDecimal) * 100
  return percentage.toFixed(2) + '%'
}

const AscpetRatio = ({ ratio, children }: AspectRatioProps) => {
  if (!ratio) {
    return <div>{children}</div>
  }

  const paddingTop = calculateAspectRatioPercentage(ratio)
  return (
    <div
      className="aspect-ratio"
      style={{
        height: 0,
        paddingTop,
      }}
    >
      <div className="aspect-ratio-inner">{children}</div>
    </div>
  )
}

export default AscpetRatio
