import React, { createContext, FC, useContext, useEffect, useState } from 'react'
import { HeaderVariantType } from '~/@types/models'
import { useMenu } from '~/context/menu-context'

type HeaderContextType = {
  variant: HeaderVariantType
  subMenuState: [number | null, React.Dispatch<React.SetStateAction<number | null>>] | undefined
  subMenuExpandedItemState:
    | [string | null, React.Dispatch<React.SetStateAction<string | null>>]
    | undefined
}

const HeaderContext = createContext<HeaderContextType>({
  variant: 'default',
  subMenuState: undefined,
  subMenuExpandedItemState: undefined,
})

type Props = Pick<HeaderContextType, 'variant'>

const HeaderContextProvider: FC<Props> = ({ variant, children }) => {
  const subMenuState = useState<number | null>(null)
  const subMenuExpandedItemState = useState<string | null>('')

  useEffect(() => {
    if (subMenuState[0] === 1 && subMenuExpandedItemState[0]?.length === 0) {
      subMenuExpandedItemState[1]('BY SIZE')
    }
  }, [subMenuState, subMenuExpandedItemState])

  return (
    // eslint-disable-next-line react/react-in-jsx-scope
    <HeaderContext.Provider
      value={{
        variant,
        subMenuState,
        subMenuExpandedItemState,
      }}
    >
      {children}
    </HeaderContext.Provider>
  )
}

const useHeaderContext = () => {
  const context = useContext(HeaderContext)

  if (context === undefined) {
    throw new Error('useHeaderContext must be used within a HeaderContext')
  }

  return context
}

export { useHeaderContext, HeaderContextProvider }
