import { useCallback } from 'react'

type InputType = {
  availableQuantity?: number
  selectedQuantity?: number
}

const useQuantityNote = ({ availableQuantity = 0, selectedQuantity = 1 }: InputType) => {
  const getNoteBySelectedQuantity = useCallback(
    (availableQuantity: number, selectedQuantity: number) => {
      const moreThanAvailable = selectedQuantity > availableQuantity
      const message = `${availableQuantity} item(s) available. `

      if (moreThanAvailable) {
        return `Only ${message}The additional items wil be pre-ordered`
      }

      return null
    },
    []
  )

  const message = getNoteBySelectedQuantity(availableQuantity, selectedQuantity)

  return {
    message,
    getNoteBySelectedQuantity,
  }
}

export default useQuantityNote
