import { useMemo } from 'react'
import { useCartContext } from '~/context/cart-context'

declare global {
  interface Window {
    ga: Record<string, Function>
  }
}

const parserCookie = (target: string) => {
  if (typeof document === 'undefined') {
    return ''
  }

  const cookies = document.cookie.split(';')
  const cookie = cookies.find(cookie => cookie.includes(target))
  if (!cookie) {
    return ''
  }
  const cookieValue = encodeURIComponent(cookie?.split('=')[1] || '')
  const url = `&${target}=${cookieValue}`

  return url
}

const getLinkerParameter = () => {
  if (!window.ga || !window.ga.getAll) {
    return null
  }

  const trackers = window.ga.getAll()

  return trackers.length > 0 ? trackers[0].get('linkerParam') : null
}

const useCheckoutLink = (): string | null => {
  const { checkout } = useCartContext()

  const queryParamFromCookie = parserCookie('rmStore')

  return useMemo(() => {
    if (!checkout) {
      return null
    }

    const linkerParam = getLinkerParameter()

    if (!linkerParam) {
      // todo - track error
      return `${checkout.webUrl}${queryParamFromCookie}`
    }

    return `${checkout.webUrl}&${linkerParam}${queryParamFromCookie}`
  }, [checkout])
}

export default useCheckoutLink
