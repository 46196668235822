import { intersection } from 'ramda'
import {
  ImageType,
  ProductItemComponentType,
  ProductVariantType,
  ShopifyImageSource,
} from '~/@types/models'
import { isCircularRug, isRunnerRug } from '~/utils/product-variant'

export const isNotSwatch = (value: string) => String(value).indexOf('Swatch') < 0
export const isFabric = (product: ProductItemComponentType) => product.productType === 'Fabric'
export const isAntiqueRug = (product: ProductItemComponentType) =>
  product.productType.toUpperCase() === 'ANTIQUE RUGS'

export const isCreamerArt = (tags: string[]) => tags.includes('craemer-artwork')

export const isPrintArt = (tags: string[]) => tags.includes('print-art')

const productsWithException = ['product-rug']

export const isException = (product: ProductItemComponentType) => {
  return product.tags && intersection(product.tags, productsWithException).length > 0
}

const runnersType = '-RUN-'
const circularType = '-CIR-'
const overheadType = '-OH-'

export const findImage = (type: string, images: ReadonlyArray<ImageType | ShopifyImageSource>) =>
  images.find(image => {
    const imageSrc = String(image.originalSrc)
    return imageSrc.indexOf(type) >= 0
  })

export const findRunnersOrCircularImages = (
  product: ProductItemComponentType,
  productVariant: ProductVariantType,
  comesFromRunners: boolean | undefined,
  comesFromCircular: boolean | undefined
) => {
  if (!product.images) {
    return false
  }

  const isRunner = isRunnerRug(productVariant)

  if (isRunner && comesFromRunners) {
    return findImage(runnersType, product.images)
  }

  const isCircular = isCircularRug(productVariant)
  if (isCircular && comesFromCircular) {
    return findImage(circularType, product.images)
  }

  return false
}
