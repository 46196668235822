/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-array-index-key */
import React, { FC, useCallback, MouseEvent, useState, useRef } from 'react'

// @ts-ignore
import { Grid, Box, Text, ThemeUIStyleObject } from 'theme-ui'
import { useOverlayContext } from '~/context/overlay-context'
import useIsTouchDevice from '~/hooks/use-is-touch-device'
import useOnClickOutside from '~/hooks/use-on-click-outside'
import { useHeaderContext } from '~/components/header/header-context'
import { NavItemByType } from '~/components/menu/base/NavItemByType'
import { keyframes } from '@emotion/react'
import { IMenu, IMenuGroup, IMenuImageGroup, IMenuItem } from '~/context/menu-context'
import LinkComponent from '~/components/menu/base/LinkComponent'
import MenuImageGroup from '~/components/menu/base/MenuImageGroup'
import { RiArrowRightSLine } from '@remixicon/react'
import { useCustomerContext } from '~/context/customer-context'
import { useIdentifierModal } from '~/context/identifier-modal'
import { navigate } from 'gatsby'

interface MenuUlLiProps {
  index: number
  searchActive: boolean
  menu: IMenu
}

const DesktopMainMenu: FC<MenuUlLiProps> = ({ menu, index, searchActive }) => {
  const hasSubmenu = menu.menus && menu.menus.length > 0
  const anchorRef = useRef(null)
  const [clickCount, setClickCount] = useState<number>(0)
  // const [openSubMenu, setOpenSubMenu] = useState<number | undefined>()
  const { subMenuState } = useHeaderContext()
  const { isTrade } = useCustomerContext()
  const { customerSegment } = useIdentifierModal()
  const [subMenuActiveIndex, setOpenSubMenu] = subMenuState!
  const { toggleOverlay } = useOverlayContext()
  const isTouchDevice = useIsTouchDevice()
  const menuRef = useRef<HTMLUListElement>(null)

  useOnClickOutside(anchorRef, () => {
    setClickCount(0)
    toggleOverlay(false)
  })

  const onItemClick = useCallback(
    (e: MouseEvent<HTMLAnchorElement>) => {
      if (clickCount === 0 && isTouchDevice) {
        // handling the first click on the link
        e.preventDefault()
        setClickCount(1)
        toggleOverlay(false)
        return
      }

      if (clickCount === 1 && isTouchDevice) {
        setClickCount(0)
        toggleOverlay(false)
      }

      if (!isTouchDevice) {
        setClickCount(0)
        toggleOverlay(false)

        if (menu?.link) {
          navigate(menu.link)
        }
      }
    },
    [hasSubmenu, toggleOverlay, clickCount, setClickCount, isTouchDevice]
  )

  const shouldShowSubMenu = subMenuActiveIndex === index

  return (
    <Box
      className="menu-nav-item"
      ref={menuRef}
      as="nav"
      onMouseEnter={() => {
        if (!searchActive) setOpenSubMenu(index)
      }}
      onMouseLeave={(e: MouseEvent<HTMLDivElement>) => {
        const target = e.relatedTarget as HTMLElement
        const classList = target.classList ? target.classList.contains('menu') : false
        if (target === menuRef.current || classList) return
        setOpenSubMenu(null)
      }}
      style={{
        flex: '1 1 auto',
      }}
    >
      <LinkComponent
        data-comp="main-menu-item"
        className="menu-nav-item-link"
        style={{
          position: 'relative',
          padding: '10px 0px',
          display: 'block',
          background: 'transparent',
          cursor: 'pointer',
          border: 'none',
          fontSize: 'clamp(0.75rem, 0.4381rem + 0.454vw, 1rem)',
          width: '100%',
          textTransform: 'uppercase',
          fontWeight: menu.tradeHighlight && (!isTrade || customerSegment === 'retail') ? 700 : 400,
        }}
        onClick={onItemClick}
        ref={anchorRef}
      >
        {menu.name}
      </LinkComponent>
      {hasSubmenu && <SubMenuWrapper {...menu} visible={shouldShowSubMenu} />}
    </Box>
  )
}

const SubMenuWrapper: FC<
  IMenu & {
    visible: boolean
  }
> = props => {
  const { visible, menus, images, reverseGroups } = props
  const { subMenuState } = useHeaderContext()
  const [, setOpenSubMenu] = subMenuState!
  const fadeIn = keyframes({ from: { opacity: 0 }, to: { opacity: 1 } })
  const fadeOut = keyframes({ from: { opacity: 1 }, to: { opacity: 0 } })
  const visibilityIn = keyframes({ from: { visibility: 'hidden' }, to: { visibility: 'visible' } })
  const visibilityOut = keyframes({ from: { visibility: 'visible' }, to: { visibility: 'hidden' } })

  return (
    <Grid
      onMouseLeave={(e: MouseEvent<HTMLDivElement>) => {
        const target = e.relatedTarget as HTMLElement
        if (target?.classList?.contains('menu-nav-item-link')) return
        setOpenSubMenu(null)
      }}
      className="sub-menu-grid"
      sx={{
        background: 'white',
        color: '#2B2B2B',
        padding: visible ? '24px' : '0',
        display: 'grid',
        width: '100%',
        height: visible ? 'auto' : '0',
        transition: 'all 0.3s fade-in-out',
        animation: `${visible ? fadeIn : fadeOut} 0.3s ease-in-out,`,
        position: 'absolute',
        // minHeight: '100vh', TODO: figure out collapse menu issue
        overflow: 'hidden',
        overflowY: visible ? 'visible' : 'hidden',
        left: 0,
        top: '30px',
        boxShadow: '0px 1px 1px 0px #99999980',
        '& > ul': {
          opacity: visible ? 1 : 0,
          transition: 'all 0.3s fade-in-out',
          animation: `${visible ? visibilityIn : visibilityOut} 0.4s fade-in-out`,
        },
        'li:hover': {
          textDecoration: 'underline',
        },
        'li.menu-image-group:hover img': {
          filter: 'brightness(1.1)',
        },
        button: {
          background: 'transparent',
          border: 'none',
          cursor: 'pointer',
        },
      }}
      gap={24}
      columns={[reverseGroups ? '4fr 2fr' : '2fr 4fr']}
    >
      <Grid gap={24} columns={['1fr 1fr']}>
        {menus?.map(item => (
          <MenuGroup key={'menu' + item.id} {...item} />
        ))}
      </Grid>

      <Grid
        gap={24}
        columns={['repeat(auto-fit, minmax(250px, 1fr))']}
        sx={{
          order: reverseGroups ? -1 : 1,
          img: {
            width: '100%',
            objectFit: 'cover',
            aspectRatio: images?.length === 1 ? '16/9' : '444/448',
          },
        }}
      >
        {images?.map(item => (
          <Box
            as="ul"
            sx={{
              position: 'relative',
              fontSize: '14px;',
            }}
          >
            <li
              style={{
                listStyle: 'none',
                lineHeight: '1.5rem',
                marginBottom: '0.5rem',
              }}
              className="menu-image-group"
            >
              <Text
                as="h4"
                sx={{
                  fontSize: '12px',
                  paddingBottom: 0,
                  color: '#2B2B2B',
                }}
              >
                <LinkComponent
                  link={item.link}
                  style={{
                    position: 'relative',
                    display: 'block',
                    cursor: 'pointer',
                    border: 'none',
                  }}
                >
                  <MenuImageGroup key={'image' + item.id} {...item} />
                </LinkComponent>
              </Text>
            </li>
          </Box>
        ))}
      </Grid>
    </Grid>
  )
}

const MenuGroup: React.FC<IMenuGroup> = group => {
  return (
    <Box
      as="ul"
      className="menu-group"
      sx={{
        position: 'relative',
        fontSize: '14px;',
      }}
    >
      <li
        style={{
          listStyle: 'none',
          lineHeight: '1.5rem',
          marginBottom: '0.5rem',
        }}
      >
        <Text
          as="h4"
          sx={{
            fontSize: '14px',
            paddingBottom: 0,
            color: '#2B2B2B',
            fontWeight: '600',
          }}
        >
          {group.groupName}
        </Text>
      </li>
      {group.menus.map((item, index) => (
        <MenuItem key={index} {...item} />
      ))}
    </Box>
  )
}

const MenuItem: React.FC<IMenuItem> = item => {
  const { subMenuExpandedItemState } = useHeaderContext()
  const [expandedItem, setSubMenuExpandedItemState] = subMenuExpandedItemState!

  const hasSubmenu = item.menus && item.menus.length > 0

  return (
    <li
      style={{
        listStyle: 'none',
        marginLeft: '10px',
      }}
    >
      <LinkComponent
        link={item.link}
        onClick={e => {
          if (hasSubmenu) {
            e.preventDefault()
            setSubMenuExpandedItemState(state => (state === item.name ? null : item.name))
          }
        }}
        style={{
          marginBottom: 15,
          marginTop: 15,
          cursor: 'pointer',
          background: 'transparent',
          border: 'none',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        {item.name}
        {hasSubmenu && (
          <RiArrowRightSLine
            style={{
              fill: '#2B2B2B',
              width: 16,
              height: 16,
              marginLeft: 5,
              transition: 'all 0.3s ease-in-out',
              transform: expandedItem === item.name ? 'rotate(90deg)' : 'rotate(0deg)',
            }}
          />
        )}
      </LinkComponent>

      {hasSubmenu && (
        <ul
          className="sub-menu"
          style={{
            paddingLeft: 20,
            marginTop: 15,
            marginBottom: 15,
            display: expandedItem === item.name ? '' : 'none',
            textTransform: 'none',
          }}
        >
          {item.menus?.map((item, index) => (
            <MenuItem key={index} {...item} />
          ))}
        </ul>
      )}
    </li>
  )
}

export default DesktopMainMenu
