import React, { FC, SVGProps } from 'react'

type Props = SVGProps<SVGSVGElement> & {
  color?: string
}

export const Cross: FC<Props> = ({ color, ...rest }) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18 1.81286L16.1871 0L9 7.18714L1.81286 0L0 1.81286L7.18714 9L0 16.1871L1.81286 18L9 10.8129L16.1871 18L18 16.1871L10.8129 9L18 1.81286Z"
        fill={color}
      />
    </svg>
  )
}
