/** @jsx jsx */
import { space } from '~/gatsby-plugin-theme-ui'
import { Box, jsx, Text } from 'theme-ui'

import { CheckoutLineItem } from '~/@types/models'

import CompareAtPrice from '~/components/compare-at-price'

import { formatMoney } from '~/utils/format'
import { lineItemPrice } from '~/utils/price'
import { useCartContext } from '~/context/cart-context'
import { ProductPrice } from '~/hooks/use-product-variant-info'

type PriceProps = {
  item: CheckoutLineItem
  showTradeCustomerLabel: boolean
  flyout?: boolean
}

export const CartItemPrice = ({ item, showTradeCustomerLabel, flyout }: PriceProps) => {
  const { overlayOpen } = useCartContext()
  const product = {
    ...item,
    ...item.variant,
    ...item.variant.product,
    compareAtPrice:
      item.variant?.compareAtPrice?.amount === item.variant.price.amount
        ? null
        : item.variant.compareAtPrice,
  } as any

  const { cheapestVariantPrice } = new ProductPrice(
    product,
    null,
    false,
    showTradeCustomerLabel,
    item.quantity
  )
  const { percentOff } = cheapestVariantPrice
  const { compareAtPrice, price, tradePrice, discountPrice } = cheapestVariantPrice.priceFormatted(
    true
  )

  const fontSize = ['13px', null, '16px']

  // if (item.title === 'Anda Rug – Charcoal') {
  //   console.log('priceNew', compareAtPrice, price, tradePrice)
  // }

  const showTradePrice = tradePrice !== price

  return (
    <Box
      sx={{
        textAlign: 'end',
        fontSize: overlayOpen && flyout ? fontSize : fontSize,
        minWidth: ['100px', null, null, '140px'],
      }}
    >
      {cheapestVariantPrice.isSale || showTradePrice ? (
        <CompareAtPrice
          sxProps={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            color: '#595858',
            fontSize: overlayOpen && flyout ? fontSize : fontSize,
          }}
          price={cheapestVariantPrice.isSale ? compareAtPrice : price}
        />
      ) : null}
      <Box
        // marketing tools class
        className="cart-item-price"
        data-cy="cart-item-price"
        sx={{
          display: 'flex',
          color: '#2B2B2B',
          alignItems: 'flex-end',
          flexDirection: ['column'],
          gap: space.sm,
        }}
      >
        {cheapestVariantPrice.isSale || showTradeCustomerLabel ? (
          <Text
            sx={{
              fontSize:
                overlayOpen && flyout
                  ? ['11px', '14px', '11px !important']
                  : ['11px !important', null, null, '15px !important'],
              minWidth: '65px',
              '.cart-flyout &': {
                verticalAlign: 'center',
              },
              color: '#595858',
              order: 1,
            }}
          >
            {!cheapestVariantPrice.isSale ? 'TRADE PRICE' : 'SALE'}
          </Text>
        ) : null}
        <span sx={{ order: 0 }}>{!cheapestVariantPrice.isSale ? tradePrice : price}</span>
      </Box>
    </Box>
  )
}
