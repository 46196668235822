import { isBrowser } from '~/utils/marketing/index'

export enum TrackedEvents {
  Search = 'Search',
  AddToCart = 'AddToCart',
  Contact = 'Contact',
  CompleteRegistration = 'CompleteRegistration',
  InitiateCheckout = 'InitiateCheckout',
  ViewContent = 'ViewContent',
}

type FBQInputType = {
  eventType?: string
  event: TrackedEvents
  params?: object
}

const fbq = ({ eventType = 'track', event, params = {} }: FBQInputType): void => {
  if (!isBrowser || !window.fbq) {
    return
  }

  window.fbq(eventType, event, params)
}

export default fbq
