import { useMemo } from 'react'
import { ProductDetailPageQuery, ProductVariantType } from '~/@types/models'
interface UseProductCategoriesProps {
  product: ProductDetailPageQuery['product']
  productVariant: ProductVariantType
}

export const useProductCategories = ({ product, productVariant }: UseProductCategoriesProps) => {
  return useMemo(
    () =>
      product.categoriesProducts?.map(_product => {
        const title = _product?.variants?.[0]?.colorName
          ? _product.title + '-' + _product.variants[0].colorName.split('-')[0]
          : _product.title
        return {
          ..._product,
          title,
        }
      }),
    [product]
  )
}
