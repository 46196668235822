import styled from 'styled-components'

export const ImageGrid = styled.div`
  display: grid;

  img {
    width: 100%;
    object-fit: cover;
    aspect-ratio: 440/440;
    max-height: 300px;
  }

  ${({ theme }) => '@media ' + theme.mediaQueries.tablet} {
    grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
  }
`
