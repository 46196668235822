import { useMemo, useState } from 'react'
import { ProductDetailPageQuery, ProductVariantType } from '~/@types/models'

interface UseProductUtilsProps {
  product: ProductDetailPageQuery['product']
  productVariant: ProductVariantType
}

export const useFlyOutControl = ({ product, productVariant }: UseProductUtilsProps) => {
  const [flyOpen, setFlyOpen] = useState<boolean>(false)

  return {
    flyOpen,
    setFlyOpen,
  }
}
