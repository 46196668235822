import { useCallback, useEffect, useMemo, useState } from 'react'
import { Maybe, ProductDetailPageQuery, ProductVariantType } from '~/@types/models'
import useProductAvailability from '~/hooks/use-product-availability'

interface UseVariantControlProps {
  product: ProductDetailPageQuery['product']
  productVariant: ProductVariantType
  defaultPreOrderMessage: boolean
}

export const useAvaliableControl = ({
  product,
  productVariant,
  defaultPreOrderMessage,
}: UseVariantControlProps) => {
  const [preOrderAgreement, setPreorderAgreement] = useState<boolean>(true)

  const { preOrderMessage, currentlyInStock } = useProductAvailability({
    product,
    variant: productVariant,
  })

  const { currentlyNotInStock } = productVariant || {}

  useEffect(() => {
    if (!currentlyInStock) {
      setPreorderAgreement(false)
    }
  }, [productVariant.selectedOptions, preOrderMessage, currentlyInStock])

  const handlePreorderAgreement = useCallback(() => {
    setPreorderAgreement(() => !preOrderAgreement)
  }, [setPreorderAgreement, preOrderAgreement])

  const customAttributes = []

  if (preOrderMessage && currentlyNotInStock) {
    customAttributes.push({
      key: 'Pre-order',
      value: String(preOrderMessage).toString(),
    })
  }

  return {
    preOrderAgreement,
    handlePreorderAgreement,
    customAttributes,
    currentlyNotInStock,
  }
}
