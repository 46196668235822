import React, { ReactNode, forwardRef } from 'react'
import { StyledNavigationButton } from './styles'

interface NavigationButtonProps {
  children?: ReactNode
  action: 'prev' | 'next'
  direction?: 'vertical' | 'horizontal'
  spacing?: number
  className?: string
}

export const NavigationButton = forwardRef<HTMLButtonElement, NavigationButtonProps>(
  ({ children, action, direction = 'horizontal', spacing = 0, className, ...props }, ref) => {
    const classes = ['navigation-' + action, className].filter(o => !!o).join(' ')

    return (
      <StyledNavigationButton
        ref={ref}
        $action={action}
        $direction={direction}
        $spacing={spacing}
        className={classes}
        {...props}
      >
        {children}
      </StyledNavigationButton>
    )
  }
)
