// @TODO: add this to a utils method;
export const toCamelCase = (str: string) => str.replace(/[-_]([a-z])/gu, m => m[1].toUpperCase())

// @TODO: add this to a utils method;
export const mapMetafields = product => {
  return Array.from(product.metafields)
    .map(metafield => {
      if (metafield === null) return null
      const key = toCamelCase(metafield?.key)
      return {
        [key]: metafield.value,
      }
    })
    .filter(item => item !== null)
    ?.filter(item => item.key === undefined)
    .reduce((acc, curr) => {
      return {
        ...acc,
        ...curr,
      }
    }, {})
}

// @TODO: add this to a utils method;
export const isCustomOption = (title: string) => String(title).indexOf(`- Stocked`) < 0
