/** @jsx jsx */

import { FC } from 'react'
import { jsx, Box, ThemeUIStyleObject } from 'theme-ui'
import theme from '~/gatsby-plugin-theme-ui'
import { HeaderVariantType } from '~/@types/models'
import useHeaderScrolling from '~/hooks/use-header-scolling'
import './header-wrapper.styles.css'

type Props = {
  headerVariant: HeaderVariantType
  sxProps?: ThemeUIStyleObject
  setIsNavOpen?: (isNavOpen: boolean) => void
  isNavOpen?: boolean
  display?: `MOBILE` | `DESKTOP`
}

const HeaderWrapper: FC<Props> = ({ children, headerVariant, sxProps, display }) => {
  const { isScrolling } = useHeaderScrolling()
  const showGradient = !isScrolling && headerVariant !== 'default'

  const defaultGradient = 'linear-gradient(180deg, #2b2b2b52, transparent)'

  const gradient: Record<HeaderVariantType, string> = {
    dark: 'linear-gradient(180deg, #ffffff52, transparent)',
    light: defaultGradient,
    default: defaultGradient,
    dark_solid: defaultGradient,
    dark_transparent: defaultGradient,
    dark_transparent_bg: defaultGradient,
    light_blue_gradient: 'linear-gradient(0deg, rgba(38, 22, 10, 0) 0%, rgb(1, 24, 43) 100%)',
    transparent: 'transparent',
  }

  return (
    <Box
      id={`header-wrapper-${display === 'MOBILE' ? 'mobile' : 'desktop'}`}
      className={display === `MOBILE` ? `mobile` : `desktop`}
      as="header"
      sx={{
        width: '100%',
        zIndex: 'header',
        transition: 'height .3s',
        position: 'absolute',
        ...(showGradient && {
          background: gradient[headerVariant],
        }),

        ...(headerVariant === 'light' ||
        headerVariant === 'light_blue_gradient' ||
        headerVariant === 'transparent'
          ? {
              bg: 'transparent',
              color: 'white',
              svg: {
                fill: 'white',
              },
            }
          : headerVariant === 'dark' || headerVariant === 'dark_transparent_bg'
          ? {
              bg: 'transparent',
              color: 'black',
              fontWeight: 500,
              svg: {
                fill: 'black',
              },
            }
          : headerVariant === 'dark_transparent'
          ? {
              bg: 'transparent',
              color: 'text',
              svg: {
                fill: 'text',
              },
            }
          : headerVariant === 'dark_solid'
          ? {
              bg: 'navDarkLight',
              color: 'main',
              position: 'relative',
            }
          : {
              borderBottom: '1px solid',
              borderBottomColor: 'border',
              bg: 'main',
              color: 'text',
              svg: {
                fill: 'text',
              },
            }),

        ...(isScrolling && {
          position: 'fixed',
          top: 0,
          bg: headerVariant === 'dark_solid' ? 'navDarkLight' : theme.colors.main,
          color: headerVariant === 'dark_solid' ? 'main' : 'text',
          borderBottom: '1px solid',
          borderBottomColor: headerVariant === 'dark_solid' ? 'navDarkLight' : 'border',
          svg: {
            fill: headerVariant === 'dark_solid' ? 'main' : 'text',
          },
        }),

        ...sxProps,
      }}
    >
      {children}
    </Box>
  ) as JSX.Element
}

export default HeaderWrapper
