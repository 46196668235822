import { useEffect, useState } from 'react'

const useHeaderScrolling = () => {
  const [isScrolling, setIsScrolling] = useState(false)
  const onScroll = () => {
    const headerIdName = 'app-header'
    const header = document.getElementById(`${headerIdName}`)
    const topBanner = document.getElementById('top-banner')
    const stickyMenu = document.getElementById('sticky-menu')

    if (header && (stickyMenu || topBanner)) {
      if (topBanner && document.scrollingElement!.scrollTop > topBanner?.clientHeight) {
        setIsScrolling(true)
      } else if (stickyMenu && document.scrollingElement!.scrollTop > stickyMenu?.clientHeight) {
        setIsScrolling(true)
      } else {
        setIsScrolling(false)
      }
    }
  }

  useEffect(() => {
    onScroll()

    window.addEventListener('scroll', onScroll)

    return () => window.removeEventListener('scroll', onScroll)
  }, [])

  return {
    isScrolling,
  }
}

export default useHeaderScrolling
