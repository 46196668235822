/** @jsx jsx **/
import { jsx, Box } from 'theme-ui'
import React, { FC, useEffect } from 'react'
import { HeaderVariantType } from '~/@types/models'

const sharedStylesBottomTop = {
  WebkitTransformOrigin: 'center',
  transformOrigin: 'center',
  top: '50%',
  marginTop: '-1px',
  width: '120%',
}

const sharedStylesTop = {
  WebkitTransform: 'rotate(45deg)',
  transform: 'rotate(45deg)',
  top: '50%',
  marginTop: '-1px',
}

const sharedStylesMiddle = {
  opacity: '0',
}

const sharedStylesBottom = {
  WebkitTransform: 'rotate(-45deg)',
  transform: 'rotate(-45deg)',
  bottom: 'auto',
}

type Props = {
  isLight?: boolean
  isOpenNav: boolean
  setIsOpenNav: Function
}

export const Hamburger: FC<Props> = ({ isLight, isOpenNav, setIsOpenNav }) => {
  const handleOpenNavClick = () => {
    setIsOpenNav(!isOpenNav)
  }

  useEffect(() => {
    document.body.style.overflow = isOpenNav ? 'hidden' : ''
  }, [isOpenNav])

  const hamburgerStyles = {
    '.hamburger': {
      backgroundColor: 'transparent',
      position: 'relative',
      zIndex: 10,
      border: 0,
      padding: '15px',
      display: ['flex', null, null, 'none'],
      alignItems: 'center',
      ':focus, :hover': {
        outline: 'none',
        opacity: '0.8',
      },
    },
    '.icon': { width: '16px', height: '16px', position: 'relative' },
    '.dash': {
      WebkitTransition: 'all 0.3s',
      transition: 'all 0.3s',
      width: '100%',
      height: '2px',
      borderRadius: '3px',
      backgroundColor: `${isLight ? 'white' : 'black'}`,
      position: 'absolute',
      left: '0',
    },
    '.dash--top': { top: '0' },
    '.dash--middle': { top: '50%', marginTop: '-1px' },
    '.dash--bottom': { bottom: '0' },
  }

  return (
    <React.Fragment>
      <Box
        as="button"
        sx={{
          ...hamburgerStyles['.hamburger'],
        }}
        onClick={handleOpenNavClick}
      >
        <Box
          as="span"
          sx={{
            ...hamburgerStyles['.icon'],
          }}
        >
          <Box
            as="span"
            sx={{
              ...hamburgerStyles['.dash'],
              ...hamburgerStyles['.dash--top'],
              ...(isOpenNav && sharedStylesTop),
              ...(isOpenNav && sharedStylesBottomTop),
            }}
          />
          <Box
            as="span"
            sx={{
              ...hamburgerStyles['.dash'],
              ...hamburgerStyles['.dash--middle'],
              ...(isOpenNav && sharedStylesMiddle),
            }}
          />
          <Box
            as="span"
            sx={{
              ...hamburgerStyles['.dash'],
              ...hamburgerStyles['.dash--bottom'],
              ...(isOpenNav && sharedStylesBottom),
              ...(isOpenNav && sharedStylesBottomTop),
            }}
          />
        </Box>
      </Box>
    </React.Fragment>
  )
}
