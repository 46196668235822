import React, { useState, useEffect, useCallback, useRef } from 'react'
import ReactModal from 'react-modal'
import { Input, Text, Flex, Box } from 'theme-ui'
import { H2, H3 } from '~/styled/components/typography'
import { Copy } from '../components/icons/copyIcon'
import { widePageQuery } from './page-wide'
import useMediaQuery from '~/hooks/use-media-query'

type Props = {
  isOpen: boolean
  onRequestClose: () => void
  isFromModal?: boolean
  isSuccesful?: boolean
  shareLink: string
  wishlistName?: string
}

interface ListEntry {
  [key: string]: any // Assuming the keys are dynamic
}

interface Lists {
  [key: string]: ListEntry
}

const ShareLinkModal: React.FC<Props> = ({
  isOpen,
  isFromModal,
  isSuccesful,
  onRequestClose,
  shareLink,
  wishlistName,
}) => {
  const [isNext, setIsNext] = useState(false)
  const [loading, setLoading] = useState<boolean>(true)
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const menuRef = useRef()

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen)
  }

  const handleOutsideClick = event => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsMenuOpen(false)
    }
  }

  const isMobile = useMediaQuery({
    query: breakpoints => `(max-width: ${breakpoints[2]})`,
  })

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick)
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [])

  useEffect(() => {
    setIsNext(false)
  }, [onRequestClose])

  const handleNext = () => {
    setIsNext(true)
  }
  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(shareLink)
      .then(() => {
        // console.log('Text copied to clipboard:', textToCopy);
        // settoastText('Copied to clipboard')
        // settoastlIsOpen(true)
      })
      .catch(error => {
        // console.error('Error copying text:', error);
        return error
      })
  }
  // console.log(isMobile);

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Minimal Modal Example"
      style={{
        overlay: {},
        content: {
          fontFamily: 'Cormorant Garamond',
          color: '#2b2b2b',
          position: isMobile ? 'sticky' : 'fixed',
          top: '50%', // Vertically center
          left: '50%', // Horizontally center
          transform: isMobile ? 'none' : 'translate(-50%, -50%)', // Adjust for centering
          backgroundColor: 'white',
          border: '1px solid #C8C8C8',
          width: isMobile ? '100%' : '60vw',
          height: isMobile ? 'auto' : '60px',
          padding: '10px',
          display: 'table',
          alignItems: 'center',
          justifyItems: 'center',
          fontWeight: '500',
        },
      }}
    >
      <>
        <H3 style={{ marginBottom: '1em' }}>Share Project</H3>
        <Flex sx={{ alignItems: 'center', columnGap: '8px' }}>
          <Box
            sx={{
              overflow: 'hidden',
              height: '55px',
              width: isMobile ? '80vw' : '60vw',
              alignContent: 'center',
              border: '1px solid',
              textAlign: 'start',
              borderRadius: '4px',
            }}
          >
            <Box
              sx={{
                fontFamily:
                  'Helvetica,Roboto,system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",sans-serif',
                fontSize: '1rem',
                padding: '10px',
                overflow: 'auto',
              }}
            >
              {shareLink}
            </Box>
          </Box>
          {/* <Textarea readOnly defaultValue={shareLink} sx={{ height: '45px' }} /> */}
          <Box
            sx={{
              height: '45px',
              width: '45px',
              alignContent: 'center',
              border: '1px solid',
              textAlign: 'center',
              borderRadius: '4px',
              cursor: 'pointer',
              margin: 'auto',
            }}
            onClick={() => copyToClipboard()}
          >
            <Copy color="#2b2b2b" width="24px" height="24px" />
          </Box>
        </Flex>

        {/* <Text>{shareLink}</Text> */}
      </>
    </ReactModal>
  )
}

export default ShareLinkModal
