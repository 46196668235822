/** @jsx jsx **/
import { FC } from 'react'
import { Flex, jsx } from 'theme-ui'
import theme from '~/gatsby-plugin-theme-ui'

const CartOverlayWrapper: FC = ({ children }) => {
  return (
    <Flex
      sx={{
        flexDirection: 'column',
        height: ['calc(100vh - 64px)', '100%'],
        overflow: 'hidden',
        px: '20px',
      }}
    >
      {children}
    </Flex>
  )
}

export default CartOverlayWrapper
