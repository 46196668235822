/** @jsx jsx **/
// @ts-ignore
import { jsx, Box } from 'theme-ui'
import { useEffect, FC, useRef, useState, ReactNode, Fragment, RefObject } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import ErrorMessage from '~/components/error-message'

let globalId = 0

export type OnFormReadyType = (val: any, formRef: RefObject<HTMLDivElement>) => void

type Props = {
  formId: string
  onReady?: OnFormReadyType
  loading?: string | ReactNode
}

const KlaviyoForm: FC<Props> = ({ formId, onReady, loading }) => {
  const formRef = useRef<HTMLDivElement>(null)
  const reCaptchaElement = useRef<any>(null)
  const [state, setState] = useState({
    loaded: false,
  })
  const [recaptchaError, setRecaptchaError] = useState<string | null>(null)
  const [recaptchaValue, setRecaptchaValue] = useState<string | null>(null)
  const findFormElement = () => {
    // protect against component unmounting before form is added
    if (!formRef.current) {
      return
    }

    const form = formRef.current.querySelector(`button`)
    if (form) {
      form.disabled = true
      form.style.cursor = 'not-allowed'
      form.style.opacity = '0.5'
    }
    if (form) {
      setState({ loaded: true })

      if (onReady) {
        onReady(form, formRef)
      }
    } else {
      setTimeout(findFormElement, 1)
    }
  }

  useEffect(() => {
    globalId++
    findFormElement()
  }, [])

  useEffect(() => {
    if (recaptchaValue) {
      const button = formRef.current?.querySelector('button')
      if (button) {
        button.disabled = false
        button.style.cursor = 'pointer'
        button.style.opacity = '1'
      }
    }
  }, [recaptchaValue])

  return (
    <Fragment>
      <div ref={formRef} id="klaviyoNewsletter" className={`klaviyo-form-${formId}`}>
        {!state.loaded && <Fragment>{loading}</Fragment>}
      </div>
      <ReCAPTCHA
        ref={reCaptchaElement}
        onChange={(e: any) => {
          if (e) {
            setRecaptchaError(null)
            setRecaptchaValue(e)
          } else {
            setRecaptchaError('Please complete the reCAPTCHA')
            setRecaptchaValue(null)
          }
        }}
        sitekey={process.env.GATSBY_RECAPTCHA_SITE_KEY as string}
      />
      {recaptchaError && <ErrorMessage error={recaptchaError} data-cy="recaptcha-error" />}
    </Fragment>
  )
}

export default KlaviyoForm
