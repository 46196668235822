import { SxStyleProp } from 'theme-ui'
import React from 'react'
import { useLocation } from '@reach/router'
import SearchInput from '~/components/search-input'
import { connectSearchBox } from 'react-instantsearch-dom'
import { SearchBoxProvided } from 'react-instantsearch-core'
import { ProductHit } from './types'
import { navigate } from 'gatsby'

type Props = SearchBoxProvided & {
  focusOnMount?: boolean
  sx?: SxStyleProp
  hitRef?: React.MutableRefObject<ProductHit[] | undefined>
  closeSearch?: (value: boolean) => void
}

const SearchBox = (props: Props) => {
  const { currentRefinement, refine, focusOnMount = true, sx, hitRef } = props

  const onSubmit = () => {
    let handle
    if (hitRef && hitRef?.current && hitRef.current.length === 1) {
      handle = hitRef.current[0].handle
    }
    navigate(handle ? `/products/${handle}` : `/search?search=${currentRefinement}`)
  }

  const { pathname } = useLocation()

  return (
    <SearchInput
      searchInputValue={currentRefinement}
      onEnterPress={onSubmit}
      onChange={event => {
        refine(event.currentTarget.value)
        pathname === '/search' && navigate(`/search?search=${event.currentTarget.value}`)
      }}
      // setSearchInputValue={() => refine(``)}
      focusOnMount={focusOnMount}
      closeSearch={props.closeSearch}
      css={sx}
    />
  )
}

export default connectSearchBox<Props>(SearchBox)
