import React, { FC, SVGProps } from 'react'

const ExclamationMark: FC<SVGProps<SVGSVGElement>> = props => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
    <path
      d="M8 16C12.3765 16 16 12.3765 16 8C16 3.63137 12.3686 0 7.99216 0C3.62353 0 0 3.63137 0 8C0 12.3765 3.63137 16 8 16ZM8 14.6667C4.29804 14.6667 1.34118 11.702 1.34118 8C1.34118 4.30588 4.2902 1.33333 7.99216 1.33333C11.6941 1.33333 14.6588 4.30588 14.6667 8C14.6745 11.702 11.702 14.6667 8 14.6667ZM7.99216 9.41961C8.36863 9.41961 8.58824 9.20784 8.59608 8.8L8.70588 4.65882C8.71373 4.25882 8.4 3.96078 7.98431 3.96078C7.56078 3.96078 7.26275 4.25098 7.27059 4.65098L7.37255 8.8C7.38039 9.2 7.6 9.41961 7.99216 9.41961ZM7.99216 11.9686C8.44706 11.9686 8.83922 11.6078 8.83922 11.1529C8.83922 10.6902 8.4549 10.3373 7.99216 10.3373C7.52941 10.3373 7.1451 10.698 7.1451 11.1529C7.1451 11.6 7.53726 11.9686 7.99216 11.9686Z"
      fill="currentColor"
    />
  </svg>
)

export default ExclamationMark
