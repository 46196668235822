/** @jsx jsx */
import { RiDeleteBinLine } from '@remixicon/react'
import { FC, useCallback, useState, useRef } from 'react'
import { Box, Flex, jsx, Text, Button, SxStyleProp } from 'theme-ui'

type ButtonSize = 'xs' | 'sm' | 'md' | 'lg'
type props = {
  buttonSize?: ButtonSize
  value: number
  onChange: (newValue: number) => void
  sxProps?: SxStyleProp
  isFabric: boolean
  bordered?: boolean
  showLabel?: boolean
}

const BUTTON_SIZES: Record<ButtonSize, number> = { xs: 26, sm: 35, md: 40, lg: 64 }

export const StyledButton: FC<{
  onUpdate: () => void
  buttonSize?: ButtonSize
  sxProps?: SxStyleProp
}> = ({ children, onUpdate, buttonSize = 'lg', sxProps }) => {
  // const [disableButton, setDisableButton] = useState(undefined)

  const inputElement = useRef()

  return (
    <Button
      ref={inputElement}
      sx={{
        border: 'none',
        borderColor: 'strokes',
        bg: 'white',
        color: 'text',
        borderRadius: 0,
        cursor: 'pointer',
        height: [BUTTON_SIZES[buttonSize], null, BUTTON_SIZES[buttonSize]],
        width: [BUTTON_SIZES[buttonSize], null, BUTTON_SIZES[buttonSize]],
        padding: '0!important',
        fontFamily: 'Roboto, Helvetica, system-ui, -apple-system, BlinkMacSystemFont',
        ...sxProps,
      }}
      onClick={e => {
        if (e.target.disabled || inputElement.current.disabled) {
          return
        }
        inputElement.current.disabled = true
        try {
          onUpdate(e)
        } catch (error) {
          console.error(error)
        }
        inputElement.current.disabled = undefined
      }}
    >
      {children}
    </Button>
  )
}

const QuantityInput: FC<props> = ({
  onChange,
  value,
  buttonSize = 'lg',
  sxProps = {},
  isFabric,
  bordered = true,
  showLabel = true,
}) => {
  const [fieldValue, setFieldValue] = useState<number>(value)
  // const [disableButton, setDisableButton] = useState(undefined)
  const updateValue = useCallback(
    (value: number) => {
      const newValue = Math.max(0, value + fieldValue)
      setFieldValue(newValue)
      onChange(newValue)
    },
    [fieldValue, onChange]
  )

  return (
    <Flex
      sx={{
        height: [BUTTON_SIZES[buttonSize], null, BUTTON_SIZES[buttonSize]],
        ...sxProps,
        alignItems: 'center',
      }}
    >
      {showLabel ? (
        <Box
          sx={{
            fontSize: ['9px', '15px'],
            textTransform: 'uppercase',
            mr: '10px',
            fontFamily: 'Roboto, Helvetica, system-ui, -apple-system, BlinkMacSystemFont',
            color: '#2B2828',
            '.cart-flyout &': {
              fontSize: ['9px'],
            },
          }}
        >
          QTY
        </Box>
      ) : null}
      <Box
        sx={{ border: bordered ? '1px solid' : 'none', borderColor: '#595858', display: 'flex' }}
      >
        <Box
          sx={{
            mr: '0',
          }}
        >
          <StyledButton
            data-cy="quantity-decrease"
            buttonSize={buttonSize}
            onUpdate={() => {
              isFabric && fieldValue <= 3 ? updateValue(-3) : updateValue(-1)
            }}
          >
            <span>—</span>
          </StyledButton>
        </Box>
        <Flex
          as={Box}
          sx={{
            mr: '0',
            height: [BUTTON_SIZES[buttonSize], null, BUTTON_SIZES[buttonSize]],
            width: [BUTTON_SIZES[buttonSize], null, BUTTON_SIZES[buttonSize]],
            border: bordered ? 'none' : '1px solid',
            borderColor: !bordered ? 'rgba(230, 230, 230, 1)' : 'strokes',
            bg: bordered ? 'bg' : 'white',
            color: 'text',
            alignItems: 'center',
            justifyContent: 'center',
            flex: 1,
            fontSize: ['9px!important', '15px!important'],
            fontFamily: 'Roboto, Helvetica, system-ui, -apple-system, BlinkMacSystemFont',
            '.cart-flyout &': {
              fontSize: ['9px!important'],
            },
            div: {
              fontSize: ['9px!important', '15px!important'],
              '.cart-flyout &': {
                fontSize: ['9px!important'],
              },
            },
          }}
        >
          <Text>{fieldValue}</Text>
        </Flex>
        <Box>
          <StyledButton
            data-cy="quantity-increase"
            onUpdate={() => updateValue(1)}
            buttonSize={buttonSize}
          >
            <span>+</span>
          </StyledButton>
        </Box>
      </Box>
    </Flex>
  )
}

export default QuantityInput
