import React from 'react'
import { StyledTag } from './styles'

interface TagProps extends React.HTMLAttributes<HTMLDivElement> {
  bgColor?: string
  textColor?: string
  active?: boolean
  style?: React.CSSProperties
  disabled?: boolean
}

export const Tag: React.FC<TagProps> = ({
  children,
  bgColor = '#EDEDED',
  textColor = '#000',
  active = false,
  style,
  disabled = false,
  ...props
}) => {
  return (
    <StyledTag
      className={`tag${active ? ' active' : ''}${disabled ? ' disabled' : ''} ${
        bgColor === 'transparent' ? 'transparent' : ''
      }`}
      style={{
        backgroundColor: bgColor,
        color: textColor,
        border: `1px solid transparent`,
        ...style,
      }}
      {...props}
    >
      {children}
    </StyledTag>
  )
}
