import slugify from 'slugify'

import { assoc, curry, keys, map, reduce } from 'ramda'
import { FaqItem } from './types'

export const generateFaqSlug = (title: string): string => {
  if (!title) {
    return ''
  }
  return `faq-` + slugify(title).toLowerCase()
}

type MountFaq = {
  items: {
    question: string
    answer: string
  }[]
}

const renameKeys = curry((keysMap, obj) =>
  reduce((acc, key) => assoc(keysMap[key] || key, obj[key], acc), {}, keys(obj))
)

export const mountFaq = ({ items }: MountFaq): Array<FaqItem> | null => {
  if (!items) return null

  const transformedData: Array<{ label: string; content: string; slug: string }> = map(
    renameKeys({ question: 'label', answer: 'content' }),
    items
  )

  return transformedData.map(item => {
    return {
      ...item,
      identifier: generateFaqSlug(item.label),
    }
  })
}
