/** @jsx jsx **/
import { jsx, Box } from 'theme-ui'
import { FC } from 'react'
import { useOverlayContext } from '~/context/overlay-context'
import { keyframes } from '@emotion/react'

type Props = {}

const Overlay: FC<Props> = () => {
  const { show } = useOverlayContext()
  const fadeIn = keyframes({ from: { opacity: 0 }, to: { opacity: 1 } })
  if (show) {
    return (
      <Box
        id="app-overlay"
        sx={{
          variant: 'variants.overlay',
          zIndex: 'overlay',
          position: 'fixed',
          display: 'block',
          animation: `${fadeIn} 0.3s ease-in-out`,
        }}
      />
    )
  }
  return null
}

export default Overlay
