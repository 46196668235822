import React, { useState, useEffect, useCallback } from 'react'
import ReactModal from 'react-modal'
import FormReadOnly from '~/components/form-read-only'
import FormField2 from '~/components/form-field2'
import { object, string } from 'yup'
import useFormSubmission from '~/hooks/use-form-submission'
import { Grid, Box, Text, Button as CloseButton } from 'theme-ui'
import { space } from '~/gatsby-plugin-theme-ui'
import { useCustomerContext } from '~/context/customer-context'
import Folder from '~/components/icons/folder'
import FolderAdded from '~/components/icons/FolderAdded'
import { Button } from '~/components/button'
import { getProductByHandle } from '~/graphql/getters'
import { ProductItemComponentType, ProductVariantType } from '~/@types/models'
import { Cross } from '~/components/icons/cross'
import urls from '~/common/urls'
import NotificationModal from './notificationModal'
import useMediaQuery from '~/hooks/use-media-query'
import { axiosRequests } from '~/utils/axios'
import { useDashboard } from '~/components/account-layout/context/dashboardContext'

type Props = {
  isOpen: boolean
  product: ProductItemComponentType
  variant: ProductVariantType
  isMobile: boolean
  onRequestClose: () => void
  initialValues?: FormValues
  fromList: number
  productName?: string
  isCopy: boolean
  productHande?: any
}

type FormValues = {
  firstName: string
  lastName: string
  email: string
}

interface ListEntry {
  [key: string]: any // Assuming the keys are dynamic
}

interface Lists {
  [key: string]: ListEntry
}

const schema = object().shape({
  firstName: string().required(),
  lastName: string().required(),
  email: string().email().required(),
})

const ProjectsMoveModal: React.FC<Props> = ({
  isOpen,
  product,
  productName,
  variant,
  onRequestClose,
  isCopy,
  productHande,
  fromList,
  initialValues = {},
}) => {
  const {
    customer,
    addProductToWishlist,
    removeProductFromWishlist,
    fetchCustomer,
  } = useCustomerContext()

  // let listsArray = []
  // let listsNames = []
  const [isNext, setIsNext] = useState(false)
  const [loading, setLoading] = useState<boolean>(true)
  const [toastlIsOpen, settoastlIsOpen] = useState(false)
  const [isSuccesful, setisSuccesful] = useState<boolean>(false)
  const [toastText, settoastText] = useState('')
  const [wishlistName, setwishlistName] = useState('')
  const AxiosFunctions = axiosRequests()
  const openToast = async (copyBoolean: boolean) => {
    settoastlIsOpen(true)
  }

  const {
    defaultList,
    addToWishlist,
    removeFromWishlist,
    addToSfl,
    removeFromSfl,
    sflLists,
    regid,
    sessionid,
    listsArray,
  } = useDashboard()

  const closeToast = () => {
    settoastlIsOpen(false)
  }

  const isMobile = useMediaQuery({
    query: breakpoints => `(max-width: ${breakpoints[2]})`,
  })

  const copyToWishlist = async (listId, listName) => {
    const prefix = `https://bensoleimani.myshopify.com${productHande}`
    await AxiosFunctions.copyToWishlist(
      listId,
      listName,
      product,
      sessionid,
      regid,
      variant,
      prefix
    )
      .then(function (response) {
        setwishlistName(listName)
        settoastText('Item copied to')
        setisSuccesful(true)
        settoastlIsOpen(true)
        setTimeout(() => {
          onRequestClose()
          fetchCustomer()
        }, 3200)
      })
      .catch(function (error) {
        setwishlistName(listName)
        settoastText('Could not copy item to')
        setisSuccesful(false)
        settoastlIsOpen(true)
        setTimeout(() => {
          settoastlIsOpen(false)
        }, 3200)
        return error
      })
  }

  const moveToWishlist = async (listId, listName) => {
    const prefix = `https://bensoleimani.myshopify.com${productHande}`
    await AxiosFunctions.moveToWishlist(
      listId,
      listName,
      product,
      sessionid,
      regid,
      variant,
      prefix,
      fromList
    )
      .then(function (response) {
        setwishlistName(listName)
        settoastText('Item Moved to')
        setisSuccesful(true)
        settoastlIsOpen(true)
        setTimeout(() => {
          onRequestClose()
          fetchCustomer()
        }, 3200)
      })
      .catch(function (error) {
        setwishlistName(listName)
        settoastText('Item was only copied to')
        setisSuccesful(false)
        settoastlIsOpen(true)
        setTimeout(() => {
          settoastlIsOpen(false)
        }, 3200)
        return error
      })
  }

  useEffect(() => {
    setIsNext(false)
  }, [onRequestClose])

  const submitCallback = async (values: FormValues) => {
    return values
  }

  const handleNext = () => {
    setIsNext(true)
  }

  const { onSubmit, isLoading, serverError, isRead } = useFormSubmission<FormValues, any>(
    submitCallback
  )
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Minimal Modal Example"
      style={{
        overlay: {},
        content: {
          color: 'lightsteelblue',
          position: isMobile ? 'sticky' : 'fixed',
          top: '30%',
          left: '36%',
          backgroundColor: 'white',
          border: '1px solid #C8C8C8',
          width: isMobile ? 'auto' : '34rem',
          height: isMobile ? 'auto' : '33rem',
        },
      }}
    >
      <Box
        sx={{ display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid #E6E6E6' }}
      >
        <h2
          style={{
            fontFamily: 'Cormorant Garamond, Times New Roman, serif',
            fontWeight: 400,
            fontSize: '33.18px',
            color: '#2b2b2b',
            marginTop: '10px',
            marginBottom: '10px',
          }}
        >
          {isCopy ? 'COPY' : 'MOVE'} ITEM
        </h2>
        <CloseButton sx={{ background: 'none', border: 'none' }} onClick={onRequestClose}>
          {' '}
          <Cross color="#2B2B2B" />
        </CloseButton>
      </Box>

      <Grid
        columns={['7fr 2fr']}
        gap="0.5em"
        sx={{
          flexDirection: 'column',
          boxSizing: 'border-box',
          marginTop: '1em',
          marginBottom: '1em',
        }}
      >
        {!!customer &&
          listsArray?.map((list, index) => {
            if (fromList === list.lid) {
              return ''
            }
            return (
              <>
                <Box sx={{ display: 'grid' }} key={index}>
                  <Text
                    sx={{
                      color: '#051826',
                      padding: '5px 0 5px 0',
                      textTransform: 'uppercase',
                      fontFamily: 'Cormorant Garamond',
                      fontWeight: '500',
                      fontSize: '20px',
                    }}
                  >
                    {list.lname === 'default' ? 'my favorites' : list.lname}
                  </Text>
                  <Text sx={{ color: '#051826', padding: '5px 0 5px 0', fontSize: '16px' }}>
                    {' '}
                    {list.listcontents.length} {list.listcontents.length === 1 ? 'item' : 'items'}
                  </Text>
                </Box>
                <Box
                  sx={{
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '5px 0 5px 0',
                    cursor: 'pointer',
                  }}
                >
                  <Button
                    onClick={(e: any) => {
                      isCopy
                        ? copyToWishlist(list.lid, list.lname)
                        : moveToWishlist(list.lid, list.lname)
                    }}
                    sxProps={{ color: '#2b2b2b', borderColor: '#2b2b2b', minWidth: '100px' }}
                    variant="tertiary"
                    label={isCopy ? 'COPY' : 'MOVE'}
                  />
                </Box>
              </>
            )
          })}
        {/* <Box>
          <Text sx={{ color: '#051826', padding: '5px 0 5px 0' }}>MY FAVORITES</Text>
        </Box>
        <Box
          sx={{
            borderBottom: '1px solid #051826',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '5px 0 5px 0',
          }}
        >
          <Heart color="#C8C8C8" />
        </Box>
        <Box sx={{ padding: '5px 0 5px 0' }}>
          <Text sx={{ color: '#051826' }}>PROJECTS NAME</Text>
        </Box>
        <Box
          sx={{
            borderBottom: '1px solid #051826',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '5px 0 5px 0',
          }}
        >
          <Heart color="#C8C8C8" />
        </Box> */}
      </Grid>
      {toastlIsOpen && (
        <Box>
          <NotificationModal
            toastText={toastText}
            wishlistName={wishlistName}
            isFromModal={true}
            isSuccesful={isSuccesful}
            isMobile={false}
            isOpen={toastlIsOpen}
            onRequestClose={closeToast}
          />
        </Box>
      )}
    </ReactModal>
  )
}

export default ProjectsMoveModal
