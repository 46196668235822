import React from 'react'
import { ThemeUIStyleObject } from 'theme-ui'
import { Link as GatsbyLink } from 'gatsby'

interface ILinkComponentProps extends React.HTMLProps<HTMLAnchorElement> {
  link?: string
  children: React.ReactNode
  sx?: ThemeUIStyleObject
  style?: React.CSSProperties
}

const LinkComponent = React.forwardRef<HTMLAnchorElement, ILinkComponentProps>(
  ({ link, children, ...rest }, ref) => {
    const LinkComponent = link ? GatsbyLink : 'button'
    const linkProps: { [key: string]: any } = link ? { to: link } : { role: 'button' }

    if (link && link?.indexOf('?') > 0) {
      linkProps['onClick' as string] = (e: React.MouseEvent) => {
        e.preventDefault()
        window.location.href = link
      }
    }

    return (
      <LinkComponent {...(rest as any)} {...linkProps} ref={ref}>
        {children}
      </LinkComponent>
    )
  }
)

export default LinkComponent
