// import * as Sentry from '@sentry/react'
import pintrk, { PinterestTrackedEvents } from '~/utils/marketing/pinterest'
import fbq, { TrackedEvents } from '~/utils/marketing/facebook'
import gtag, { GoogleTrackedEvents } from '~/utils/marketing/google'
import { ProductItemComponentType, ProductVariantType } from '~/@types/models'

const currency = 'USD'

type TrackAddToCartType = {
  content_ids: readonly string[]
  content_name?: string
  contents: Array<{
    id: string
    quantity: number
  }>
  currency: string
  value: string | number
  name: string
  color: string
  item_category?: string
}

export const trackAddToCart = (params: TrackAddToCartType): void => {
  const value = typeof params.value === 'string' ? parseFloat(params.value) : params.value
  try {
    pintrk({
      event: PinterestTrackedEvents.AddToCart,
      params: {
        value,
        order_quantity: 1,
        currency: params.currency,
      },
    })

    fbq({
      event: TrackedEvents.AddToCart,
      params: {
        content_ids: params.content_ids,
        content_name: params.content_name,
        content_type: 'product',
        currency: params.currency || currency,
        value: value,
      },
    })

    gtag({
      action: GoogleTrackedEvents.AddToCart,
      params: {
        item_name: params.name,
        item_variant: params.color,
        item_category: params.item_category,
        item_id: params.content_ids && params.content_ids[0],
        quantity: 1,
        price: value,
      },
    })
  } catch (err) {
    // Sentry.captureException(err)
  }
}

type TrackViewCartType = {
  totalPrice: number
  contents: Array<{
    item_id: number | string
    item_name: string
    item_category: string
    item_variant: any
    price: string | number
    quantity: number
  }>
}

export const trackViewCart = (params: TrackViewCartType): void => {
  try {
    gtag({
      action: GoogleTrackedEvents.ViewCart,
      params: { currency: 'USD', value: params.totalPrice, items: params.contents },
    })
  } catch (err) {
    // Sentry.captureException(err)
  }
}

type TrackSearchType = {
  name: string
  id: number
}

export const trackSearch = (params: TrackSearchType): void => {
  try {
    pintrk({
      event: PinterestTrackedEvents.Search,
      params: {
        search_query: params.searchTerm,
      },
    })

    gtag({
      action: GoogleTrackedEvents.Search,
      params: {
        item_name: params.name,
        item_id: params.id,
      },
    })

    fbq({
      event: TrackedEvents.Search,
      params: {
        search_string: params.searchTerm,
      },
    })
  } catch (err) {
    // Sentry.captureException(err)
  }
}

type TrackCompleteRegistrationType = {
  pageName: string
  userId?: number
}

export const trackCompleteRegistration = (params: TrackCompleteRegistrationType): void => {
  const status = true
  const value = params.userId

  try {
    fbq({
      event: TrackedEvents.CompleteRegistration,
      params: {
        content_name: params.pageName,
        currency,
        status,
        value,
      },
    })
  } catch (err) {
    // Sentry.captureException(err)
  }
}

type TrackInitiateCheckoutType = {
  contentCategory: string
  contents: ReadonlyArray<{
    quantity: number
    id: string | number
  }>
  currency?: string
  value?: number
}

export const trackInitiateCheckout = (params: TrackInitiateCheckoutType): void => {
  const contentIds = params.contents.map(item => item.id)
  const numItems = params.contents.reduce((acc, curr) => acc + curr.quantity, 0)

  try {
    fbq({
      event: TrackedEvents.InitiateCheckout,
      params: {
        content_category: params.contentCategory,
        content_ids: contentIds,
        contents: params.contents,
        currency,
        num_items: numItems,
        value: params.value,
        content_type: 'product',
      },
    })

    gtag({
      action: GoogleTrackedEvents.BeginCheckout,
      params: {
        currency,
        items: params.contents,
      },
    })
  } catch (err) {
    // Sentry.captureException(err)
  }
}

export const trackContact = (): void => {
  fbq({
    event: TrackedEvents.Contact,
  })
}

type ViewContentType = {
  product: ProductItemComponentType
  variant?: ProductVariantType
}

export const trackViewContent = ({ product, variant }: ViewContentType): void => {
  try {
    fbq({
      event: TrackedEvents.ViewContent,
      params: {
        content_ids: [Number(product.shopifyId)],
        currency,
        content_category: product.productType,
        content_type: `product_group`,
        content_name: product.title,
        value: variant?.price.amount,
      },
    })
    gtag({
      action: GoogleTrackedEvents.ViewItem,
      params: {
        item_id: product.id,
        item_name: product.title,
        item_category: product.productType,
        item_variant: variant?.colorName,
        price: variant?.price.amount,
      },
    })
  } catch (err) {
    // Sentry.captureException(err)
  }
}
