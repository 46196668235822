import { useMemo } from 'react'
import useProductVariantInfo from '~/hooks/use-product-variant-info'
import { useProductUtils } from './useProductUtils'
import { ProductDetailPageQuery, ProductVariantType } from '~/@types/models'

interface UseProductPriceProps {
  product: ProductDetailPageQuery['product']
  productVariant: ProductVariantType
}

export const useProductPrice = ({ product, productVariant }: UseProductPriceProps) => {
  const { isFabric, isAntiqueRug } = useProductUtils({
    product,
    productVariant,
  })

  const priceData = useProductVariantInfo({
    product,
    productVariant: productVariant,
    isFabric,
  })

  // @TODO state??
  const isNotSelectedVariant = false

  const isPriceVisible = useMemo(() => {
    const price = Number(product.cheapestVariant?.price?.amount)

    if (product.isCustomQuickship && !price) {
      return false
    }

    return !isAntiqueRug && !isNotSelectedVariant
  }, [product.isCustomQuickship, product.price, isAntiqueRug, isNotSelectedVariant])

  return {
    visible: isPriceVisible,
    ...priceData,
  }
}
