import React from 'react'
import { SVGComponentProps } from './types'

const Pinterest2: SVGComponentProps = ({ ...rest }) => (
  <svg width="10" height="14" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 4.60671C10 7.76114 8.38192 10.1181 5.99662 10.1181C5.19525 10.1181 4.4422 9.64858 4.1841 9.11551C4.1841 9.11551 3.75319 10.9684 3.6624 11.3261C3.34109 12.5896 2.39595 13.8537 2.3229 13.9576C2.27145 14.0296 2.15867 14.007 2.14652 13.9117C2.12604 13.7507 1.88525 12.1562 2.16888 10.8555C2.31109 10.2023 3.12307 6.47361 3.12307 6.47361C3.12307 6.47361 2.88656 5.96014 2.88656 5.20144C2.88656 4.00931 3.52372 3.1199 4.31727 3.1199C4.99211 3.1199 5.31781 3.6687 5.31781 4.32691C5.31781 5.06261 4.8861 6.16194 4.663 7.18038C4.47674 8.03361 5.05774 8.72968 5.83395 8.72968C7.23954 8.72968 8.18629 6.77244 8.18629 4.45377C8.18629 2.69157 7.09142 1.37214 5.09899 1.37214C2.84885 1.37214 1.44606 3.19137 1.44606 5.22354C1.44606 5.92434 1.63696 6.41868 1.93587 6.80054C2.07261 6.97634 2.09227 7.04704 2.04235 7.24884C2.00618 7.39704 1.92526 7.75298 1.89073 7.89431C1.84161 8.09828 1.68915 8.17064 1.51951 8.09564C0.4832 7.63711 0 6.40627 0 5.02341C9.22665e-05 2.73934 1.77764 0 5.30293 0C8.136 0 10 2.22117 10 4.60671Z"
      fill="white"
    />
  </svg>
)

export default Pinterest2
