/** @jsx jsx */
import theme, { space } from '~/gatsby-plugin-theme-ui'

// @ts-ignore
import { Flex, Image, Box, jsx, Text, Heading } from 'theme-ui'
import { Link } from 'gatsby'
import { useEffect, useMemo } from 'react'

import {
  CheckoutLineItem,
  CheckoutLineItemInput,
  CheckoutProductVariant,
  ProductItemComponentType,
} from '~/@types/models'

import ImageWrapper from '~/components/image-wrapper'
import ProductVariantPriceNote from '~/components/product-variant-price-note'
import QuantityNote from '~/components/cart-item/quantity-note'
import QuantityInput, { StyledButton } from '~/components/quantity-input'

import { useCustomerContext } from '~/context/customer-context'

import urls from '~/common/urls'

import useQuantityNote from '~/hooks/use-quantity-note'

import { isSale } from '~/utils/price'
import { useCartContext } from '~/context/cart-context'
import { isValidTradeCustomerDiscount } from '~/utils/is-valid-trade-customer-discount'
import { CartItemPrice } from './components/price'
import { isCustomOption, mapMetafields } from './utils'
import { CartItemRow } from './components/styles'
import { AddFavoriteButton } from '~/componentsV2/addFavoriteBtn'

type CartItemProps = {
  source: string
  variant: string
  item: CheckoutLineItem
  onRemove: (item: CheckoutLineItem, sfl?: boolean) => void
}

export const CartItem = ({ source, variant, item, onRemove, ...rest }: CartItemProps) => {
  const { getNoteBySelectedQuantity } = useQuantityNote({})
  const { saveItem } = useCartContext()
  const { customerType } = useCustomerContext()
  const flyout = source === 'cart-flyout'
  const { auth } = useCustomerContext()

  const onQuantityUpdate = async (
    variant: CheckoutProductVariant,
    title: string,
    newValue: number,
    sfl?: boolean
  ) => {
    const message = getNoteBySelectedQuantity(
      variant.quantityAvailable,
      (item && item.quantity) + newValue
    )

    if (newValue <= 0) {
      onRemove(item, sfl)
    }

    const updatedItem: CheckoutLineItemInput = {
      variantId: variant.id,
      quantity: newValue,
      customAttributes: [
        {
          key: `Quantity`,
          value: message || '',
        },
      ],
    }
    await saveItem(updatedItem, title, variant)
  }

  const showTradeCustomerLabel =
    !isSale(item.variant) &&
    isValidTradeCustomerDiscount({
      isTradeCustomer: customerType.trade,
      productType: item.variant?.product?.productType,
    })

  const notLinkedCategory = ['Fabric Swatch', 'Wood/Metal Finish']

  const isNotLinked = !notLinkedCategory.includes(item.variant?.product?.productType)

  const productLink = isNotLinked
    ? `${urls.pages.productDetail}/${item.variant.product && item.variant.product.handle}`
    : null

  const mappedProduct = useMemo(() => {
    const {
      variant: { product },
    } = item

    return {
      ...product,
      metafields: product && product.metafields && product.metafields && mapMetafields(product),
    }
  }, [item.variant.product])

  const mappedVariant = useMemo(() => {
    const { variant } = item
    return {
      ...variant,
      isCustomOption: isCustomOption(variant.title),
      metafields: variant.metafields && variant.metafields && mapMetafields(variant),
      selectedOptions:
        variant.selectedOptions &&
        variant.selectedOptions
          .filter(option => option.name !== 'Title')
          .map(option => {
            return {
              ...option,
              value: String(option.value).replace('- Stocked', ''),
            }
          }),
    }
  }, [item.variant])

  const quantityAvailable = item.variant.quantityAvailable
  const quantity = item.quantity
  const isFabric = item.variant.product?.productType === 'Fabric'

  const renderImageSecion = () => {
    if (item.variant?.image && item.variant?.image.transformedSrc) {
      return (
        <ImageWrapper ratio={272 / 200} center classNameWrap="cart-item-image__wrapper">
          <Image
            src={item.variant?.image.transformedSrc}
            loading="lazy"
            alt={item.title || 'Product'}
          />
        </ImageWrapper>
      )
    } else {
      return <span>no image</span>
    }
  }

  const linkBaseProps = {
    style: { width: '100%' },
    className: 'cart-item-link',
  }

  const LinkComponent = productLink ? Link : 'div'
  const linkProps = productLink ? { to: productLink, ...linkBaseProps } : { ...linkBaseProps }
  // const isSwatch = item.variant.title?.includes('Swatch')
  // const isRugs = auth
  //   ? item.title?.includes('Rug') ||
  //     item.variant.product?.productType?.toLowerCase().includes('rug')
  //   : false

  if (!item.variant) return null

  return (
    <Flex
      key={item.id}
      className={source}
      sx={{
        pb: theme.space.gridGap1,
        mb: [theme.space.gridGap2, theme.space.gridGap3],
        borderBottom: ['1px solid', 'none'],
        borderColor: '#e6e6e6',
        flexFlow: ['column', null, 'row'],
        position: 'relative',
        gap: theme.space.gridGap1,
      }}
      {...rest}
    >
      <Flex
        sx={{
          minWidth: flyout ? ['100%', null, 180] : ['100%', null, 270],
          width: flyout ? ['100%', null, 180] : ['100%', null, 270],
        }}
        className="cart-item-image"
      >
        <LinkComponent {...linkProps}>{renderImageSecion()}</LinkComponent>
      </Flex>
      <Flex
        className="cart-item-details"
        sx={{
          width: '100%',
          paddingRight: flyout ? '16px' : 0,
          flexFlow: 'column',
          justifyContent: 'space-between',
          gap: theme.space.md,
        }}
      >
        <CartItemRow className="cart-item-header">
          <Box>
            <Link
              to={productLink}
              sx={{
                textDecoration: 'none',
                color: theme.colors.navDark,
              }}
            >
              <Heading
                variant="title6"
                // marketing tools class
                className="cart-item-title"
                sx={{
                  mb: ['8px'],
                  textTransform: 'uppercase',
                  fontSize: ['16px!important', '17px!important'],
                  lineHeight: ['20px!important'],
                  '.cart-flyout &': {
                    fontSize: ['16px!important'],
                  },
                }}
              >
                {item.title}
              </Heading>
            </Link>
          </Box>
          <CartItemPrice
            item={item}
            showTradeCustomerLabel={showTradeCustomerLabel}
            flyout={flyout}
          />
        </CartItemRow>
        <CartItemRow className="cart-item-body">
          <Box>
            {mappedVariant.selectedOptions && (
              <Box
                className="cart-item-quantity-box"
                as="table"
                sx={{
                  mb: ['0'],
                  borderSpacing: '0',
                  width: ['100%'],
                }}
              >
                <tbody>
                  {mappedVariant.selectedOptions.map(option => (
                    <tr
                      key={option.name}
                      sx={{
                        textTransform: 'uppercase',
                        fontSize: ['12px', null, '12px', '15px'],
                        '.cart-flyout &': {
                          fontSize: ['9px'],
                        },
                      }}
                    >
                      <td
                        sx={{
                          verticalAlign: 'top',
                          padding: '0',
                          fontFamily:
                            'Roboto, Helvetica, system-ui, -apple-system, BlinkMacSystemFont',
                          border: 'none',
                        }}
                      >
                        <Box sx={{ display: 'flex' }}>
                          <Text
                            variant="micro"
                            sx={{
                              mr: '4px',
                              color: 'text',
                              fontSize: ['12px', null, '12px', '15px'],
                              fontFamily:
                                'Roboto, Helvetica, system-ui, -apple-system, BlinkMacSystemFont',
                              '.cart-flyout &': {
                                fontSize: ['9px'],
                              },
                            }}
                          >
                            <strong>{option.name}</strong> <span sx={{ mx: ['4px'] }}>|</span>
                          </Text>
                          <Text
                            variant="micro"
                            sx={{
                              fontSize: ['12px', null, '12px', '15px'],
                              fontFamily:
                                'Roboto, Helvetica, system-ui, -apple-system, BlinkMacSystemFont',
                              '.cart-flyout &': {
                                fontSize: ['9px'],
                              },
                            }}
                          >
                            {option.value}
                          </Text>
                        </Box>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Box>
            )}
          </Box>
          <Box></Box>
        </CartItemRow>
        <CartItemRow className="cart-item-footer" align="center">
          <Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                <QuantityInput
                  bordered={false}
                  showLabel={false}
                  buttonSize={flyout ? 'xs' : 'sm'}
                  value={quantity}
                  isFabric={isFabric}
                  onChange={(newValue: number) =>
                    onQuantityUpdate(item.variant, item.title, newValue - quantity)
                  }
                />
              </Box>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <AddFavoriteButton
              product={mappedProduct}
              sfl={true}
              hiddenModal={true}
              productVariant={mappedVariant}
              small={flyout}
              callback={() => {
                onQuantityUpdate(item.variant, item.title, -1000, true)
              }}
            />
            <StyledButton
              data-cy="quantity-remove"
              onUpdate={() => onQuantityUpdate(item.variant, item.title, -1000)}
            >
              <Text
                sx={{
                  color: '#2B2828',
                  fontSize: flyout ? '14px' : '16px',
                  cursor: 'pointer',
                  textDecoration: 'underline',
                  textUnderlineOffset: '3px',
                }}
              >
                REMOVE
              </Text>
            </StyledButton>
          </Box>
        </CartItemRow>
        {quantity > quantityAvailable || mappedVariant.currentlyNotInStock ? (
          <CartItemRow className="cart-item-footer-message">
            {quantityAvailable > 0 && (
              <QuantityNote
                sxStyle={{
                  fontSize: ['9px', '14px', '9px !important'],
                }}
                quantity={quantityAvailable}
                selectedQuantity={quantity}
              />
            )}
            {mappedVariant.currentlyNotInStock && (
              <Flex sx={{ display: 'flex' }}>
                <Box
                  sx={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'space-between',
                    verticalAlign: 'top',
                    padding: '0',
                    mt: '5px',
                    fontFamily: 'Roboto, Helvetica, system-ui, -apple-system, BlinkMacSystemFont',
                  }}
                >
                  <Text
                    variant="micro"
                    sx={{ color: '#595858', textTransform: 'uppercase', mt: 'sm', fontSize: '9px' }}
                  >
                    Pre-order
                    <br></br>
                    <ProductVariantPriceNote
                      product={mappedProduct as ProductItemComponentType}
                      variant={mappedVariant}
                      small
                    />
                  </Text>
                </Box>
              </Flex>
            )}
          </CartItemRow>
        ) : null}
      </Flex>
    </Flex>
  )
}
