/** @jsx jsx */

import { Box, BoxProps, jsx } from 'theme-ui'

interface Props extends BoxProps {
  align?: 'center' | 'start' | 'end'
}

export const CartItemRow = ({ children, align = 'start', ...props }: Props) => (
  <Box
    sx={{
      display: 'flex',
      alignItems: align,
      justifyContent: 'space-between',
      gap: '12px',
    }}
    {...props}
  >
    {children}
  </Box>
)
