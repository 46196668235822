import { useState } from 'react'
import { FormikHelpers } from 'formik'
import { Maybe } from '~/@types/models'

type ApiRequestType = <T, R>(values: T) => Promise<T>

type FormSubmissionType<T> = {
  onSubmit: (values: T, actions: FormikHelpers<T>) => Promise<any>
  isLoading: boolean
  isSuccess: boolean
  serverError: Maybe<string>
}

const useFormSubmission = <T, R>(apiRequest: ApiRequestType): FormSubmissionType<T> => {
  const [loading, setLoading] = useState<boolean>(false)
  const [success, setSuccess] = useState<boolean>(false)
  const [serverError, setServerError] = useState<Maybe<string>>(null)

  const onSubmit = async (values: T, actions: FormikHelpers<T>) => {
    try {
      setLoading(true)
      await apiRequest(values)
      setSuccess(true)
      actions.resetForm()
      setServerError(null)
    } catch (e) {
      setServerError(e.message)
    } finally {
      setLoading(false)
      actions.setSubmitting(false)
    }
  }

  return {
    onSubmit,
    isLoading: loading,
    isSuccess: success,
    serverError,
  }
}

export default useFormSubmission
