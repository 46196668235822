import { useMemo, useCallback } from 'react'
import { Maybe, ProductItemComponentType, ProductVariantType } from '~/@types/models'
import { isCustomProduct } from '~/components/product-detail-panel/utils'
import { calculatePreorderMessage } from '~/utils/product-variant'

export const messages = {
  inStock: 'In Stock',
  customAvailability: 'Suggested Custom Option - Lead-time based on product and availability',
  readyToShip: ``,
}

type Props = {
  product: ProductItemComponentType
  variant: ProductVariantType
}

type OutputType = {
  currentlyInStock: boolean
  isCustom: boolean
  message: Maybe<string>
  preOrderMessage: Maybe<string>
  readyToShip: string
  getColorParameterTitle: () => Maybe<string>
}

const isProductWithCalculatedMessage = (
  product: ProductItemComponentType,
  variant: ProductVariantType
) => {
  return isCustomProduct(product) && variant.isCustomOption
}

const useProductAvailability = ({ variant, product, bundle }: Props): OutputType => {
  const currentlyInStock = bundle ? !bundle.currentlyNotInStock : !variant?.currentlyNotInStock
  const isCustomOption = variant?.isCustomOption
  const variantPreorderMessage = variant?.metafields && variant?.metafields?.productPreorderMessage

  const preOrderMessage = useMemo(() => {
    if (variantPreorderMessage) {
      return `Expected to Ship ${variantPreorderMessage}`
    }

    const calculatedMessage = isProductWithCalculatedMessage(product, variant)

    // if (calculatedMessage) {
    //   return calculatePreorderMessage()
    // }

    return null
  }, [variantPreorderMessage, variant])

  const customAvailability = isCustomOption && isCustomProduct(product)

  const message = useMemo(() => {
    if (customAvailability) {
      return messages.customAvailability
    }

    return null
  }, [isCustomOption, customAvailability])

  const getColorParameterTitle = useCallback(() => {
    if (!currentlyInStock && preOrderMessage) {
      return preOrderMessage
    } else if (currentlyInStock) {
      return messages.readyToShip
    }
  }, [currentlyInStock, preOrderMessage])

  return {
    currentlyInStock,
    message,
    preOrderMessage,
    isCustom: customAvailability,
    readyToShip: messages.readyToShip,
    getColorParameterTitle,
  }
}

export default useProductAvailability
