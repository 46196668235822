/** @jsx jsx */
import theme, { space } from '~/gatsby-plugin-theme-ui'

import { Link } from 'gatsby'
import { AspectRatio, Box, Card, Grid, Image, Text, jsx } from 'theme-ui'
import { Button } from '~/components/button'
import { H2, H3 } from '~/styled/components/typography'
import { useCartContext } from '~/context/cart-context'
import { Fragment } from 'react'

export const EmptyCartCard = ({
  image,
  link,
  style,
  title,
  type,
}: {
  image?: any
  link: string
  showMenu?: boolean
  title: string
  style?: React.CSSProperties
  type?: string
}) => (
  <Card
    sx={{
      justifySelf: 'center',
      position: 'relative',
      width: '100%',
      background: 'white',
      maxWidth: '300px',
    }}
    style={style}
  >
    <Link to={link} style={{ all: 'unset', cursor: 'pointer' }}>
      <AspectRatio ratio={1}>
        <Image
          src={image}
          sx={{
            width: '100%',
            height: '100%',
            alignContent: 'center',
            background: '#f8f8f8',
            objectFit: type === 'rug' ? 'cover' : 'contain',
          }}
        />
      </AspectRatio>
      <Grid
        columns={[1]}
        gap={space.gridGap3}
        sx={{
          flexDirection: 'column',
          boxSizing: 'border-box',
          marginTop: '1em',
          marginBottom: '1em',
        }}
      >
        <Button
          className="add-to-cart-button"
          variant="primary"
          to={link}
          type="button"
          hasArrow={false}
          data-cy="add-to-cart"
          label={'SHOP ALL ' + title}
        />
      </Grid>
    </Link>
  </Card>
)

export const EmptyCartSection = () => {
  const { newArrivals } = useCartContext()
  return (
    <Box
      sx={{
        p: [40, null, 60],
        pt: [0, null, 0],
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        textTransform: 'uppercase',
      }}
    >
      <H3 sx={{ textTransform: 'uppercase' }}>Your cart is empty.</H3>
      {newArrivals && (
        <Fragment>
          <Text sx={{ mb: 10, textTransform: 'uppercase' }}>Explore {newArrivals?.title}</Text>
          <EmptyCartCard
            image={newArrivals?.image}
            link={newArrivals?.slug}
            title={newArrivals?.title}
            type={newArrivals?.type}
          />
        </Fragment>
      )}
    </Box>
  )
}
