/** @jsx jsx */
import { useMemo } from 'react'
// @ts-ignore
import { jsx, Box, Flex } from 'theme-ui'
import useHeaderScrolling from '~/hooks/use-header-scolling'
import { Logo } from '~/components/logo'
import { HeaderVariantType } from '~/@types/models'
import HeaderCartOpener from '~/components/header/header-cart-opener'

type Props = {
  headerVariant: HeaderVariantType
  children?: React.ReactNode
}

const MobileContent = ({ headerVariant, children }: Props) => {
  const { isScrolling } = useHeaderScrolling()
  const isDarkSolid = headerVariant === 'dark_solid'

  const styleSettings = useMemo(() => {
    return {
      logo: {
        color: isDarkSolid ? 'main' : 'initial',
      },
      leftMenu: {
        isLight: (headerVariant === 'light' && !isScrolling) || (isDarkSolid && 'main'),
      },
    }
  }, [headerVariant, isScrolling])

  return (
    <Box
      className="mobile-content"
      sx={{
        display: 'grid',
        gridTemplateColumns: '1fr auto 1fr',
        alignItems: 'center',
        height: isScrolling ? 64 : 120,
        button: {
          px: 24,
          height: 50,
        },
      }}
    >
      <Box>{children}</Box>
      <Box>
        <Logo color={styleSettings.logo.color} />
      </Box>
      <Flex
        sx={{
          justifyContent: 'flex-end',
        }}
      >
        <HeaderCartOpener sxProps={{ textTransform: 'uppercase' }} />
      </Flex>
    </Box>
  )
}

export default MobileContent
