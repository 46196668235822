import { isBrowser } from '~/utils/marketing/index'
import { ProductItemComponentType } from '~/@types/models'

const GOOGLE_ADS_ID = process.env.GATSBY_GOOGLE_ADS_ID

export enum GoogleTrackedEvents {
  Search = 'search',
  AddToCart = 'add_to_cart',
  ViewCart = 'view_cart',
  BeginCheckout = 'begin_checkout',
  PageView = 'page_view',
  ViewItem = 'view_item',
}

type PintrkInputType = {
  eventType?: string
  action: GoogleTrackedEvents
  params?: object
}

const gtag = ({ eventType = 'event', action, params = {} }: PintrkInputType): void => {
  if (!isBrowser || !window.gtag) {
    return
  }

  window.gtag(eventType, action, params)
}

type TrackPageViewType = {
  path?: 'collection' | 'product' | 'search' | ''
  product?: ProductItemComponentType
}

const trackPageView = ({ path = '', product = null }: TrackPageViewType): void => {
  const getCategory = (path: string) => {
    switch (path) {
      case 'collection':
        return 'category'
      case 'product':
        return 'product'
      case 'search':
        return ''
      case '':
        return 'home'
      default:
        return ''
    }
  }

  const isProduct = path && path === 'product'

  // gtag({
  //   action: GoogleTrackedEvents.PageView,
  //   params: {
  //     send_to: GOOGLE_ADS_ID,
  //     ecomm_pagetype: getCategory(path),
  //     //ecomm_totalvalue: isProduct && product ? product.price_min : undefined,
  //     ecomm_totalvalue: isProduct && product ? product.price : undefined,
  //     ecomm_category: isProduct && product ? product.productType : undefined,
  //   },
  // })
}

export default gtag
