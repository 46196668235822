import { useCallback, useMemo, useState } from 'react'
import { Maybe, ProductDetailPageQuery, ProductVariantType } from '~/@types/models'
import querystring from 'query-string'
import { isAntiqueRug, isException, isFabric } from '../../utils'
import useFindVariant from '~/hooks/use-find-variant'
import { useQueryParams } from '~/hooks/use-query-params'
import useLinkState from '~/hooks/use-link-state'
import { navigate } from 'gatsby'

interface UseVariantControlProps {
  product: ProductDetailPageQuery['product']
  productVariant: ProductVariantType
}

export const useVariantControl = ({ product, productVariant }: UseVariantControlProps) => {
  const { queryParams } = useQueryParams()
  const { variant, ...rest } = queryParams as { variant: Maybe<string> }
  const { comesFromSaleCollections } = useLinkState()
  const [isNotSelectedVariant, setIsNotSelectedVariant] = useState(false)

  const { findVariant } = useFindVariant({
    product,
  })

  const selectedColor = productVariant?.selectedOptions?.find(variant => variant.name === 'Color')
    ?.value

  const changeVariant = useCallback(
    variantId => {
      const params = {
        variant: variantId,
        ...rest,
      }
      const options = comesFromSaleCollections
        ? {
            state: {
              comesFromSaleCollections: true,
            },
          }
        : {}
      navigate(`?${querystring.stringify(params)}`, options)
    },
    [variant, rest]
  )

  const onChangeParameter = useCallback(
    data => {
      const selectedVariant = findVariant(data)
      if (selectedVariant) {
        const shopifyId = selectedVariant.shopifyId2
        changeVariant(shopifyId)
        setIsNotSelectedVariant(false)
      } else if (data.Size === 'Custom') {
        setIsNotSelectedVariant(false)
      } else {
        setIsNotSelectedVariant(true)
      }
    },
    [changeVariant, product.variants]
  )

  return {
    isNotSelectedVariant,
    onChangeParameter,
    selectedColor,
  }
}
