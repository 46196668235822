import React from 'react'
import { IMenuImageGroup } from '~/context/menu-context'
import { Grid, Box, Text, ThemeUIStyleObject } from 'theme-ui'
import LinkComponent from './LinkComponent'
import ResponsiveImage from '~/componentsV2/ResposiveImage'

const MenuImageGroup: React.FC<IMenuImageGroup> = group => {
  return (
    <div className="nav-item-image">
      <ResponsiveImage desktop={group.desktopImage} mobile={group.mobileImage} alt={group.name}>
        <div className="overlay" />
      </ResponsiveImage>
      <div>
        <span
          style={{
            paddingBottom: '2px',
          }}
        >
          {group.name}
        </span>
      </div>
    </div>
  )
}

export default MenuImageGroup
