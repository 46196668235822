/** @jsx jsx */
// @ts-ignore
import { jsx, Box, Flex, Container } from 'theme-ui'
import { globalHistory } from '@reach/router'
import { FC, useEffect, useRef, Fragment } from 'react'
import SearchResults from '~/components/search-results'
import useMediaQuery from '~/hooks/use-media-query'
import InstantSearchWrapper from '~/features/instant-search'
import SearchBox from '~/features/instant-search/search-box'
import StateResults, { StateResultsType } from '~/features/instant-search/state-results'
import useOnClickOutside from '~/hooks/use-on-click-outside'
import { ProductHit } from '~/features/instant-search/types'

type Props = {
  showSearch?: boolean
  toggleSearch: (value: boolean) => any
}

const Search: FC<Props> = ({ showSearch, toggleSearch }) => {
  const ref = useRef<HTMLDivElement>(null)
  const resultRef = useRef<HTMLDivElement>(null)

  useOnClickOutside(ref, () => {
    toggleSearch(false)
  })

  const closeOnEsc = (e: KeyboardEvent) => {
    if (e.keyCode === 27) {
      toggleSearch(false)
    }
  }

  useEffect(() => {
    document.addEventListener(`keydown`, closeOnEsc)

    return () => {
      document.removeEventListener(`keydown`, closeOnEsc)
    }
  }, [])

  const isMobile = useMediaQuery({
    query: breakpoints => `(max-width: ${breakpoints[2]})`,
  })

  useEffect(() => {
    if (showSearch && isMobile) {
      toggleSearch(false)
    }
  }, [toggleSearch, isMobile])

  useEffect(() => {
    // close search when unmounted
    return globalHistory.listen(({ action }) => {
      if (action === 'PUSH' && showSearch) {
        toggleSearch(false)
      }
    })
  }, [toggleSearch, showSearch])

  // useEffect(() => {
  //   if (typeof window !== 'undefined') {
  //     document.addEventListener('click', e => {
  //       const target = e.target as HTMLElement
  //       if (
  //         showSearch &&
  //         !resultRef.current?.contains(target) &&
  //         !target.classList.contains('search-icon')
  //       ) {
  //         toggleSearch(false)

  //         document.removeEventListener('click', () => {
  //           toggleSearch(false)
  //         })
  //       }
  //     })
  //   }
  // }, [toggleSearch, showSearch])

  const hitRef = useRef<ProductHit[]>()

  return (
    <Box ref={ref} role="search" aria-label="Search header">
      <InstantSearchWrapper>
        {isMobile ? <SearchBox /> : <div />}
        <StateResults>
          {({ searchState }: StateResultsType) => {
            const hasQuery = searchState && searchState.query && searchState.query.length > 0

            return (
              <Box
                ref={resultRef}
                className="search-results"
                sx={{
                  position: isMobile ? 'unset' : 'absolute',
                  top: isMobile ? 'unset' : '95px',
                  zIndex: isMobile ? 1 : '10',
                  mt: isMobile ? 0 : '-1px',
                  width: '100%',
                  bg: 'main',
                }}
              >
                {!isMobile ? <SearchBox hitRef={hitRef} closeSearch={toggleSearch} /> : <div />}
                {hasQuery && (
                  <Container
                    as={Flex}
                    sx={{
                      py: ['md1', null, 'l'],
                      minHeight: 200,
                      justifyContent: 'center',
                      alignItems: 'center',
                      maxWidth: 1340,
                      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                    }}
                  >
                    <SearchResults hitRef={hitRef} />
                  </Container>
                )}
              </Box>
            )
          }}
        </StateResults>
      </InstantSearchWrapper>
    </Box>
  )
}

export default Search
