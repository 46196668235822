import { useLocation } from '@reach/router'
import queryString, { ParsedQuery } from 'query-string'

type UseQueryParams = {
  queryParams: ParsedQuery
}

const useQueryParams = (): UseQueryParams => {
  const { search } = useLocation()
  const queryParams = queryString.parse(search)

  return {
    queryParams,
  }
}

export { useQueryParams }
