/** @jsx jsx **/
import { jsx, SxStyleProp, Text } from 'theme-ui'
import { space } from '~/gatsby-plugin-theme-ui'

interface Props {
  price: string
  sxProps?: SxStyleProp
}

const CompareAtPrice = ({ price, sxProps }: Props) => (
  <Text
    as="span"
    sx={{
      textDecoration: 'line-through',
      mb: space.xxs,
      color: 'muted',
      lineHeight: ['heading', null, 'heading'],
      ...sxProps,
    }}
  >
    {price}
  </Text>
)

export default CompareAtPrice
