import React, { useState, useRef, useEffect, useCallback, FC, Fragment } from 'react'
import MenuDots from '../icons/menuDots'
import { none } from 'ramda'
import { initial } from 'lodash'
import Pencil from '../icons/pencil'
import { Share } from '../icons/share'
import TrashCan from '../icons/trashCan'
import { Box, Button, Flex, Text } from 'theme-ui'
import GoTo from '../icons/goTo'
import Move from '../icons/move'
import { Link } from 'gatsby'
import urls from '~/common/urls'
import { useCustomerContext } from '~/context/customer-context'
import ProjectsMoveModal from '~/templates/projects-move-modal'
import NotificationModal from '~/templates/notificationModal'
import { getProduct, getProductByHandle, getVariants } from '~/graphql/getters'
import ShareLinkModal from '~/templates/shareLinkModal'
import { CustomerType } from '~/@types/models'
import { Copy } from '../icons/copyIcon'
import { axiosRequests } from '~/utils/axios'
import { useDashboard } from '../account-layout/context/dashboardContext'

type Props = {
  type: boolean
  productName?: any
  productVariant?: any
  product?: any
  listName?: string
  productUrl?: any
  listId?: any
  listProd?: any
  sfl?: boolean
}

const CustomMenuButton: FC<Props> = ({
  type,
  productUrl,
  productName,
  productVariant,
  product,
  listName,
  listId,
  listProd,
  sfl,
}) => {
  const { sessionid, regid, removeFromSfl, removeFromWishlist } = useDashboard()
  const { customer, fetchCustomer } = useCustomerContext()
  const AxiosFunctions = axiosRequests()
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [toastlIsOpen, settoastlIsOpen] = useState(false)
  const [toastlIsOpen2, settoastlIsOpen2] = useState(false)
  const [isCopy, setIsCopy] = useState(false)
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [isProject, setisProject] = useState(type)
  const [isSuccesful, setisSuccesful] = useState<boolean>(false)
  const [toastText, settoastText] = useState('')
  const [wishlistName, setwishlistName] = useState('')
  const [variantsIds, setvariantsIds] = useState({})
  const [shareUrl, setshareUrl] = useState('')
  const [productsArray, setproductsArray] = useState({})
  const [loading, setLoading] = useState<boolean>(false)
  const customerInfo = customer as CustomerType
  const productVarianUrl = productUrl?.slice(34)
  const productHandle = getSubstringBeforeQuestionMark(productVarianUrl)

  const hasProducts = false
  let listOfProducts
  const listsArray = []
  const listsNames = []

  function getSubstringBeforeQuestionMark(url) {
    if (!!url) {
      const questionMarkIndex = url.indexOf('?')
      return questionMarkIndex !== -1 ? url.slice(0, questionMarkIndex) : url
    }
  }

  const removeWishlist = async () => {
    await AxiosFunctions.removeWishlist(sessionid, regid, listId)
      .then(function (response) {
        // setwishlistName(listName)
        settoastText('Deleted wishlist')
        setisSuccesful(true)
        settoastlIsOpen(true)
        setTimeout(() => {
          settoastlIsOpen(false)
          setIsMenuOpen(false)
          fetchCustomer()
        }, 2200)
      })
      .catch(function () {
        // setwishlistName(listName)
        settoastText('Could not delete wishlist')
        setisSuccesful(false)
        settoastlIsOpen(true)
        setTimeout(() => {
          settoastlIsOpen(false)
        }, 2200)
      })
  }

  const handleRemoveFromWishlist = () => {
    removeFromWishlist({
      productVariantId: productVariant,
      shopifyId: product,
      product: {
        handle: productHandle,
      },
      listId,
    })
    settoastText('Item removed')
    setisSuccesful(true)
    settoastlIsOpen(true)
    setTimeout(() => {
      settoastlIsOpen(false)
      setIsMenuOpen(false)
      fetchCustomer()
    }, 3200)
  }

  useEffect(() => {
    if (hasProducts) {
      const variansidvalues = Object.values(listOfProducts)
      setvariantsIds(variansidvalues)
    }
  }, [listName])

  useEffect(() => {
    const fetchProductData = async listEntry => {
      try {
        const data = await getProductByHandle(listEntry)
        // Update the listEntry with prodImageSrc
        setproductsArray(product => ({
          ...product,
          [listEntry]: data,
        }))
      } catch (error) {
        console.error('Error fetching product data:', error)
      }
    }

    const fetchAllProductData = async () => {
      await Promise.all(listsArray.map(fetchProductData))
      setLoading(false)
    }
    fetchAllProductData()
  }, [listName])

  const copyToClipboard = (textToCopy: string) => {
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        // console.log('Text copied to clipboard:', textToCopy);
        settoastText('Copied to clipboard')
        settoastlIsOpen(true)
      })
      .catch(error => {
        // console.error('Error copying text:', error);
        return error
      })
  }

  function createShareUrl() {
    const productsArray = listProd
    const onlyIdsArray: number[] = []
    const onlyVIdsArray: number[] = []
    // console.log(productsArray);
    productsArray.map(product => {
      const onlyId = product.empi.toString()
      const onlyVId = product.epi.toString()
      onlyIdsArray.push(onlyId)
      onlyVIdsArray.push(onlyVId)
      return ''
    })

    const data = {
      productsIds: onlyIdsArray,
      variantIds: onlyVIdsArray,
      listName: listName,
      userName: customer?.displayName,
    }
    const encodedData = btoa(JSON.stringify(data))
    const hostName =
      window.location.hostname + (window.location.hostname === 'localhost' ? ':8002' : '')
    const shareUrl = `${hostName}${urls.account.accountPortfoliosShared}?data=${encodedData}`
    setshareUrl(shareUrl)
    // setshareUrl(shareUrl)
    settoastlIsOpen2(true)
    // copyToClipboard(shareUrl)
  }

  const openModal = async (copyBoolean: boolean) => {
    setIsCopy(copyBoolean)
    setModalIsOpen(true)
    setIsMenuOpen(false)
  }

  const closeModal = () => {
    setModalIsOpen(false)
  }
  const menuRef = useRef()
  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen)
  }

  const openToast = async (copyBoolean: boolean) => {
    settoastlIsOpen(true)
  }

  const closeToast = () => {
    settoastlIsOpen(false)
  }

  const openToast2 = async () => {
    settoastlIsOpen2(true)
  }

  const closeToast2 = () => {
    settoastlIsOpen2(false)
  }

  const handleOutsideClick = event => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsMenuOpen(false)
      if (toastlIsOpen) {
        settoastlIsOpen(false)
      }
      if (toastlIsOpen2) {
        settoastlIsOpen2(false)
      }
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick)
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [])

  return (
    <div
      style={{
        position: 'absolute',
        top: '0rem',
        right: '0rem',
      }}
    >
      <Button
        onClick={handleMenuToggle} // Change to onClick event
        sx={{
          background: isMenuOpen ? 'lightGray' : 'none',
          border: 'none',
          cursor: 'pointer',
          width: '40px',
          height: '40px',
          borderRadius: 'none',
          transition: 'all .3s ease',
          '&:hover': {
            background: 'lightGray',
          },
        }}
      >
        <MenuDots color="#C8C8C8" width="18px" />
      </Button>
      {isMenuOpen && (
        <div
          className="flex flex-col"
          ref={menuRef}
          style={{
            position: 'absolute',
            // top: '1.5rem',
            right: '0',
            width: '100px',
            // borderRadius: '15px',
            backgroundColor: 'white',
            // border: '1px solid gray',
            textAlign: 'initial',
            boxShadow: 'rgba(153, 153, 153, 0.5) 0px 0px 4px 0px',
          }}
        >
          {isProject && (
            <ul
              className="flex"
              style={{
                listStyle: 'none',
                padding: '10px',
                margin: 0,
                lineHeight: '2.5em',
              }}
            >
              {!sfl ? (
                <Fragment>
                  <li>
                    <Box
                      sx={{
                        color: '#2b2b2b',
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '6px',
                        textDecoration: 'none',
                        '&:hover': {
                          opacity: '80%',
                        },
                      }}
                      onClick={(e: any) => {
                        openModal(false)
                      }}
                    >
                      Move
                    </Box>
                  </li>
                  <li>
                    <Box
                      sx={{
                        color: '#2b2b2b',
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '6px',
                        textDecoration: 'none',
                        '&:hover': {
                          opacity: '80%',
                        },
                      }}
                      onClick={(e: any) => {
                        openModal(true)
                      }}
                    >
                      Copy
                    </Box>
                  </li>
                </Fragment>
              ) : null}
              <li>
                <span
                  onClick={(e: any) => {
                    sfl
                      ? removeFromSfl({
                          productVariantId: productVariant,
                          shopifyId: product,
                          product: {
                            handle: productHandle,
                          },
                        })
                      : handleRemoveFromWishlist()
                  }}
                >
                  <Box
                    sx={{
                      color: '#2b2b2b',
                      cursor: 'pointer',
                      display: 'flex',
                      alignItems: 'center',
                      gap: '6px',
                      textDecoration: 'none',
                      '&:hover': {
                        opacity: '80%',
                      },
                    }}
                  >
                    Delete
                  </Box>
                </span>
              </li>
            </ul>
          )}
          {!isProject && (
            <ul
              className="flex"
              style={{
                listStyle: 'none',
                padding: '10px',
                margin: 0,
                lineHeight: '2em',
              }}
            >
              {/* <li>
              <div style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', gap: '6px' }}>
                <Pencil color="#C8C8C8" width="24px" />
                Rename
              </div>
            </li> */}
              {listName !== 'default' && (
                <li>
                  <Link
                    style={{
                      textDecoration: 'none',
                    }}
                    to={`${urls.account.accountPortfoliosEdit}/:${listId}`}
                  >
                    <Box
                      sx={{
                        color: '#2b2b2b',
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '6px',
                        textDecoration: 'none',
                        '&:hover': {
                          opacity: '80%',
                        },
                      }}
                    >
                      Rename
                    </Box>
                  </Link>
                </li>
              )}
              <li>
                <Box
                  sx={{
                    color: '#2b2b2b',
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '6px',
                    textDecoration: 'none',
                    '&:hover': {
                      opacity: '80%',
                    },
                  }}
                  onClick={() => createShareUrl()}
                >
                  {' '}
                  Share
                </Box>
              </li>
              {listName !== 'default' && (
                <li>
                  <span
                    onClick={(e: any) => {
                      removeWishlist()
                    }}
                  >
                    <Box
                      sx={{
                        color: '#2b2b2b',
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '6px',
                        textDecoration: 'none',
                        '&:hover': {
                          opacity: '80%',
                        },
                      }}
                    >
                      {' '}
                      Delete
                    </Box>
                  </span>
                </li>
              )}
            </ul>
          )}
        </div>
      )}
      {isProject && modalIsOpen && (
        <Box>
          <ProjectsMoveModal
            fromList={listId}
            isMobile={false}
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            product={product}
            productName={productName}
            variant={productVariant}
            productHande={productHandle}
            isCopy={isCopy}
          />
        </Box>
      )}
      {toastlIsOpen && (
        <Box>
          <NotificationModal
            toastText={toastText}
            wishlistName={wishlistName}
            isFromModal={true}
            isSuccesful={isSuccesful}
            isMobile={false}
            isOpen={toastlIsOpen}
            onRequestClose={closeToast}
          />
        </Box>
      )}
      {toastlIsOpen2 && (
        <Box>
          <ShareLinkModal
            isOpen={toastlIsOpen2}
            shareLink={shareUrl}
            isMobile={false}
            onRequestClose={closeToast2}
          />
        </Box>
      )}
    </div>
  )
}

export default CustomMenuButton
function setLoading(arg0: boolean) {
  throw new Error('Function not implemented.')
}
