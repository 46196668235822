/** @jsx jsx **/
import { jsx, Box, Flex, Grid, Container, Text, Divider } from 'theme-ui'
import FooterLinksCategory from '~/components/footer/footer-links-category'
import FooterLogoLinks from '~/components/footer/footer-logo-links'
import urls from '~/common/urls'
import { space } from '~/gatsby-plugin-theme-ui'
import KlaviyoForm from '~/utils/klaviyoNewsletterForm'
import { Link } from '~/componentsV2/linkPrev/LinkPrev'

const Footer = () => {
  const klaviyoFooterFormId = process.env.GATSBY_KLAVIYO_FOOTER_NEWSLETTER_FORM_ID || ''

  const footerLinks1st = {
    name: 'Customer Care',
    links: [
      {
        label: 'FAQ',
        url: urls.pages.faq,
      },
      {
        label: 'Ordering',
        url: urls.pages.ordering,
      },
      {
        label: 'Shipping',
        url: urls.pages.orderingShipping,
      },
      {
        label: 'Contact Us',
        url: urls.pages.contact,
      },
      {
        label: '1.888.216.4277',
        url: urls.pages.contact,
      },
      {
        label: 'SALE',
        url: urls.pages.sale,
      },
    ],
  }
  const footerLinks2nd = {
    name: 'Our Company',
    links: [
      {
        label: 'About Ben Soleimani',
        url: urls.pages.about,
      },
      {
        label: 'About Ben',
        url: urls.pages.aboutBen,
      },
      {
        label: 'Press',
        url: urls.blog.press,
      },
      {
        label: 'Careers',
        url: urls.pages.careers,
      },
      {
        label: 'Press Inquiries',
        url: urls.blog.press,
      },
      {
        label: 'Journal',
        url: urls.blog.journal,
      },
    ],
  }
  const footerLinks3rd = {
    name: 'Concierge',
    links: [
      {
        label: 'Customer Service',
        url: urls.pages.contact,
      },
      {
        label: 'Design Services',
        url: urls.pages.designServices,
      },
      {
        label: 'Custom Design Studio',
        url: urls.pages.bespokeServices,
      },
      {
        label: 'Digital Catalogues',
        url: urls.pages.catalog,
      },
      {
        label: 'Product Care',
        url: urls.pages.productCare,
      },
      {
        label: 'Gift Card',
        url: urls.pages.giftCard,
      },
    ],
  }
  const footerTrade = {
    name: 'Trade',
    links: [
      {
        label: 'Register',
        url: urls.pages.tradeProgram,
      },
      {
        label: 'Login',
        url: urls.account.signIn,
      },
    ],
  }
  const footerShowrooms = {
    name: 'Showrooms',
    links: [
      {
        label: 'New York Showroom',
        url: urls.pages.nycShowroom,
      },
      {
        label: 'Los Angeles Showroom',
        url: urls.pages.showroom,
      },
    ],
  }
  const footerSocialMediaLinks = {
    name: 'Follow Us',
    links: [
      {
        label: 'Facebook',
        url: urls.pages.facebook,
        target: '_blank',
        rel: 'noopener noreferrer',
      },
      {
        label: 'Instagram',
        url: urls.pages.instagram,
        target: '_blank',
        rel: 'noopener noreferrer',
      },
      {
        label: 'Pinterest',
        url: urls.pages.pinterest,
        target: '_blank',
        rel: 'noopener noreferrer',
      },
      {
        label: 'LinkedIn',
        url: urls.pages.linkedIn,
        rel: 'noopener noreferrer',
      },
    ],
  }
  return (
    <Box
      as="footer"
      sx={{
        position: 'relative',
        backgroundColor: 'footer.background',
        pt: [70, null, null, 138],
        pb: [70, null, null, 100],
        zIndex: 1000,
      }}
    >
      <Container
        sx={{
          // pl: [10, 20, 24],
          pr: [10, 20, 24],
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Grid
            sx={{
              gridTemplateColumns: [
                'repeat(1, 1fr)',
                'repeat(2, 1fr)',
                'repeat(3, 1fr)',
                'repeat(5, 3fr) 5fr',
              ],
              pb: [0, null, null, 70],
              pl: [0, 0, null, 0],
              h3: {
                m: 0,
                mb: 24,
                color: 'main',
              },
              a: {
                variant: 'links.footer',
                height: '30px',
              },
            }}
          >
            <FooterLinksCategory
              key={footerLinks1st.name}
              name={footerLinks1st.name}
              links={footerLinks1st.links}
            />
            <FooterLinksCategory
              key={footerLinks2nd.name}
              name={footerLinks2nd.name}
              links={footerLinks2nd.links}
            />
            <FooterLinksCategory
              key={footerLinks3rd.name}
              name={footerLinks3rd.name}
              links={footerLinks3rd.links}
            />
            <Box
              sx={{
                paddingBottom: 0,
                marginTop: ['0', '40px', '0'],
              }}
            >
              <FooterLinksCategory
                key={footerTrade.name}
                name={footerTrade.name}
                links={footerTrade.links}
              />
              <FooterLinksCategory
                key={footerShowrooms.name}
                name={footerShowrooms.name}
                links={footerShowrooms.links}
              />
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
              <Divider
                sx={{
                  color: '#fff',
                  borderLeft: [null, null, null, '1px solid #fff'],
                  height: [null, null, '400px'],
                  borderBottom: 'none',
                  marginTop: ['0', '40px', '0'],
                }}
              />{' '}
            </Box>
            <Box
              sx={{
                gridRowStart: [5, 2, 2, 1, 1],
                gridColumn: ['1 / span 1', '2 / span 2', '2 / span 2', '6 / span 1'],
                marginTop: ['40px', '40px', '0'],
              }}
            >
              <Box
                sx={{
                  color: 'main',
                  maxWidth: 470,
                  textAlign: 'left',
                }}
              >
                <h3
                  sx={{
                    fontSize: [18, null, 18],
                    fontWeight: '700 !important',
                    color: 'footer.heading',
                    letterSpacing: 1.95,
                  }}
                >
                  KEEP IN TOUCH
                </h3>
                <Text
                  sx={{
                    fontSize: '14px',
                    color: '#fefefe',
                    a: {
                      color: 'main',
                    },
                    mb: space.sm1,
                    letterSpacing: 0.7,
                    lineHeight: '28px',
                  }}
                >
                  Sign up for our email to get the first look at new product drops, design collabs &
                  more.
                  {/* By entering your email address below, you consent to receive our newsletter with
                access to our latest collections, events, initiatives, and promotions. More details
                are provided in our <Link to={urls.pages.privacyPolicy}>Privacy Policy.</Link> */}
                </Text>
                <Box
                  sx={{
                    marginBottom: space.md1 * 2,
                    form: {
                      display: 'flex',
                      '.hs-email > label:first-child': {
                        display: 'none',
                      },
                      '.hs-email': {
                        mb: 0,
                      },
                      '.hs-submit': {
                        mt: 0,
                        minWidth: 120,

                        '&:after': {
                          display: 'none',
                        },
                      },
                      '.hs-input, .hs-button': {
                        height: '40px !important',
                      },
                    },
                  }}
                >
                  <KlaviyoForm formId={klaviyoFooterFormId} loading="Loading ..." />
                </Box>
              </Box>
              <Container sx={{ display: 'flex', justifyContent: 'space-between', pl: 0 }}>
                <Box
                  sx={{
                    pb: [0, null, null, 40],
                    pl: [0, 0, null, 0],
                    h3: {
                      m: 0,
                      mb: 24,
                      color: 'main',
                    },
                    a: {
                      variant: 'links.footer',
                    },
                  }}
                >
                  <FooterLogoLinks
                    key={footerSocialMediaLinks.name}
                    name={footerSocialMediaLinks.name}
                    links={footerSocialMediaLinks.links}
                  />
                </Box>
                <Box
                  sx={{
                    pb: [0, null, null, 40],
                    pl: [0, 0, null, 0],
                    h3: {
                      m: 0,
                      mb: 24,
                      color: 'main',
                    },
                  }}
                >
                  <Link to={urls.pages.contact}>
                    <h3
                      sx={{
                        fontSize: [18, null, 18],
                        fontWeight: '700 !important',
                        letterSpacing: 1.95,
                      }}
                    >
                      CONTACT US
                    </h3>
                  </Link>
                  <a href="tel:18882164277">
                    <Text
                      sx={{
                        fontSize: 14,
                        color: '#fefefe',
                        mb: space.sm1,
                        letterSpacing: 0.7,
                      }}
                    >
                      1.888.216.4277
                    </Text>
                  </a>
                </Box>
              </Container>
            </Box>
          </Grid>
        </Box>
        <Divider sx={{ color: '#fff' }} />
        <Grid
          columns={[1, 2]}
          sx={{
            alignItems: 'flex-end',
            textAlign: ['center', 'center', 'initial'],
          }}
        >
          <Box
            sx={{
              mb: [space.md1, null, 0],
            }}
          >
            <Box
              as="span"
              sx={{
                variant: 'links.footer',
                display: 'inline-block',
                lineHeight: ['50px', null, 'initial'],
                maxWidth: ['180px', null, 'initial'],
              }}
            >
              All rights reserved{' '}
              <span
                sx={{
                  display: ['none', null, 'initial'],
                }}
              ></span>{' '}
              {new Date().getFullYear()} &copy; Ben Soleimani
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifySelf: ['center', 'center', 'end'],
              mb: [space.md2, null, 0],
            }}
          >
            <Flex
              sx={{
                width: '100%',
                flexDirection: ['column', null, 'row'],
                a: {
                  variant: 'links.footer',
                  display: 'inline-block',
                  px: space.md,
                },
              }}
            >
              <Link to={urls.pages.terms}>Terms & Conditions</Link>
              <span
                sx={{
                  color: '#fff',
                  alignSelf: 'center',
                }}
              >
                |
              </span>
              <Link to={urls.pages.privacyPolicy}>Privacy</Link>
            </Flex>
          </Box>
        </Grid>
        {process.env.GATSBY_ENV === 'production' && (
          <script
            type="text/javascript"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: `(function (url) {
                /*Tracking Bootstrap Set Up DataLayer objects/properties here*/
                window.rakutenDataLayer = false; // Set custom DataLayer name if 'DataLayer' conflicts
              var dln = window.rakutenDataLayer || 'DataLayer';
              if(!window[dln]){
                  window[dln] = {};
                }
                if(!window[dln].events){
                  window[dln].events = {};
                }
                window[dln].events.SPIVersion = window[dln].events.SPIVersion || "v3.4.1";

                var loc, ct = document.createElement("script");
                ct.type = "text/javascript";
                ct.async = true; ct.src = url; loc = document.getElementsByTagName('script')[0];
                loc.parentNode.insertBefore(ct, loc);
                }(document.location.protocol + "//tag.rmp.rakuten.com/118854.ct.js"));`,
            }}
          />
        )}
      </Container>
      <span id="footer" />
    </Box>
  )
}

export default Footer
