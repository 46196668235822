/** @jsx jsx */
// @ts-ignore
import { jsx, SxStyleProp, Text } from 'theme-ui'
import { FC } from 'react'
import useQuantityNote from '~/hooks/use-quantity-note'

type Props = {
  quantity: number
  selectedQuantity: number
  sxStyle?: SxStyleProp
}

const QuantityNote: FC<Props> = ({ quantity, selectedQuantity, sxStyle }) => {
  const { message } = useQuantityNote({
    selectedQuantity: selectedQuantity,
    availableQuantity: quantity,
  })

  return (
    <Text
      variant="micro"
      sx={{ color: '#595858', textTransform: 'uppercase', mt: 'sm', ...sxStyle }}
    >
      {message}
    </Text>
  )
}

export default QuantityNote
