/** @jsx jsx **/
// @ts-ignore
import { jsx, Box, AspectRatio, SxStyleProp } from 'theme-ui'
import { FC } from 'react'
// @ts-ignore
import { AspectRatioProps } from '@theme-ui/components'

type Props = AspectRatioProps & {
  width?: Array<string | number | null> | number | string
  height?: Array<string | number | null> | number | string
  center?: boolean
  sxProps?: SxStyleProp
  sxWrap?: SxStyleProp
  classNameWrap?: string
}

const ImageWrapper: FC<Props> = ({
  children,
  width,
  height,
  center = true,
  ratio,
  sxProps = {},
  sxWrap = {},
  classNameWrap,
  ...rest
}) => {
  return (
    <Box
      className={classNameWrap}
      sx={{
        ...(width && {
          width: width,
          minWidth: width,
        }),
        ...(height && {
          height: height,
          minHeight: height,
        }),
        ...sxWrap,
      }}
      {...rest}
    >
      <AspectRatio
        ratio={ratio}
        sx={{
          bg: 'bg',
          overflow: 'hidden',
          ...(center && {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }),
          '.gatsby-image-wrapper': {
            width: '100%',
            minHeight: '100%',
          },
          img: {
            maxHeight: '100%',
          },
          ...sxProps,
        }}
      >
        {children}
      </AspectRatio>
    </Box>
  )
}

export default ImageWrapper
