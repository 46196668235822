/** @jsx jsx **/
import { Box, jsx, SxStyleProp } from 'theme-ui'
import {
  Accordion as AccordionComponent,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
} from '@reach/accordion'
import '@reach/accordion/styles.css'
import { FC, ReactNode, useEffect, useState } from 'react'
import MarkdownSerializer from 'markdown-it'
import { PlusMenuExpand } from '~/components/icons/plusMenuExpand'
import { generateKey } from '~/utils/react-object-key-gen'
import { Link } from 'gatsby'
import { useLocation } from 'react-use'
import { generateFaqSlug } from '../faq-category/helpers'

type PropsItem = {
  label: ReactNode | string
  content: ReactNode | string
}

type Props = {
  data: PropsItem[]
  variant?: 'primary' | 'secondary' | 'footer'
  sxPanel?: SxStyleProp
  sxButton?: SxStyleProp
  sxLabel?: SxStyleProp
  enableMarkdownContent?: boolean
  defaultIndexes: number[]
}

const md = new MarkdownSerializer({
  html: true,
  linkify: true,
  typographer: true,
})

const Accordion: FC<Props> = ({
  data,
  variant = 'primary',
  sxPanel,
  sxButton,
  enableMarkdownContent = false,
}) => {
  const { hash, pathname } = useLocation()
  const [indices, setIndices] = useState<number | undefined>()

  function toggleItem(toggledIndex: number) {
    if (indices === toggledIndex) {
      setIndices(undefined) // Colapsa o item se já estiver aberto
    } else {
      setIndices(toggledIndex) // Abre o novo item
    }
  }

  const setPageURI = (title: string) => {
    if (!pathname?.includes('faqs')) return false
    const slug = generateFaqSlug(title)
    const href = `#${slug}`

    if (typeof document !== 'undefined') {
      document?.getElementById(href)?.scrollIntoView()
    }
  }

  useEffect(() => {
    if (hash) {
      data.forEach(({ label: title }, index) => {
        const slug = generateFaqSlug(title as string)

        if (slug === hash.replace('#', '')) {
          setIndices(index)

          if (typeof document !== 'undefined') {
            document?.getElementById(hash)?.scrollIntoView()
          }
        }
      })
    }
  }, [hash, data])

  const footerAcc = (item: any) => {
    if (
      item.label === 'Customer Care' ||
      item.label === 'Concierge' ||
      item.label === 'Our Company' ||
      item.label === 'Trade' ||
      item.label === 'Showrooms'
    ) {
      return item.content.map((itm: any, index: number) => {
        if (itm.label === '1.888.216.4277') {
          return (
            <div
              key={index}
              sx={{
                paddingBottom: ['11px'],
                height: ['30px'],
                marginBottom: ['20px'],
                fontSize: ['14px'],
              }}
            >
              <a
                href="tel:18882164277"
                sx={{
                  fontFamily: 'helvetica',
                  letterSpacing: 'normal',
                }}
              >
                {itm.label}
              </a>
            </div>
          )
        } else if (itm.label === 'Press Inquiries') {
          return (
            <div
              key={index}
              sx={{
                paddingBottom: ['11px'],
                height: ['30px'],
                marginBottom: ['20px'],
                fontSize: ['14px'],
              }}
            >
              <a
                href="mailto:press@bensoleimani.com"
                sx={{
                  fontFamily: 'helvetica',
                  letterSpacing: 'normal',
                }}
              >
                {itm.label}
              </a>
            </div>
          )
        } else {
          return (
            <div
              key={index}
              sx={{
                paddingBottom: ['11px'],
                height: ['30px'],
                marginBottom: ['20px'],
                fontSize: ['14px'],
              }}
            >
              <Link
                to={itm.url}
                sx={{
                  fontFamily: 'helvetica',
                  letterSpacing: 'normal',
                }}
              >
                {itm.label}
              </Link>
            </div>
          )
        }
      })
    } else {
      return item.content
    }
  }

  return (
    <AccordionComponent className={variant} index={indices} onChange={toggleItem}>
      {data.map((item, index) => (
        <AccordionItem
          id={generateFaqSlug(item.label as string)}
          key={generateKey(item.label)}
          sx={{
            display: ['flex'],
            justifyContent: 'center',
            flexDirection: 'column',
            '&.footer': {
              display: ['block'],
              textAlign: ['left', 'left'],
              minWidth: ['auto', '120px', '240px'],
              paddingRight: ['auto', '26px'],
            },
          }}
          className={variant}
        >
          <AccordionButton
            onClick={() => {
              const { label: title } = item
              setPageURI(title as string)
            }}
            className={variant}
            sx={{
              '&.primary': {
                variant: 'styles.button',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
                minHeight: 80,
                px: 4,
                py: 20,
                mb: '-1px',
                borderTop: '1px solid',
                borderBottom: '1px solid',
                borderColor: '#F0F0F0',
                fontSize: 3,
                ...sxButton,
              },
              '&.footer': {
                variant: 'styles.button',
                color: 'white',
                border: 'none',
                appearance: 'none',
                marginBottom: ['40px'],
                fontFamily: 'helvetica!important',
                padding: '0',
                display: ['flex', 'initial'],
                justifyContent: 'space-between',
                width: ['100%', 'initial'],
                '&[aria-expanded="true"]': {
                  svg: {
                    transform: 'rotate(0)',
                    transition: '.3s all',
                  },
                  polygon: {
                    visibility: 'hidden',
                    opacity: 0,
                    transition: 'visibility 0s 0.5s, opacity 0.5s linear',
                  },
                },
              },
              '&[aria-expanded="true"]': {
                svg: {
                  transform: 'rotate(180deg)',
                  transition: '.3s all',
                },
                polygon: {
                  visibility: 'hidden',
                  opacity: 0,
                  transition: 'visibility 0s 0.5s, opacity 0.5s linear',
                },
              },
            }}
          >
            <span
              className={variant}
              style={{
                fontFamily: 'Helvetica, system-ui, -apple-system, BlinkMacSystemFont',
                fontSize: '16px',
                color: '#2B2B2B',
              }}
              sx={{
                '&.footer': {
                  color: '#fff!important',
                  textTransform: 'uppercase',
                  letterSpacing: ['0.5px'],
                  fontWeight: ['700'],
                  fontSize: ['18px!important', null, 18],
                  display: 'flex',
                  justifyContent: 'space-between',
                },
              }}
            >
              {item.label}
            </span>
            <PlusMenuExpand
              className={variant}
              sx={{
                fill: '#666',
                transform: 'rotate(0)',
                transition: '.3s all',
                minWidth: 10,
                ml: 16,
                '&.footer': {
                  fill: '#fff',
                  display: ['block', 'none'],
                  ml: 12,
                  position: 'relative',
                  top: '2px',
                  right: '10px',
                },
              }}
            />
          </AccordionButton>

          <AccordionPanel
            className={variant}
            sx={{
              outline: 'none',
              ...sxPanel,
              '&.footer': {
                marginBottom: ['60px', '0'],
                display: [null, 'block'],
              },
            }}
          >
            {enableMarkdownContent && typeof item.content === 'string' ? (
              <Box
                dangerouslySetInnerHTML={{
                  __html: md.render(item.content),
                }}
              />
            ) : (
              footerAcc(item)
            )}
          </AccordionPanel>
        </AccordionItem>
      ))}
    </AccordionComponent>
  )
}

export default Accordion
