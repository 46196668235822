import styled, { css } from 'styled-components'

interface Props {
  isTruncated?: boolean
  numberOfLines?: number
}

export const RichTextContainer = styled.div<Props>`
  max-width: 1080px;
  width: auto;
  text-align: start;
  margin: 0 auto;

  ${({ isTruncated, numberOfLines = 3 }) =>
    isTruncated &&
    css`
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: ${numberOfLines};
      overflow: hidden;
    `}

  a {
    color: var(--theme-ui-colors-muted);
    font-weight: 600;
    -webkit-text-decoration: underline;
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      color: var(--theme-ui-colors-primary);
    }
  }
`
export const RichTextWrapper = styled.div`
  button {
    background-color: transparent;
    border: none;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
      font-weight: bold;
    }
  }
`
