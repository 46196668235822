import { isBrowser } from '~/utils/marketing/index'

export enum PinterestTrackedEvents {
  Search = 'search',
  Lead = 'lead',
  AddToCart = 'addtocart',
}

type PintrkInputType = {
  eventType?: string
  event: PinterestTrackedEvents
  params?: object
}

const pintrk = ({ eventType = 'track', event, params = {} }: PintrkInputType): void => {
  if (!isBrowser) {
    return
  }
  if (window?.pintrk) {
    window.pintrk(eventType, event, params)
  }
}

export const trackSignIn = (): void => {
  pintrk({
    event: PinterestTrackedEvents.Lead,
    params: {
      lead_type: 'Sign In',
    },
  })
}

export const trackSignUp = (): void => {
  pintrk({
    event: PinterestTrackedEvents.Lead,
    params: {
      lead_type: 'Sign Up',
    },
  })
}

export default pintrk
