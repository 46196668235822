import { useMemo } from 'react'
import { ProductDetailPageQuery, ProductVariantType } from '~/@types/models'
import { isAntiqueRug, isCreamerArt, isException, isFabric, isPrintArt } from '../../utils'
import { isUpholstery } from '~/utils/product'
import { useCustomerContext } from '~/context/customer-context'

interface UseProductUtilsProps {
  product: ProductDetailPageQuery['product']
  productVariant: ProductVariantType
}

export const useProductUtils = ({ product, productVariant }: UseProductUtilsProps) => {
  const { auth: isAuth } = useCustomerContext()

  return {
    isAuth,
    isRugs: product.title?.includes('Rug') || product.productType?.toLowerCase().includes('rug'),
    isFabric: isFabric(product),
    isException: isException(product),
    isAntiqueRug: isAntiqueRug(product),
    isCustomQuickshipArt: isPrintArt(product.tags) || isCreamerArt(product.tags),
    isUpholstery: isUpholstery(product),
    isAddCartVisible:
      !product.isCustomQuickship && product.productType !== 'Fabric' && !isAntiqueRug(product),
  }
}
