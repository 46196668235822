/** @jsx jsx */
import { jsx, SxStyleProp, Box } from 'theme-ui'
import { FC, SVGProps } from 'react'

type Props = SVGProps<SVGSVGElement> &
  SxStyleProp & {
    color?: string
    width?: number
    height?: number
  }

export const PlusMinus: FC<Props> = ({ color, height = 16, width = 13, ...rest }) => {
  return (
    <Box
      as="svg"
      {...rest}
      // @ts-ignore
      width={width}
      height={height}
      viewBox="0 0 16 16"
    >
      <rect id="upright" x="6.95653125" width="2" height="16.08696875" fill={color} />
      <rect y="6.95653125" width="16" height="2.08696875" fill={color} />
    </Box>
  )
}
