/** @jsx jsx */
import { jsx, Text } from 'theme-ui'
import { FC } from 'react'
import { ProductItemComponentType, ProductVariantType } from '~/@types/models'
import useProductAvailability from '~/hooks/use-product-availability'

type Props = {
  product: ProductItemComponentType
  variant: ProductVariantType
  small?: boolean
}

const ProductVariantPriceNote: FC<Props> = ({ product, variant, small, ...rest }) => {
  const { preOrderMessage } = useProductAvailability({
    product,
    variant,
  })

  if (!preOrderMessage) {
    return null
  }

  return (
    <Text
      variant={small ? 'micro' : 'label'}
      as="span"
      sx={
        small
          ? { color: '#595858', textTransform: 'uppercase', mt: 'sm', fontSize: '9px' }
          : {
              fontSize: ['9px', '15px'],
              '.cart-flyout &': {
                maxWidth: '100px',
                fontSize: ['9px', '9px', '9px'],
              },
            }
      }
      {...rest}
    >
      {preOrderMessage}
    </Text>
  )
}

export default ProductVariantPriceNote
