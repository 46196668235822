/** @jsx jsx */
import { Link } from 'gatsby'
import { useState } from 'react'
import { Box, Text, jsx } from 'theme-ui'
import { CheckoutLineItem } from '~/@types/models'
import urls from '~/common/urls'
import { StyledButton } from '../quantity-input'

export const useRemovedItem = () => {
  const [removedItem, setRemovedItem] = useState<CheckoutLineItem | null>(null)
  const [sflMessage, setSflMessage] = useState(false)

  const onRemove = (item: CheckoutLineItem, sfl?: boolean) => {
    setRemovedItem(item)
    if (sfl) {
      setSflMessage(true)
    } else {
      setSflMessage(false)
    }
  }

  const RemovedItem = () => {
    if (!removedItem) return null
    return (
      <Box
        sx={{
          height: '100px',
          display: 'flex',
          width: '100%',
          alignItems: 'center',
          gap: '20px',
        }}
      >
        <Text
          sx={{
            textTransform: 'uppercase',
          }}
        >
          <Link
            sx={{
              fontWeight: 'bold',
              textDecoration: 'underline',
              cursor: 'pointer',
              color: 'primary',
              textTransform: 'uppercase',
            }}
            to={`${urls.pages.productDetail}/${
              removedItem.variant.product && removedItem.variant.product.handle
            }`}
          >
            {removedItem.title}
          </Link>{' '}
          {sflMessage ? 'has been saved for later' : 'has been removed.'}
        </Text>
        <StyledButton
          onUpdate={() => setRemovedItem(null)}
          sxProps={{
            bg: 'white',
            '&:hover': { cursor: 'pointer', textDecoration: 'underline' },
          }}
        >
          DISMISS
        </StyledButton>
      </Box>
    )
  }

  return {
    RemovedItem,
    setRemovedItem,
    onRemove,
  }
}
