/** @jsx jsx **/
// @ts-ignore
import { jsx, Flex, Text } from 'theme-ui'
import { FC } from 'react'
import { Link } from 'gatsby'
import { trackSearch } from '~/utils/marketing/track'
import algoliasearch from 'search-insights'
import { connectHitInsights } from 'react-instantsearch-dom'
import { ProductHit } from '~/features/instant-search/types'

type Props = {
  data: {
    name: string
    image: string
    handle: string
    id: number
  }
  hit: ProductHit
  queryID: string
  insights: any
}

const SearchResultItemAlgolia: FC<Props> = ({ data, queryID, insights, hit }) => {
  if (queryID) {
    localStorage.setItem('queryID', queryID)
  }

  const handleClick = () => {
    trackSearch({
      name: data.name,
      id: data.id,
    })
  }

  return (
    <Link
      onClick={() => {
        handleClick()
        insights('clickedObjectIDsAfterSearch', {
          eventName: 'Product Clicked',
        })
      }}
      to={data.handle + `?queryID=${queryID}`}
      sx={{
        display: 'flex',
        alignItems: 'center',
        textDecoration: 'none',
      }}
    >
      <Flex
        as="span"
        sx={{
          position: 'relative',
          width: [100, null, 120],
          minWidth: [100, null, 120],
          height: [66, null, 87],
          minHeight: [66, null, 87],
          bg: 'bg',
          overflow: 'hidden',
          alignItems: 'center',
          img: {
            position: 'absolute',
            objectFit: 'cover',
            width: '100%',
          },
        }}
      >
        <img src={data.image} alt={data.name || 'Product Image'} />
      </Flex>
      <Text
        as="span"
        variant="regular"
        sx={{
          ml: 'md1',
          color: 'text',
          lineHeight: '28px',
        }}
      >
        {data.name}
      </Text>
    </Link>
  )
}

const SearchResultItem = connectHitInsights(algoliasearch)(SearchResultItemAlgolia)
export default SearchResultItem
