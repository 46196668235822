import React, { useState, useEffect, useCallback, useRef } from 'react'
import ReactModal from 'react-modal'
import FormReadOnly from '~/components/form-read-only'
import FormField2 from '~/components/form-field2'
import { object, string } from 'yup'
import useFormSubmission from '~/hooks/use-form-submission'
import { Grid, Box, Text, Button as CloseButton } from 'theme-ui'
import { space } from '~/gatsby-plugin-theme-ui'
import { useCustomerContext } from '~/context/customer-context'
import Folder from '~/components/icons/folder'
import FolderAdded from '~/components/icons/FolderAdded'
import { Button } from '~/components/button'
import { getProductByHandle } from '~/graphql/getters'
import { ProductItemComponentType, ProductVariantType } from '~/@types/models'
import { Cross } from '~/components/icons/cross'
import urls from '~/common/urls'
import { Link } from 'gatsby'
import { CheckMark } from '~/components/icons/checkMark'
import useMediaQuery from '~/hooks/use-media-query'

type Props = {
  isOpen: boolean
  isMobile: boolean
  onRequestClose: () => void
  initialValues?: FormValues
  isFromModal?: boolean
  isSuccesful?: boolean
  toastText?: string
  wishlistName?: string
}

type FormValues = {
  firstName: string
  lastName: string
  email: string
}

interface ListEntry {
  [key: string]: any // Assuming the keys are dynamic
}

interface Lists {
  [key: string]: ListEntry
}

const schema = object().shape({
  firstName: string().required(),
  lastName: string().required(),
  email: string().email().required(),
})

const NotificationModal: React.FC<Props> = ({
  isOpen,
  isFromModal,
  isSuccesful,
  onRequestClose,
  toastText,
  wishlistName,
  initialValues = {},
}) => {
  const {
    customer,
    addProductToWishlist,
    removeProductFromWishlist,
    fetchCustomer,
  } = useCustomerContext()
  const [isNext, setIsNext] = useState(false)
  const [loading, setLoading] = useState<boolean>(true)
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const menuRef = useRef()

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen)
  }

  const handleOutsideClick = event => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsMenuOpen(false)
    }
  }

  const isMobile = useMediaQuery({
    query: breakpoints => `(max-width: ${breakpoints[2]})`,
  })

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick)
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [])

  useEffect(() => {
    setIsNext(false)
  }, [onRequestClose])

  const submitCallback = async (values: FormValues) => {
    // await updateCustomer({
    //   firstName: values.firstName,
    //   lastName: values.lastName,
    //   email: values.email,
    // })
  }

  const handleNext = () => {
    setIsNext(true)
  }
  // console.log(isMobile);

  const { onSubmit, isLoading, serverError, isRead } = useFormSubmission<FormValues, any>(
    submitCallback
  )
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Minimal Modal Example"
      style={{
        overlay: {},
        content: {
          fontFamily: 'Helvetica',
          color: '#2B2B2B',
          position: isMobile ? 'sticky' : 'fixed',
          top: '42vh',
          left: '42vw',
          backgroundColor: 'white',
          border: '0.3px solid #2B2B2B',
          borderRadius: '0',
          width: isMobile ? 'auto' : '20vw',
          height: isMobile ? 'auto' : '100px',
          display: 'grid',
          alignItems: 'center',
          justifyItems: 'center',
          fontWeight: '300',
        },
      }}
    >
      {/* <Cross /> */}
      {isFromModal && (
        <>
          {isSuccesful ? (
            <>
              {/* <CheckMark /> */}
              <Text sx={{ fontSize: '23.04px', textTransform: 'uppercase' }}>{toastText}</Text>
              {wishlistName ? (
                <Text sx={{ fontSize: '23.04px', textTransform: 'uppercase' }}>{wishlistName}</Text>
              ) : (
                <></>
              )}
            </>
          ) : (
            <>
              {/* <Cross /> */}
              <Text sx={{ fontSize: '23.04px', textTransform: 'uppercase' }}>{toastText}</Text>
              {wishlistName ? (
                <Text sx={{ fontSize: '23.04px', textTransform: 'uppercase' }}>{wishlistName}</Text>
              ) : (
                <></>
              )}
            </>
          )}
        </>
      )}
    </ReactModal>
  )
}

export default NotificationModal
