/** @jsx jsx */
// @ts-ignore
import { jsx, Box } from 'theme-ui'
import { useKeyboard } from '@react-aria/interactions'
import { FC, RefObject } from 'react'

type Props = {
  label: string
  containerRef: RefObject<HTMLElement>
} & HTMLElement

const KeycodeMap = {
  PAGE_DOWN: 33,
  PAGE_UP: 34,
  HOME: 35,
  END: 36,
}

const Hit: FC<Props> = ({ containerRef, children, ...rest }) => {
  const { keyboardProps } = useKeyboard({
    onKeyDown: e => {
      if (!containerRef || !containerRef?.current?.children) {
        return
      }

      switch (e.keyCode) {
        case KeycodeMap.PAGE_DOWN: {
          // @ts-ignore
          containerRef.current.children[0].focus()
          break
        }
        case KeycodeMap.PAGE_UP: {
          // @ts-ignore
          containerRef.current.children[containerRef.current.children.length - 1].focus()
          return
        }
        case KeycodeMap.HOME: {
          if (e.ctrlKey) {
            // @ts-ignore
            containerRef.current.children[0].focus()
          }
          break
        }
        case KeycodeMap.END: {
          // CTRL + END
          if (e.ctrlKey) {
            // @ts-ignore
            containerRef.current.children[containerRef.current.children.length - 1].focus()
          }
          break
        }
        default:
          return
      }
    },
  })

  return (
    <Box
      as="article"
      // @ts-ignore
      tabIndex={0}
      {...keyboardProps}
      {...rest}
    >
      {children}
    </Box>
  )
}

export default Hit
