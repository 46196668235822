import { ElementType } from 'react'
import { connectStateResults } from 'react-instantsearch-dom'
import { StateResultsProvided } from 'react-instantsearch-core'

export type StateResultsType = Pick<StateResultsProvided, 'searchState'> & {}

type ChildrenArgs = StateResultsProvided & {
  hasResults: boolean
  hits: number
}

export type StateResults = StateResultsProvided & {
  children: (args: ChildrenArgs) => ElementType
}

const StateResults = ({
  searchResults,
  searchState,
  isSearchStalled,
  children,
  ...rest
}: StateResults) => {
  const hasResults = searchResults && searchResults.nbHits !== 0
  const nbHits = searchResults && searchResults.nbHits

  // @ts-ignore
  return children({ hits: nbHits, hasResults, searchState, ...rest })
}

// @ts-ignore
export default connectStateResults(StateResults)
