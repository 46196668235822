/** @jsx jsx **/
import { jsx, SxStyleProp } from 'theme-ui'
import { SVGProps, FC } from 'react'

type Props = SVGProps<SVGSVGElement> & {
  sxProps?: SxStyleProp
  width?: number
  height?: number
}

export const Search: FC<Props> = ({
  color = 'current',
  sxProps,
  width = '21',
  height = '22',
  ...rest
}) => {
  return (
    <svg
      {...rest}
      width={width}
      height={height}
      viewBox="0 0 21 22"
      sx={{
        ...sxProps,
      }}
    >
      <path
        sx={{
          fill: color,
        }}
        className="search-icon"
        d="M8.78906 17.3779C10.583 17.3779 12.2373 16.7979 13.5908 15.8311L18.6826 20.9229C18.9189 21.1592 19.2305 21.2773 19.5635 21.2773C20.2617 21.2773 20.7559 20.7402 20.7559 20.0527C20.7559 19.7305 20.6484 19.4297 20.4121 19.1934L15.3525 14.123C16.416 12.7266 17.0498 10.9971 17.0498 9.11719C17.0498 4.57324 13.333 0.856445 8.78906 0.856445C4.24512 0.856445 0.52832 4.57324 0.52832 9.11719C0.52832 13.6611 4.24512 17.3779 8.78906 17.3779ZM8.78906 15.5947C5.24414 15.5947 2.31152 12.6621 2.31152 9.11719C2.31152 5.57227 5.24414 2.63965 8.78906 2.63965C12.334 2.63965 15.2666 5.57227 15.2666 9.11719C15.2666 12.6621 12.334 15.5947 8.78906 15.5947Z"
      />
    </svg>
  )
}
