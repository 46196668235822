import { useMediaQuery as useMediaQueryResponsive } from 'react-responsive'
import { useThemeUI } from 'theme-ui'
import theme from '~/gatsby-plugin-theme-ui'
const { breakpoints } = theme

type Props = {
  query: (breakpoints: string[]) => string
}

const useMediaQuery = ({ query }: Props) => {
  const theme = useThemeUI()
  const breakpoints = theme.theme.breakpoints as string[]
  const resolveQuery = query(breakpoints)
  return useMediaQueryResponsive({ query: resolveQuery })
}

type TReponsiveMedia =
  | {
      minWidth?: number
      maxWidth: number
    }
  | {
      minWidth: number
      maxWidth?: number
    }
interface IUseMediaQueryV2 {
  query: TReponsiveMedia
}

export const useMediaQueryV2 = ({ query }: IUseMediaQueryV2) => {
  const parseRem = (value: number) => {
    if (breakpoints[value]) {
      return parseInt(breakpoints[value].replace('rem', '')) * 16
    }
    return value
  }
  const media: TReponsiveMedia = {
    minWidth: 0,
    maxWidth: 0,
  }

  if (query.maxWidth) {
    media.maxWidth = parseRem(query.maxWidth)
    delete media.minWidth
  }
  if (query.minWidth) {
    media.minWidth = parseRem(query.minWidth)
    if (media.maxWidth) {
      delete media.maxWidth
    }
  }

  return useMediaQueryResponsive(media)
}

export default useMediaQuery
