import styled, { css } from 'styled-components'

interface NavigationButtonProps {
  $action: 'prev' | 'next'
  $direction: 'vertical' | 'horizontal'
  $spacing: number
}

export const StyledNavigationButton = styled.button<NavigationButtonProps>`
  position: absolute;
  z-index: 10;
  background: rgba(255, 255, 255, 0.5);
  color: black;
  border: none;
  cursor: pointer;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;

  &:disabled {
    color: rgba(16, 16, 16, 0.3);
  }

  svg {
    height: 30px;
    width: 30px;
  }

  ${({ $action, $direction, $spacing }) => {
    if ($direction === 'vertical') {
      return css`
        left: 50%;
        transform: translateX(-50%);
        & > svg {
          transform: rotate(90deg);
        }
        ${$action === 'prev' ? `top: ${$spacing}px` : `bottom: ${$spacing}px`};
      `
    } else {
      return css`
        top: 50%;

        transform: translateY(-50%);
        ${$action === 'prev' ? `left: ${$spacing}px` : `right: ${$spacing}px`};
      `
    }
  }}
`
