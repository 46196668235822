// @ts-ignore

import React, { ReactNode, createContext, useCallback, useContext, useEffect, useMemo } from 'react'

import {
  ProductDetailPageQuery,
  ProductItemComponentType,
  ProductItemType,
  ProductVariantType,
  ShopifyConfigurationProduct,
} from '~/@types/models'

import { useProductVariant } from './hooks/useProductVariant'
import { useProductSliders } from './hooks/useProductSliders'
import { useProductUtils } from './hooks/useProductUtils'
import { useProductPrice } from './hooks/useProductPrice'
import { useVariantControl } from './hooks/useVariantControl'
import useProductAvailability from '~/hooks/use-product-availability'
import { useAvaliableControl } from './hooks/useAvaliableControl'
import { useProductSwatches } from './hooks/useProductSwatches'
import { useProductCategories } from './hooks/useProductCategories'
import { useFlyOutControl } from './hooks/useFlyOutControl'
import useProductVariantInfo from '~/hooks/use-product-variant-info'
import { useCustomerContext } from '~/context/customer-context'
import { formatMoney } from '~/utils/format'
import { title } from 'process'
import { IBundle, ICurrentBundle, TOptions, useBundle } from './hooks/useBundle'

interface IProductDetailContext {
  product: ProductDetailPageQuery['product']
  productVariant: ProductVariantType
  productSliders: ReturnType<typeof useProductSliders>
  productUtils: ReturnType<typeof useProductUtils>
  productPrice: ReturnType<typeof useProductPrice>
  productAvaliable: ReturnType<typeof useProductAvailability>
  productSwatches: ReturnType<typeof useProductSwatches>
  productCategories: ReturnType<typeof useProductCategories>
  variantControl: ReturnType<typeof useVariantControl>
  avaliableControl: ReturnType<typeof useAvaliableControl>
  flyOutControl: ReturnType<typeof useFlyOutControl>
  tearsheetLink: string
  bundles?: IBundle | null
  currentBundle?: ICurrentBundle | null
  handleBundleClick: (label: string, type: 'products' | 'sectionals') => void
  handleFrameClick: (label: boolean) => void
  productsOptions?: TOptions
  sectionalsOptions?: TOptions
  sectionalType?: string | null
  sectionalCushion?: any
}

export const ProductDetailContext = createContext<IProductDetailContext>(
  {} as IProductDetailContext
)

interface ProductDetailProviderProps {
  product: ProductDetailPageQuery['product']
  children: ReactNode
}

export const ProductDetailProvider = ({ product, children }: ProductDetailProviderProps) => {
  const productVariant = useProductVariant({ product })
  const {
    bundles,
    currentBundle,
    handleBundleClick,
    productsOptions,
    sectionalsOptions,
    sectionalType,
    sectionalCushion,
    handleFrameClick,
  } = useBundle({ product })

  const info = {
    product,
    productVariant,
  }

  const variantControl = useVariantControl(info)
  const avaliableControl = useAvaliableControl(info)
  const flyOutControl = useFlyOutControl(info)

  const productUtils = useProductUtils(info)
  const productSliders = useProductSliders(info)
  const productPrice = useProductPrice(info)
  const productSwatches = useProductSwatches(info)
  const productCategories = useProductCategories(info)

  const productAvaliable = useProductAvailability({
    product,
    variant: productVariant,
    bundle: currentBundle,
  })

  const tearsheetHerokuLink =
    '/tearsheet/' +
    product.handle +
    (variantControl.selectedColor ? '?colorOption=' + variantControl.selectedColor : '')

  const tearsheetLink = productUtils.isAntiqueRug
    ? product.metafields?.antiquesPdfLink
    : tearsheetHerokuLink

  return (
    <ProductDetailContext.Provider
      value={{
        ...info,
        productSliders,
        productUtils,
        productPrice,
        productSwatches,
        productCategories,
        variantControl,
        avaliableControl,
        flyOutControl,
        tearsheetLink,
        bundles,
        currentBundle,
        handleBundleClick,
        handleFrameClick,
        productsOptions,
        sectionalsOptions,
        productAvaliable,
        sectionalType,
        sectionalCushion,
      }}
    >
      {children}
    </ProductDetailContext.Provider>
  )
}

export const useProductDetail = () => {
  const context = useContext(ProductDetailContext)
  if (context === undefined) {
    throw new Error('useProductDetail must be used within a ProductDetailProvider')
  }
  return context
}
