/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/** @jsx jsx */
import { FC, Fragment, useMemo } from 'react'
import { jsx, Flex, Text, Link } from 'theme-ui'
import { graphql, useStaticQuery } from 'gatsby'

import { space } from '~/gatsby-plugin-theme-ui'

import useMediaQuery from '~/hooks/use-media-query'
import { useCustomerContext } from '~/context/customer-context'

interface TextData {
  type: string
  children: TextData[]
  value: string
  bold?: boolean
  italic?: boolean
  listType?: string
  url?: string
  title?: string
  target?: string
  level?: number
  text?: string
}

interface TextComponentProps {
  textData: TextData[]
  textDataMobile?: TextData[]
  theme: string
  fontSizeQuery: number[] | number
  allCaps: boolean
}

type QueryResult = {
  data: {
    text: TextData[]
    textMobile: TextData[]
    tradeText: TextData[]
    tradeTextMobile: TextData[]
    // link: string
    // enabled: boolean
    // allCaps: boolean
    // theme: `light` | `dark`
    // fontSize: number
    // fontSizeMobile: number
  }
}

const bannerQuery = graphql`
  query {
    data: topBannerCms {
      text
      textMobile
      tradeText
      tradeTextMobile
    }
  }
`

const themeColors = {
  light: {
    bg: `dark`,
    color: `text`,
  },
  dark: {
    bg: `bgDarkBlue`,
    color: `main`,
  },
}

const fontSize = '16px'
const fontSizeMobile = '12px'
const enabled = true
const allCaps = true
const theme = 'dark'

const TextComponent = ({
  textData,
  textDataMobile,
  theme,
  fontSizeQuery,
  allCaps,
}: TextComponentProps) => {
  const isMobile = useMediaQuery({
    query: breakpoints => `(max-width: ${breakpoints[2]})`,
  })

  const textDataToUse = isMobile ? textDataMobile : textData
  if (!textDataToUse) return <Fragment></Fragment>

  return (
    <Fragment>
      {textDataToUse.map((item, index) => {
        if (item.type === 'paragraph') {
          return (
            <Text
              key={index}
              as="span"
              sx={{
                color: themeColors[theme].color,
                fontSize: fontSizeQuery,
                textTransform: allCaps ? `uppercase` : `none`,
                a: {
                  color: themeColors[theme].color,
                  textDecoration: `underline`,
                  '&:hover': {
                    fontWeight: `bold`,
                  },
                },
              }}
            >
              {item.children.map((child, childIndex) => {
                if (child.type === 'text') {
                  return <span key={childIndex}>{child.text}</span>
                } else if (child.type === 'link') {
                  return (
                    <Link key={childIndex} href={child.url}>
                      {child.children.map((linkChild, linkChildIndex) => (
                        <span key={linkChildIndex}>{linkChild.text}</span>
                      ))}
                    </Link>
                  )
                }
              })}
            </Text>
          )
        }
      })}
    </Fragment>
  )
}

type Props = {}

const TopBanner: FC<Props> = () => {
  const { isTrade } = useCustomerContext()
  const { data } = useStaticQuery<QueryResult>(bannerQuery)

  const { mobileText, desktopText, fontSizeQuery } = useMemo(() => {
    const { text, textMobile, tradeText, tradeTextMobile } = data || {
      text: [],
      textMobile: [],
      tradeText: [],
      tradeTextMobile: [],
    }
    if (isTrade) {
      return {
        mobileText: tradeTextMobile || tradeText,
        desktopText: tradeText,
        fontSizeQuery: fontSizeMobile ? [fontSizeMobile, fontSize] : fontSize,
      }
    }

    return {
      mobileText: textMobile || text,
      desktopText: text,
      fontSizeQuery: fontSizeMobile ? [fontSizeMobile, fontSize] : fontSize,
    }
  }, [data])

  const { text, textMobile, tradeText, tradeTextMobile } = data || {
    text: [],
    textMobile: [],
    tradeText: [],
    tradeTextMobile: [],
  }

  if (!enabled || (!mobileText?.length && !desktopText?.length)) {
    return null
  }

  // const hasLink = String(link).length > 0
  const hasLink = true
  const isHtml = String(text).includes('<')

  if (!data) return null

  return (
    <Flex
      id="top-banner"
      sx={{
        position: 'relative',
        zIndex: 'header',
        bottom: '100%',
        width: `100%`,
        p: [space.sm * 1.5],
        alignItems: `center`,
        justifyContent: `center`,
        textAlign: `center`,
        bg: themeColors[theme].bg,
        display: 'block',
      }}
    >
      <TextComponent
        textData={desktopText}
        textDataMobile={mobileText}
        theme={theme}
        fontSizeQuery={fontSizeQuery}
        allCaps={allCaps}
      />
    </Flex>
  )
}

export default TopBanner
