import { useLocation } from '@reach/router'

export type StateType = {
  comesFromRunners?: boolean
  comesFromCircular?: boolean
  comesFromSaleCollections?: boolean
  inStockColorOption?: string
  state?: StateType
}

const useLinkState = (): StateType => {
  const { state } = useLocation() as { state?: StateType }

  const comesFromRunners = Boolean(state && state.comesFromRunners)
  const comesFromCircular = Boolean(state && state.comesFromCircular)
  const comesFromSaleCollections = Boolean(state && state.comesFromSaleCollections)
  const inStockColorOption =
    typeof state?.inStockColorOption === 'string' ? state?.inStockColorOption : undefined

  return {
    state,
    comesFromRunners,
    comesFromCircular,
    comesFromSaleCollections,
    inStockColorOption,
  }
}

export default useLinkState
