import { useEffect, useState } from 'react'

const useIsTouchDevice = () => {
  const [isTouchDevice, setIsTouchDevice] = useState(false)

  useEffect(() => {
    // @ts-ignore
    setIsTouchDevice('ontouchstart' in window || navigator.msMaxTouchPoints > 0)
  }, [])

  return isTouchDevice
}

export default useIsTouchDevice
