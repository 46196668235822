/** @jsx jsx **/
// @ts-ignore
import { Input, Flex, jsx, Box, IconButton, Container, Label, SxStyleProp, Text } from 'theme-ui'
import { ChangeEvent, createRef, useEffect, FC } from 'react'
import VisuallyHidden from '@reach/visually-hidden'
import { Search } from '~/components/icons/search'
import { Close } from '~/components/icons/close'
import useMediaQuery from '~/hooks/use-media-query'

type Props = {
  searchInputValue: string
  onChange(e: ChangeEvent<HTMLInputElement>): void
  setSearchInputValue?: (value: string) => any
  showCloseIcon?: boolean
  closeSearch?: (value: boolean) => any
  initialValue?: string
  focusOnMount?: boolean
  css?: SxStyleProp
  onEnterPress?(): void
}

const SearchInput: FC<Props> = ({
  onChange,
  searchInputValue,
  setSearchInputValue,
  closeSearch,
  children,
  showCloseIcon = true,
  initialValue = '',
  focusOnMount = true,
  css,
  onEnterPress,
  ...rest
}) => {
  const ref = createRef<HTMLInputElement>()
  const isMobile = useMediaQuery({
    query: breakpoints => `(max-width: ${breakpoints[4]})`,
  })

  useEffect(() => {
    if (focusOnMount && ref && ref.current && !isMobile) {
      ref.current.focus()
    }
  }, [ref, focusOnMount])

  return (
    <Box
      role="search"
      className="search-input-container"
      sx={{
        borderStyle: 'solid',
        borderWidth: '1px 0',
        borderColor: 'border',
        ...css,
      }}
    >
      <Container
        className="search-input"
        sx={{
          position: 'relative',
          px: ['gridGap3', null, 'gridGap2'],
        }}
      >
        <Flex>
          <Label
            sx={{
              m: 0,
              height: isMobile ? 62 : 80,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'relative',
            }}
          >
            <VisuallyHidden>Search</VisuallyHidden>

            <Search
              role="img"
              sxProps={{
                display: ['initial', null, 'initial'],
                mr: '10px',
                mt: '2px',
              }}
              color="muted"
              height={16}
              width={12}
            />
            <Input
              placeholder="SEARCH..."
              variant="search"
              type="text"
              ref={ref}
              onChange={onChange}
              onKeyDown={(event: KeyboardEvent) => {
                if (event.key === 'Enter' && onEnterPress) {
                  onEnterPress()
                }
              }}
              value={searchInputValue}
              sx={{
                variant: 'text.regular',
                fontSize: ['16px', '18px'],
                color: 'text',
                border: 0,
                height: '100%',
                bg: 'transparent',
                p: 0,
                '::placeholder': {
                  color: 'muted',
                  fontSize: '11px',
                  fontWeight: '600',
                },
                ml: isMobile ? 0 : 'md',
                width: ['100%', null, 200],
              }}
              {...rest}
            />
            {searchInputValue?.length < 3 && searchInputValue ? (
              <Text
                sx={{
                  position: 'absolute',
                  transform: 'translateY(-50%)',
                  fontSize: '11px',
                  fontWeight: '600',
                  color: 'muted',
                  bottom: '-8px',
                }}
              >
                *Please enter at least 3 characters
              </Text>
            ) : null}
          </Label>
          {children}
          {showCloseIcon && (
            <IconButton
              onClick={() => {
                if (ref && ref?.current?.value?.length === 0) {
                  if (closeSearch) {
                    closeSearch(false)
                  }
                } else if (ref?.current?.value?.length > 0) {
                  onChange({ currentTarget: { value: '' } } as ChangeEvent<HTMLInputElement>)
                }
              }}
              sx={{
                position: 'absolute',
                right: 0,
                width: isMobile ? 65 : 80,
                p: 0,
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                textAlign: 'right',
                cursor: 'pointer',
                justifyContent: 'center',
                pointerEvents: isMobile && !searchInputValue ? 'none' : 'initial',
              }}
            >
              <VisuallyHidden>Clear search value</VisuallyHidden>
              {isMobile && !searchInputValue ? null : <Close />}
            </IconButton>
          )}
        </Flex>
      </Container>
    </Box>
  )
}

export default SearchInput
