/** @jsx jsx **/
import { Box, Flex, jsx } from 'theme-ui'
import { space } from '~/gatsby-plugin-theme-ui'

const SlickDots = (dots: readonly any[]) => (
  <Flex
    as="ul"
    sx={{
      listStyleType: 'none',
      justifyContent: 'center',
      mt: ['15px', 45],
      pl: 0,
      px: [space.gridGap2, null, 0],
      flexWrap: ['wrap', null, 'nowrap'],
    }}
  >
    {dots.map((item, index) => {
      const isActive = item.props.className === 'slick-active'
      return (
        <Box
          as="li"
          key={index}
          sx={{
            '> button': {
              outline: 'none',
              opacity: isActive ? 1 : 0.5,
              borderWidth: isActive ? '1.5px' : '0',
              borderStyle: 'solid',
              borderColor: 'textMid',
              borderRadius: '50%',
              position: 'relative',
              bg: 'transparent',
              color: 'transparent',
              width: '40px',
              height: '40px',
              cursor: 'pointer',
              '::before': {
                content: '""',
                display: 'block',
                bg: 'textMid',
                width: '7px',
                height: '7px',
                transform: 'translate(-50%, -50%) matrix(0.71,-0.71,0.71,0.71,0,0)',
                position: 'absolute',
                top: '50%',
                left: '50%',
              },
            },
          }}
        >
          {item.props.children}
        </Box>
      )
    })}
  </Flex>
)

export default SlickDots
