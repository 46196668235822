import {
  Maybe,
  ProductItemComponentType,
  ProductItemType,
  ProductVariantType,
} from '~/@types/models'
import { useMemo } from 'react'
import { equals } from 'ramda'

type Props = {
  product: ProductItemType | ProductItemComponentType
}

type OptionValues = {}

type OutputTypes = {
  findVariant: (data: OptionValues) => Maybe<ProductVariantType>
}

const useFindVariant = ({ product }: Props): OutputTypes => {
  const handlers = useMemo(() => {
    const findVariant = (data: OptionValues) => {
      if (!product.variants) {
        return null
      }

      const fullCombination = product.variants.find(variant => {
        const normalizedSelectedOptions = variant.selectedOptions.reduce(
          (acc, curr) => ({
            ...acc,
            [curr.name]: curr.value,
          }),
          {}
        )

        return equals(normalizedSelectedOptions, data)
      }) as Maybe<ProductVariantType>

      if (!fullCombination) {
        const firstValue = data[Object.keys(data)[0] as keyof OptionValues] as string
        const firstCombinationFound = product.variants.find(
          option => option.selectedOptions[0].value === firstValue
        )
        return firstCombinationFound
      }
      return fullCombination
    }

    return {
      findVariant,
    }
  }, [product])

  return {
    ...handlers,
  }
}

export default useFindVariant
