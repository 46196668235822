/** @jsx jsx */
import { jsx, Box, Grid } from 'theme-ui'
import { FC, useEffect, useState } from 'react'
import { useDashboard } from '../context/dashboardContext'

import { space } from '~/gatsby-plugin-theme-ui'
import { DashboardProductCard } from './productCard'
import { useMediaQuery } from 'react-responsive'
import { NavigationButton } from '~/componentsV2/navigationButton'
import { RiArrowLeftSLine, RiArrowRightSLine } from '@remixicon/react'
import Slider, { Settings } from 'react-slick'
import SlickDots from '~/slick-dots'
import { useTheme } from 'styled-components'
import { FONTS, H3 } from '~/styled/components/typography'

interface Props {
  cols?: number
  showNext?: boolean
}
export const DashboardSflCarousel = ({ cols = 4, showNext }: Props) => {
  const { loading, sflLists } = useDashboard()

  const theme = useTheme()

  const isDesktop = useMediaQuery({ query: theme.mediaQueries.desktop })

  const prevArrow = (
    <NavigationButton action="prev" spacing={-30}>
      <RiArrowLeftSLine />
    </NavigationButton>
  )
  const nextArrow = (
    <NavigationButton action="next" spacing={-30}>
      <RiArrowRightSLine />
    </NavigationButton>
  )

  const [settings] = useState<Settings>({
    slidesToShow: cols,
    slidesToScroll: 1,
    prevArrow,
    nextArrow,
    arrows: true,
    dots: false,
    centerMode: false,
    appendDots: SlickDots,
    // adaptiveHeight: true,
    infinite: false,
    swipe: true,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 1250,
        settings: {
          slidesToShow: cols - 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: theme.maxBreakPoints.tablet,
        settings: {
          slidesToShow: cols - 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: theme.maxBreakPoints.mobile,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
          // adaptiveHeight: true,
          // centerMode: true,
        },
      },
    ],
  })

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          width: '100%',
        }}
      >
        <p>Loading...</p>
      </Box>
    )
  }

  if (!sflLists?.items?.length) {
    return null
  }

  return (
    <div
      sx={{
        '.slick-track': {
          display: 'flex !important',
          gap: '16px',
        },
        '.slick-list': {
          overflow: showNext ? 'visible' : 'hidden',
        },
        '.slick-slide:not(.slick-active)': {
          // opacity: '0.5 !important',
          position: 'relative',
          '&:before': {
            content: '""',
            display: 'block',
            background:
              'linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%)',
            position: 'absolute',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            zIndex: 1,
          },
        },
        '.slick-slide': {
          height: 'auto !important',
        },
        '.slick-slide > div, .product-card': {
          height: '100% !important',
        },
        '.product-card__text': {
          gridTemplateRows: '1fr min-content',
        },
        '.product-card__text > :first-child': {
          gridRow: '1 / -1',
        },
      }}
    >
      <H3 style={{ textTransform: 'uppercase', fontFamily: FONTS.CormorantInfantLight }}>
        Saved for later ({sflLists?.items?.length} item{sflLists?.items?.length > 1 ? 's' : ''})
      </H3>
      <Box sx={{ mt: '32px', ml: '-16px' }}>
        <Slider {...settings}>
          {sflLists?.items?.map((list, index) => (
            <Box data-comp="product-card" key={index} sx={{ height: '100%' }}>
              <DashboardProductCard
                key={index}
                listName={sflLists?.list.lname}
                list={list}
                sfl={true}
              />
            </Box>
          ))}
        </Slider>
      </Box>
    </div>
  )
}
