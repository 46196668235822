import { useState, useCallback } from 'react'
import { useOverlayContext } from '~/context/overlay-context'

type SearchContextType = {
  showSearch: boolean
  toggleSearch(value: boolean): void
}

const useSearchHandlers = (): SearchContextType => {
  const [show, setShow] = useState(false)
  const { toggleOverlay } = useOverlayContext()

  const toggleSearch = useCallback(
    (value: boolean) => {
      if (value) {
        setShow(true)
        toggleOverlay(true)
      } else {
        setShow(false)
        toggleOverlay(false)
      }
    },
    [setShow, toggleOverlay]
  )

  return {
    showSearch: show,
    toggleSearch,
  }
}

export { useSearchHandlers }
