import styled from 'styled-components'
import { SmallText } from '~/styled/components/typography'

export const StyledTag = styled(SmallText)`
  font-family: Inter;
  padding: 5px;
  display: inline-block;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  border-color: transparent;
  font-weight: 400;
  color: #2b2b2b;
  transition: all 0.2s ease-in-out;

  &.transparent {
    background-color: transparent !important;
  }

  &:hover {
    cursor: pointer;
    border-color: #000;
    font-weight: 600;
  }

  &.active {
    border-color: #000;
    font-weight: 600;
    margin-left: 3px;
  }

  &.disabled {
    opacity: 0.5;
  }
`
