import { ProductItemComponentType } from '~/@types/models'

enum ProductTypes {
  Bed = 'Bed',
  Table = 'Table',
  Chair = 'Chair',
  Sofa = 'Sofa',
}

export const isCustomProduct = (product: ProductItemComponentType) => {
  const productType = product.productType
  const types = [
    ProductTypes.Bed,
    ProductTypes.Table,
    ProductTypes.Chair,
    ProductTypes.Sofa,
  ] as string[]

  return types.includes(productType)
}
