import React, { FC, Fragment, useEffect, useMemo, useRef, useState } from 'react'
import { useCustomerContext } from '~/context/customer-context'
import { axiosRequests } from '~/utils/axios'

import MenuDots from '~/components/icons/menuDots'
import { Button } from '~/components/button'
import ProjectsModal from '~/templates/projects-modal'
import { ProductItemComponentQuery, ProductVariantType } from '~/@types/models'
import { Box, Grid, Text, Button as UIButton } from 'theme-ui'
import { RiDislikeFill, RiHeartFill, RiHeartLine } from '@remixicon/react'
import { graphql, navigate, useStaticQuery } from 'gatsby'
import { useSwymlists } from '~/components/account-layout/hooks/useSwymlists'
import { useDashboard } from '~/components/account-layout/context/dashboardContext'
import urls from '~/common/urls'
import { useCartContext } from '~/context/cart-context'

interface Props {
  product: ProductItemComponentQuery
  productVariant: ProductVariantType
  sfl?: boolean
  hiddenModal?: boolean
  full?: boolean
  small?: boolean
  callback?: () => void
  pdp?: boolean
}

const StyledButton: FC<{
  onUpdate: () => void
}> = ({ children, onUpdate, full }) => {
  // const [disableButton, setDisableButton] = useState(undefined)

  const inputElement = useRef()

  return (
    <UIButton
      ref={inputElement}
      sx={{
        all: 'unset',
        border: 'none',
        borderColor: 'strokes',
        color: 'text',
        borderRadius: 0,
        cursor: 'pointer',
        height: 'auto',
        width: '100%',
        padding: '0!important',
        fontFamily: 'Roboto, Helvetica, system-ui, -apple-system, BlinkMacSystemFont',
      }}
      onClick={e => {
        if (e.target.disabled || inputElement.current.disabled) {
          return
        }
        inputElement.current.disabled = true
        try {
          onUpdate(e)
        } catch (error) {
          console.error(error)
        }
        inputElement.current.disabled = undefined
      }}
    >
      {children}
    </UIButton>
  )
}

export const AddFavoriteButton = ({
  product,
  productVariant,
  sfl = false,
  hiddenModal = false,
  small = false,
  pdp = false,
  callback,
}: Props) => {
  const { auth } = useCustomerContext()
  const { setOverlayOpen } = useCartContext()
  const [currentProduct, setCurrentProduct] = useState(product)
  const {
    defaultList,
    addToWishlist,
    removeFromWishlist,
    addToSfl,
    removeFromSfl,
    sflLists,
    loading,
  } = useDashboard()

  const [isInWishlist, setIsInWishlist] = useState(false)
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const openModal = () => {
    setModalIsOpen(true)
  }
  const closeModal = () => {
    setModalIsOpen(false)
  }

  const data = useStaticQuery(graphql`
    query {
      allShopifyProduct {
        edges {
          node {
            id
            handle
            shopifyId
            title
          }
        }
      }
    }
  `)
  const { shopifyId, productVariantId } = useMemo(() => {
    const shopifyId = Number(product.shopifyId) ? product.shopifyId : currentProduct.shopifyId
    const productVariantId = productVariant.shopifyId2 || productVariant.shopifyId

    return { shopifyId, productVariantId }
  }, [product, currentProduct, productVariant])

  useEffect(() => {
    if (auth && data) {
      const matchedProduct = data.allShopifyProduct.edges.find(
        edge => edge.node.handle === product.handle
      )?.node

      setCurrentProduct({ ...currentProduct, ...matchedProduct })
    }
  }, [auth, data, product.handle])

  useEffect(() => {
    if (defaultList?.lname && !!auth) {
      const defaultContents = defaultList.listcontents

      const isInList = defaultContents.find(
        prod => prod.empi === Number(shopifyId) && prod.epi === Number(productVariantId)
      )
      if (!!isInList) {
        setIsInWishlist(true)
      } else {
        setIsInWishlist(false)
      }
    }
  }, [defaultList])

  const SaveForLaterBtn = () => {
    const inTheList = sflLists?.items?.find(
      item => item.empi === Number(shopifyId) && item.epi === Number(productVariantId)
    )

    if (inTheList) {
      return null
    }

    return (
      <Box>
        <StyledButton
          onUpdate={() => {
            if (auth) {
              addToSfl({ productVariantId, shopifyId, product })
              if (callback) {
                callback()

                return
              }
            }

            setOverlayOpen(false)
            navigate(urls.account.signIn)
          }}
        >
          <Text
            sx={{
              color: '#2B2828',
              fontSize: small ? '14px' : '16px',
              cursor: 'pointer',
              textDecoration: 'underline',
              textUnderlineOffset: '3px',
            }}
          >
            SAVE FOR LATER
          </Text>
        </StyledButton>
      </Box>
    )
  }

  return (
    <Fragment>
      <Grid
        columns={['8fr 1fr']}
        gap="0.5em"
        sx={{
          flexDirection: 'column',
          boxSizing: 'border-box',
          marginTop: !sfl ? '1em' : 0,
          marginBottom: !sfl ? '1em' : 0,
        }}
      >
        {!sfl ? (
          <Box>
            {isInWishlist ? (
              <Button
                label="REMOVE FROM FAVORITES"
                sxProps={{
                  backgroundColor: '#051826',
                  color: '#ffffff',
                  width: '100%',
                }}
                variant="noStyle"
                type="submit"
                hasArrow={false}
                onClick={(e: any) => {
                  removeFromWishlist({ productVariantId, shopifyId, product })
                }}
              />
            ) : (
              <Button
                label="ADD TO FAVORITES"
                sxProps={{
                  backgroundColor: '#ffffff',
                  color: '#051826',
                  width: '100%',
                  '&:hover': {
                    color: '#ffffff',
                    backgroundColor: 'lightGrey',
                    transition: 'all .3s ease',
                  },
                }}
                variant="noStyle"
                type="submit"
                hasArrow={false}
                onClick={(e: any) => {
                  if (auth) {
                    addToWishlist({ productVariantId, shopifyId, product })
                    if (callback) {
                      callback()

                      return
                    }
                    return
                  }

                  setOverlayOpen(false)
                  navigate(urls.account.signIn)

                  return
                }}
              />
            )}
          </Box>
        ) : (
          <SaveForLaterBtn />
        )}
        {!hiddenModal ? (
          <Box
            sx={{
              border: '1px solid #051826',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: 'lightGrey',
                border: 'none',
                transition: 'all .3s ease',
              },
            }}
            onClick={(e: any) => {
              if (auth) {
                openModal()

                return
              }

              setOverlayOpen(false)
              navigate(urls.account.signIn)
            }}
          >
            <MenuDots />
          </Box>
        ) : null}
      </Grid>
      {!!auth && (
        <Box sx={{ zIndex: '2' }}>
          <ProjectsModal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            product={product}
            variant={productVariant}
          />
        </Box>
      )}
    </Fragment>
  )
}
