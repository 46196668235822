/** @jsx jsx */
import { jsx, Message } from 'theme-ui'
import { FC } from 'react'
import ExclamationMark from '~/components/icons/exclamation-mark'

type Props = {
  error: string
}

const ErrorMessage: FC<Props> = ({ error, ...rest }) => (
  <Message
    variant="error"
    sx={{
      display: 'flex',
      alignItems: 'center',
      mt: '8px',
    }}
    {...rest}
  >
    <span>
      <ExclamationMark
        sx={{
          mr: '8px',
        }}
      />
    </span>
    {error}
  </Message>
)

export default ErrorMessage
