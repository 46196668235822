import { Maybe, ProductDetailPageQuery, ProductVariantType } from '~/@types/models'
import { isNotSwatch } from '../../utils'
import { rugSizes } from '~/utils/product'
import useLinkState from '~/hooks/use-link-state'
import { useQueryParams } from '~/hooks/use-query-params'
import { useMemo } from 'react'

interface UseProductVariantProps {
  product: ProductDetailPageQuery['product']
}

export const useProductVariant = ({ product }: UseProductVariantProps): ProductVariantType => {
  const { queryParams } = useQueryParams()
  const { variant } = queryParams as { variant: Maybe<string> }

  const { comesFromRunners, comesFromCircular } = useLinkState()

  return useMemo(() => {
    const selectedVariant = product.variants.find(
      productVariant => productVariant.shopifyId2 === variant
    )

    // Business exception - don't ever show the swatch variant;
    let notSwatchVariant = product.variants.find(variant => isNotSwatch(variant.title))

    const firstVariant = product.variants[0]

    // Business exception - for Runners/Circular type, the first variant is different;
    if (!selectedVariant && product.isRunnerOrCircularRug && comesFromRunners) {
      const preferredSize = () => {
        const productVariantsSizes = product.variants
          .map(item => item.selectedOptions.filter(item => item.name === 'Size'))
          .flat()
          .map(item => item.value)
        if (productVariantsSizes.includes(rugSizes.runnersSize2x10)) {
          return rugSizes.runnersSize2x10
        }
        if (productVariantsSizes.includes(rugSizes.runnersSize2_2x10)) {
          return rugSizes.runnersSize2_2x10
        }
        return rugSizes.runnersSize3x12
      }
      notSwatchVariant = product.variants.find(variant => {
        return variant.titleSize === preferredSize()
      })
    }
    if (!selectedVariant && product.isRunnerOrCircularRug && comesFromCircular) {
      notSwatchVariant = product.variants.find(variant => {
        return variant.titleSize === rugSizes.circularSize6
      })
    }

    return selectedVariant || notSwatchVariant || firstVariant
  }, [product.variants, variant, comesFromRunners, comesFromCircular])
}
