/** @jsx jsx */

import { NavLinkType } from '~/@types/models'
import React, { FC, useState } from 'react'
import { jsx, Box, IconButton, Flex, ThemeUIStyleObject, Grid } from 'theme-ui'
import { PlusMinus } from '~/components/icons/plusMinus'
import { IMenu, IMenuItem } from '~/context/menu-context'
import LinkComponent from '~/components/menu/base/LinkComponent'
import MenuImageGroup from '~/components/menu/base/MenuImageGroup'
import { ImageGrid } from './styles'

interface PlusMinusIconProps {
  subMenuOpen: boolean
  onClick: () => void
}

const PlusMinusIcon: React.FC<PlusMinusIconProps> = ({ subMenuOpen, onClick }) => {
  return (
    <IconButton
      sx={{
        display: ['block', null, null, 'none'],
        position: 'absolute',
        right: 0,
        top: 0,
        height: 60,
        width: 60,
      }}
      onClick={onClick}
    >
      <PlusMinus
        sx={{
          minWidth: 5,
          '#upright': {
            transition: 'all .3s',
            transformOrigin: 'center',
            transform: `rotate(${subMenuOpen ? 90 : 0}deg)`,
          },
        }}
        color="#666666"
        width={8}
        height={8}
      />
    </IconButton>
  )
}

interface MobileMainMenuProps {
  item: IMenu
}

const MobileMainMenu: FC<MobileMainMenuProps> = ({ item }) => {
  const [subMenuOpen, setSubMenuOpen] = useState(false)

  const handleSubMenuOnClick = () => {
    setSubMenuOpen(!subMenuOpen)
  }

  const { menus, images } = item

  const hasSubMenu = (menus && menus.length > 0) || (images && images.length > 0)

  return (
    <Box as="li" className={`${hasSubMenu ? 'hasSubmenu' : ''}`}>
      <LinkComponent
        link={item.link}
        sx={{
          position: 'relative',
          fontWeight: 600,
          textDecoration: subMenuOpen ? 'underline !important' : 'none',
        }}
        onClick={handleSubMenuOnClick}
        className="menu-link"
      >
        {item.name}
      </LinkComponent>

      {hasSubMenu && <PlusMinusIcon subMenuOpen={subMenuOpen} onClick={handleSubMenuOnClick} />}

      {hasSubMenu && (
        <Flex
          as="ul"
          variant="menuUl"
          sx={{
            display: subMenuOpen ? 'flex !important' : 'none !important',
          }}
          className="submenu"
        >
          {!!menus.length &&
            menus.map(item => (
              <MobileMenuItem
                item={{
                  ...item,
                  name: item.groupName,
                }}
                key={'menu-group-' + item.id}
              />
            ))}
          {!!images.length && (
            <ImageGrid>
              {images?.map(item => (
                <LinkComponent
                  link={item.link}
                  sx={{
                    position: 'relative',
                  }}
                  onClick={handleSubMenuOnClick}
                  className="menu-link"
                >
                  <MenuImageGroup key={'image' + item.id} {...item} />
                </LinkComponent>
              ))}
            </ImageGrid>
          )}
        </Flex>
      )}
    </Box>
  )
}

interface MobileMenuItemProps {
  item: IMenuItem
}

const MobileMenuItem: FC<MobileMenuItemProps> = ({ item }) => {
  const [subMenuOpen, setSubMenuOpen] = useState(false)

  const hasSubMenu = item.menus && item.menus.length > 0

  const handleSubMenuOnClick = () => {
    if (hasSubMenu) {
      setSubMenuOpen(!subMenuOpen)
    }
  }

  return (
    <Box as="li" className={`${hasSubMenu ? 'hasSubmenu' : ''}`}>
      <LinkComponent
        link={item.link}
        sx={{
          position: 'relative',
          fontWeight: 400,
          textDecoration: subMenuOpen ? 'underline !important' : 'none',
        }}
        onClick={handleSubMenuOnClick}
        className="menu-link"
      >
        {item.name}
      </LinkComponent>
      {hasSubMenu && <PlusMinusIcon subMenuOpen={subMenuOpen} onClick={handleSubMenuOnClick} />}

      {hasSubMenu && (
        <Flex
          as="ul"
          variant="menuUl"
          sx={{
            ...(!subMenuOpen &&
              hasSubMenu && {
                display: 'none !important',
              }),
            ...(subMenuOpen &&
              hasSubMenu && {
                display: 'block !important',
              }),
          }}
          className="submenu"
        >
          {item.menus.map(item => (
            <MobileMenuItem item={item} key={'menu-item-' + item.id} />
          ))}
        </Flex>
      )}
    </Box>
  )
}

export default MobileMainMenu
