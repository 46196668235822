import { Maybe, ProductItemComponentType } from '~/@types/models'
import { SxStyleProp } from 'theme-ui'
import { toDashLowerCase } from '~/utils/format'

export const rugSizes = {
  runnersSize2x10: `2'10" x 10'`,
  runnersSize2_2x10: `2'2" x 10'`,
  runnersSize3x12: `3' x 12'`,
  circularSize6: `6' Diameter`,
}

export const isRunnerRug = (product: ProductItemComponentType): boolean => {
  return product.tags && product.tags.includes('size-runner')
}

export const isCircularRug = (product: ProductItemComponentType): boolean => {
  return product.tags && product.tags.includes('size-circular')
}

export const isUpholstery = (product: ProductItemComponentType): boolean => {
  return product.tags && product.tags.includes('upholstery')
}

export const isRunnerOrCircular = (product: ProductItemComponentType): Maybe<string> => {
  const tags = [isRunnerRug(product), isCircularRug(product)]
  return tags.find(tag => Boolean(tag))
}

export const isNoScale = (product: ProductItemComponentType): boolean => {
  return product.tags && product.tags.includes('image-no-scale')
}

export const disableZoom = (product: ProductItemComponentType) => {
  if (product.metafields.zoomOnOff === 'false') {
    return true
  }

  return false
}

export const addScaleClasses = (product: ProductItemComponentType): string => {
  if (!product.tags) {
    return ``
  }

  return product.tags.filter(tag => tag.startsWith(`scale-up`)).join(` `)
}

export const imageClassByType = (product: ProductItemComponentType) =>
  `img-${toDashLowerCase(product.productType || '')}`

export const scaleStyles: SxStyleProp = {
  img: {
    transform: 'scale(1.125)',
  },
  '&.scale-up-30': {
    img: {
      transform: 'scale(1.73)',
    },
  },
}

export const scaleImageStyles: SxStyleProp = {
  position: 'relative',
  '.img-throws, .img-pillows': {
    // transform: 'scale(0.7)',
  },
  '.img-decor, .img-outdoor-furniture': {
    // transform: 'scale(1.4)',
  },
  '.img-table': {
    // transform: 'scale(1.25)',
  },
  '.img-chair': {
    // transform: 'scale(1.3)',
  },
  '.img-lighting, .img-lamp': {
    // transform: 'scale(1.7)',
  },
  '.img-sofa': {
    // transform: 'scale(1.2)',
  },
  '.img-bed': {
    // transform: 'scale(1.3)',
  },
  '.img-mirror': {
    // transform: 'scale(1.6)',
  },
  '.img-nightstand, .img-bedding, .img-mattress, .img-art, .img-books': {
    // transform: 'scale(1.5)',
  },
  '[class*="rugs"]': {
    maxHeight: '100%',
  },
  '[class*="bench"]': {
    // transform: 'scale(1.1)',
  },
  '[class*="ottoman"].img-chair': {
    // transform: 'scale(1.05)',
  },
  '[class*="table"], [class*="sofa"], [class*="daybed"]': {
    '&.img-outdoor-furniture': {
      // transform: 'scale(1.3)',
    },
  },
  '&.scale-up-30': {
    img: {
      // transform: 'scale(1.3)',
    },
  },
}

export const isRugProduct = (product: ProductItemComponentType) => {
  const productType = String(product.productType).toLocaleLowerCase()

  // todo - taken from the old website - should be distinguished by tags
  return productType.includes('rug')
}

export const overheadType = '-OH-'
export const horizontalType = 'HZ'

export const isOverheadRugImage = (product: ProductItemComponentType, imageUrl: string) => {
  return isRugProduct(product) && imageUrl.indexOf(overheadType) > -1
}

export const isHorizontalRugImage = (product: ProductItemComponentType, imageUrl: string) => {
  return isRugProduct(product) && imageUrl.indexOf(horizontalType) > -1
}
