import React, { useState, useEffect, useCallback } from 'react'
import ReactModal from 'react-modal'
import FormReadOnly from '~/components/form-read-only'
import FormField2 from '~/components/form-field2'
import { object, string } from 'yup'
import useFormSubmission from '~/hooks/use-form-submission'
import { Grid, Box, Text, Button as CloseButton } from 'theme-ui'
import { space } from '~/gatsby-plugin-theme-ui'
import { useCustomerContext } from '~/context/customer-context'
import Folder from '~/components/icons/folder'
import FolderAdded from '~/components/icons/FolderAdded'
import { Button } from '~/components/button'
import { getProductByHandle } from '~/graphql/getters'
import { ProductItemComponentType, ProductVariantType } from '~/@types/models'
import { Cross } from '~/components/icons/cross'
import urls from '~/common/urls'
import { Link } from 'gatsby'
import useMediaQuery from '~/hooks/use-media-query'
import { axiosRequests } from '~/utils/axios'
import { useDashboard } from '~/components/account-layout/context/dashboardContext'

type Props = {
  isOpen: boolean
  product: ProductItemComponentType
  variant: ProductVariantType
  onRequestClose: () => void
  initialValues?: FormValues
}

type FormValues = {
  firstName: string
  lastName: string
  email: string
}

interface ListEntry {
  [key: string]: any // Assuming the keys are dynamic
}

interface Lists {
  [key: string]: ListEntry
}

const schema = object().shape({
  firstName: string().required(),
  lastName: string().required(),
  email: string().email().required(),
})

const ProjectsModal: React.FC<Props> = ({ isOpen, product, variant, onRequestClose }) => {
  const isMobile = useMediaQuery({
    query: breakpoints => `(max-width: ${breakpoints[2]})`,
  })
  const { customer, fetchCustomer } = useCustomerContext()

  const {
    sessionid,
    regid,
    listsArray,
    loading,
    addToWishlist,
    removeFromWishlist,
  } = useDashboard()

  const [isNext, setIsNext] = useState(false)
  const AxiosFunctions = axiosRequests()

  // const addToWishlist = async (listId, productVariantId) => {
  //   if (customer) {
  //     await AxiosFunctions.addToWishlist(
  //       productVariantId,
  //       product.shopifyId,
  //       sessionid,
  //       regid,
  //       listId,
  //       product.handle
  //     ).then(function (response) {
  //       fetchCustomer()
  //     })
  //   }
  // }

  // const removeFromWishlist = async (listId, productVariantId) => {
  //   if (customer) {
  //     await AxiosFunctions.removeFromWishlist(
  //       productVariantId,
  //       product.shopifyId,
  //       sessionid,
  //       regid,
  //       listId,
  //       product.handle
  //     ).then(function (response) {
  //       fetchCustomer()
  //     })
  //   }
  // }

  useEffect(() => {
    setIsNext(false)
  }, [onRequestClose])

  const submitCallback = async (values: FormValues) => {
    return values
  }

  const handleNext = () => {
    setIsNext(true)
  }
  // console.log(isMobile);

  const { onSubmit, isLoading, serverError, isRead } = useFormSubmission<FormValues, any>(
    submitCallback
  )
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Minimal Modal Example"
      style={{
        overlay: { zIndex: 10 },
        content: {
          color: 'lightsteelblue',
          position: isMobile ? 'sticky' : 'fixed',
          top: isMobile ? '20%' : '26%',
          left: isMobile ? '10%' : '58%',
          backgroundColor: 'white',
          border: '1px solid #C8C8C8',
          width: isMobile ? 'auto' : '36vw',
          height: isMobile ? 'auto' : '69vh',
        },
      }}
    >
      <Box
        sx={{ display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid #E6E6E6' }}
      >
        <h2
          style={{
            fontFamily: 'Cormorant Garamond, Times New Roman, serif',
            fontWeight: 400,
            fontSize: '30px',
            color: '#2b2b2b',
            marginTop: '10px',
          }}
        >
          ADD TO PROJECT
        </h2>
        <CloseButton sx={{ background: 'none', border: 'none' }} onClick={onRequestClose}>
          {' '}
          <Cross color="#2B2B2B" />
        </CloseButton>
      </Box>

      <Grid
        columns={['8fr 1fr']}
        gap="1.5em"
        sx={{
          flexDirection: 'column',
          boxSizing: 'border-box',
          marginTop: '1em',
          marginBottom: '1em',
        }}
      >
        {!!customer &&
          !loading &&
          listsArray?.map((list, index) => {
            const wishListItems = list.listcontents
            const isInWishlist =
              wishListItems.length > 0 &&
              !!wishListItems.find(element => element.empi === Number(product.shopifyId)) &&
              !!wishListItems.find(element => element.epi === Number(variant.shopifyId2))
            return (
              <>
                <Link
                  style={{ textDecoration: 'none' }}
                  to={`${urls.account.accountPortfolios}/${list.lid}`}
                >
                  <Box sx={{ display: 'grid' }} key={index}>
                    <Text
                      sx={{
                        color: '#051826',
                        padding: '5px 0 5px 0',
                        textTransform: 'uppercase',
                        fontFamily: 'Cormorant Garamond',
                        fontWeight: '500',
                        fontSize: '20px',
                        '&:hover': {
                          opacity: '80%',
                          transition: 'all .3s ease',
                        },
                      }}
                    >
                      {list.lname === 'default' ? 'my favorites' : list.lname}
                    </Text>
                    <Text sx={{ color: '#051826', padding: '5px 0 5px 0', fontSize: '16px' }}>
                      {' '}
                      {list.listcontents.length} {list.listcontents.length === 1 ? 'item' : 'items'}
                    </Text>
                  </Box>
                </Link>
                {/* <Box
                  sx={{
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '5px 0 5px 0',
                    cursor: 'pointer',
                    '&:hover': {
                      opacity: '80%',
                    },
                  }}
                  onClick={(e: any) => {
                    isInWishlist
                      ? removeFromWishlist(list.lid, variant.shopifyId2)
                      : addToWishlist(list.lid, variant.shopifyId2)
                  }}
                >
                  {isInWishlist ? <FolderAdded /> : <Folder />}
                </Box> */}
                <Button
                  onClick={(e: any) => {
                    isInWishlist
                      ? removeFromWishlist({
                          productVariantId: variant.shopifyId2,
                          shopifyId: product.shopifyId,
                          product: {
                            handle: product.handle,
                          },
                          listId: list.lid,
                        })
                      : addToWishlist({
                          productVariantId: variant.shopifyId2,
                          shopifyId: product.shopifyId,
                          product: {
                            handle: product.handle,
                          },
                          listId: list.lid,
                        })
                  }}
                  sxProps={{
                    color: '#2b2b2b',
                    borderColor: '#2b2b2b',
                    minWidth: '100px',
                    '&:hover': {
                      color: '#ffffff',
                      backgroundColor: 'lightGrey',
                      border: 'none',
                      transition: 'all .3s ease',
                    },
                  }}
                  variant="tertiary"
                  label={isInWishlist ? 'REMOVE' : 'ADD'}
                />
              </>
            )
          })}
      </Grid>
      <Box>
        <Button
          label="MANAGE PROJECTS"
          sxProps={{
            backgroundColor: '#ffffff',
            color: '#051826',
            width: '100%',
          }}
          as="link"
          hasArrow={false}
          to={urls.account.accountPortfolios}
        />
      </Box>
    </ReactModal>
  )
}

export default ProjectsModal
