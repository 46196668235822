import React from 'react'
import { SVGComponentProps } from './types'

const MenuDots: SVGComponentProps = ({ ...rest }) => (
  <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_429_1347)">
      <path
        d="M9.00006 3.5C9.82848 3.5 10.5001 2.82843 10.5001 2C10.5001 1.17157 9.82848 0.5 9.00006 0.5C8.17163 0.5 7.50006 1.17157 7.50006 2C7.50006 2.82843 8.17163 3.5 9.00006 3.5Z"
        fill="black"
      />
      <path
        d="M9.00006 11.0002C9.82848 11.0002 10.5001 10.3287 10.5001 9.50024C10.5001 8.67182 9.82848 8.00024 9.00006 8.00024C8.17163 8.00024 7.50006 8.67182 7.50006 9.50024C7.50006 10.3287 8.17163 11.0002 9.00006 11.0002Z"
        fill="black"
      />
      <path
        d="M9.00006 18.4998C9.82848 18.4998 10.5001 17.8282 10.5001 16.9998C10.5001 16.1713 9.82848 15.4998 9.00006 15.4998C8.17163 15.4998 7.50006 16.1713 7.50006 16.9998C7.50006 17.8282 8.17163 18.4998 9.00006 18.4998Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_429_1347">
        <rect width="18" height="18" fill="white" transform="translate(0 0.5)" />
      </clipPath>
    </defs>
  </svg>
)

export default MenuDots
