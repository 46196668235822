import { useEffect, RefObject } from 'react'

type AnyEvent = MouseEvent | TouchEvent

function useOnClickOutside<T extends HTMLElement = HTMLElement>(
  ref: RefObject<T>,
  handler: (event: AnyEvent) => void,
  id?: string
) {
  useEffect(() => {
    const listener = (event: AnyEvent) => {
      const el = ref?.current
      const ev: HTMLElement = event?.target as HTMLElement

      // Do nothing if clicking ref's element or descendent elements
      if (
        !el ||
        el.contains(event.target as Node) ||
        (id && ev.closest(`#${id}`)) ||
        ev.closest(`[data-reach-dialog-overlay]`)
      ) {
        return
      }

      handler(event)
    }

    document.addEventListener(`mousedown`, listener)
    document.addEventListener(`touchstart`, listener)

    return () => {
      document.removeEventListener(`mousedown`, listener)
      document.removeEventListener(`touchstart`, listener)
    }

    // Reload only if ref or handler changes
  }, [ref, handler, id])
}

export default useOnClickOutside
