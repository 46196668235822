/** @jsx jsx */
import { Box, jsx, SxStyleProp, Text } from 'theme-ui'
import { FC, useCallback, useEffect, useState } from 'react'
import { Button } from '~/components/button'
import { useCartContext } from '~/context/cart-context'
import {
  ButtonProps,
  CheckoutLineItemCustomAttribute,
  CheckoutLineItemInput,
  CheckoutProductVariant,
  ProductVariantType,
} from '~/@types/models'
import { trackAddToCart } from '~/utils/marketing/track'
import useQuantityNote from '~/hooks/use-quantity-note'
import { Tag } from '~/componentsV2/tag'
import { FONTS } from '~/styled/components/typography'
import { useProductDetail } from '~/containers/product-detail-v2/Provider'
import algoliasearch from 'search-insights'
import { useAlgoliaTracking } from '~/hooks/use-algolia-tracking'

export type TAddToCartButton = Omit<ButtonProps, 'variant'> & {
  variant: ProductVariantType
  title: string
  customAttributes?: readonly CheckoutLineItemCustomAttribute[]
  sxProps?: SxStyleProp
  initialQuantity?: number
  buttonLabel?: string
  buttonVariant?: string
  hasArrow?: boolean
  productType?: string
  buttonType?: string
  onCustomCart?: () => void
  algoliaData?: any
  callback?: () => void
  triggerEvent?: boolean
}

export const AddToCartButton: FC<TAddToCartButton> = ({
  variant,
  title,
  customAttributes = [],
  initialQuantity = 1,
  sxProps = {},
  buttonLabel = 'Add to Cart',
  buttonVariant = 'primary',
  hasArrow = false,
  productType,
  buttonType,
  onCustomCart,
  callback,
  triggerEvent = true,
  label,
  ...rest
}) => {
  const { saveItem, setOverlayOpen, saveItems } = useCartContext()
  const { getNoteBySelectedQuantity } = useQuantityNote({})
  const openCart = () => (onCustomCart ? onCustomCart() : setOverlayOpen(true))
  const { currentBundle } = useProductDetail()
  const { algoliaConversionEvent, mountAlgoliaData } = useAlgoliaTracking()
  const algoliaData = mountAlgoliaData([variant.shopifyId2])

  const handleAlgoliaConversion = () => {
    if (algoliaData && algoliaData.queryID) {
      algoliasearch('convertedObjectIDsAfterSearch', algoliaData)
    }
  }

  const handleAddToCart = useCallback(async (): Promise<void> => {
    if (triggerEvent) {
      //Send event to algolia
      handleAlgoliaConversion()
      algoliaConversionEvent([variant.shopifyId2])
    }

    const message = getNoteBySelectedQuantity(variant.quantityAvailable, 1)

    if (typeof window !== 'undefined') {
      const isCartPage = window.location.pathname.includes('/cart')
      if (!isCartPage) {
        openCart()
      }
    }

    if (currentBundle) {
      const items: any[] = currentBundle.products.reduce((acc: any[], item) => {
        const existingItem = acc.find(accItem => accItem.variant.shopifyId === item.id)

        if (existingItem) {
          existingItem.product.quantity = existingItem.product.quantity + 1
        } else {
          const _variant = item?.variants ? item.variants[0] : item
          const product: CheckoutLineItemInput = {
            variantId: _variant.id,
            quantity: 1,
            customAttributes: [
              ...customAttributes,
              {
                key: `Quantity`,
                value: message || '',
              },
            ],
          }
          const imageSrc = _variant.image?.originalSrc || variant.image?.srcMobile
          const title = item.title

          acc.push({
            product,
            title,
            variant: {
              ...((_variant as unknown) as CheckoutProductVariant),
              image: { transformedSrc: imageSrc },
              shopifyId: _variant.id,
              quantity: item.quantity,
            },
          })
        }

        return acc
      }, [])

      const content_ids = items.map(item => item.variant.shopifyId)
      const contents = items.map(item => {
        return {
          id: item.variant.shopifyId,
          quantity: item.product.quantity,
        }
      })

      if (triggerEvent) {
        trackAddToCart({
          content_ids,
          contents,
          currency: variant.price.currencyCode,
          value: variant.price.amount,
          name: `${title} - ${variant.title}`,
          color: variant.colorName,
          item_category: productType,
        })
      }

      await saveItems(items)
    } else {
      const product: CheckoutLineItemInput = {
        variantId: variant.shopifyId,
        quantity: initialQuantity,
        customAttributes: [
          ...customAttributes,
          {
            key: `Quantity`,
            value: message || '',
          },
        ],
      }

      const imageSrc = variant.image?.originalSrc

      await saveItem(product, title, {
        ...((variant as unknown) as CheckoutProductVariant),
        image: imageSrc ? { transformedSrc: imageSrc } : variant.image,
      } as CheckoutProductVariant)

      if (triggerEvent) {
        trackAddToCart({
          content_ids: [variant.shopifyId2],
          contents: [{ id: variant.shopifyId2, quantity: product.quantity }],
          currency: variant.price.currencyCode,
          value: variant.price.amount,
          name: `${title} - ${variant.title}`,
          color: variant.colorName,
          item_category: productType,
        })
      }
    }

    if (callback) {
      callback()

      return
    }
  }, [saveItem, variant, initialQuantity, currentBundle])

  // useEffect(() => {
  //   console.log(queue, readyQueue)
  //   if (queue.length > 0 && readyQueue) {
  //     const _queue = [...queue]
  //     const item = _queue.shift()

  //     setReadyQueue(false)
  //     const sendItem = async () => {
  //       setQueue(_queue)
  //       await saveItem(item.product, item.title, item.variant)

  //       setReadyQueue(true)
  //     }

  //     sendItem()
  //   }
  // }, [queue, readyQueue])

  if (buttonType === 'text') {
    return (
      <Text
        sx={{
          cursor: 'pointer',
          fontWeight: 400,
          textDecoration: 'underline',
          paddingLeft: ['8px', '24px', '24px'],
          fontSize: ['14px', '16px', '16px'],
        }}
        onClick={handleAddToCart}
        {...rest}
      >
        {label}
      </Text>
    )
  } else if (buttonType === 'tag') {
    return (
      <Tag
        style={{
          padding: '3px',
          cursor: 'pointer',
        }}
        bgColor="transparent"
        onClick={handleAddToCart}
        {...rest}
      >
        <Box
          sx={{
            fontFamily: FONTS.Helvetica,
            padding: '5px 10px',
            backgroundColor: '#EDEDED',
          }}
        >
          {label}
        </Box>
      </Tag>
    )
  } else {
    return (
      <Button
        className="add-to-cart-button"
        variant={buttonVariant}
        onClick={handleAddToCart}
        type="button"
        hasArrow={hasArrow}
        sxProps={sxProps}
        data-cy="add-to-cart"
        label={label || buttonLabel}
        {...rest}
      />
    )
  }
}
