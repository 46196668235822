import React from 'react'
import { SVGComponentProps } from './types'

const Facebook: SVGComponentProps = () => (
  <svg width="7" height="14" viewBox="0 0 7 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.31093 4.62977H6.70303L6.53619 6.724H4.31089V14H1.50013V6.72397H0V4.62973H1.50013V3.22227C1.50013 2.60307 1.51642 1.64573 1.98315 1.05297C2.47498 0.426233 3.15043 0 4.31093 0C6.20243 0 7 0.2597 7 0.2597L6.62484 2.40467C6.62484 2.40467 6.00007 2.23017 5.41663 2.23017C4.83326 2.23017 4.31096 2.4311 4.31096 2.9945V4.62977H4.31093Z"
      fill="white"
    />
  </svg>
)

export default Facebook
