/** @jsx jsx **/
import { Grid, jsx, Box } from 'theme-ui'
import { FC } from 'react'
import { LinkType } from '~/@types/models'
import Facebook from '~/components/icons/facebook'
import Instagram2 from '~/components/icons/instagram2'
import LinkedIn from '~/components/icons/linkedIn'
import Pinterest2 from '~/components/icons/pinterest2'

enum OptionType {
  Facebook = 'Facebook',
  Instagram = 'Instagram',
  LinkedIn = 'LinkedIn',
  Pinterest = 'Pinterest',
}
type Props = {
  name: string
  links: readonly LinkType[]
  sxProps?: object
}

interface LinkProps {
  url: string
  label: string
}

const FooterLogoLinks: FC<Props> = ({ name, links, sxProps = {} }) => {
  const logoProps = {
    sx: {
      letterSpacing: 0.7,
    },
    target: '_blank',
    rel: 'noopener noreferrer',
  }
  const logoMapper = ({ url, label, ...rest }: LinkProps) => {
    switch (label) {
      case OptionType.Facebook:
        return (
          <a href={url} {...rest} key={label} {...logoProps}>
            <Facebook />
          </a>
        )
      case OptionType.Instagram:
        return (
          <a href={url} {...rest} key={label} {...logoProps} sx={{ marginLeft: -2 }}>
            <Instagram2 />
          </a>
        )
      case OptionType.Pinterest:
        return (
          <a href={url} {...rest} key={label} {...logoProps} sx={{ paddingLeft: 4 }}>
            <Pinterest2 />
          </a>
        )
      case OptionType.LinkedIn:
        return (
          <a href={url} {...rest} key={label} {...logoProps}>
            <LinkedIn />
          </a>
        )
      default:
        return (
          <a href={url} {...rest} key={label} {...logoProps}>
            {label}
          </a>
        )
    }
  }
  return (
    <Box sx={{ paddingRight: 5 }}>
      <h3
        sx={{
          fontSize: [18, null, 18],
          fontWeight: '700 !important',
          letterSpacing: 1.95,
        }}
      >
        {name.toLocaleUpperCase()}
      </h3>
      <Grid
        columns={4}
        sx={{
          gap: 10,
          pb: [50, null, null, null],
        }}
      >
        {links.map(({ url, label, ...rest }) => logoMapper({ url, label, ...rest }))}
      </Grid>
    </Box>
  )
}

export default FooterLogoLinks
