/* eslint-disable import/no-cycle */
import { useCallback, useEffect, useMemo, useState } from 'react'
import { ProductVariantType, ShopifyConfigurationProduct } from '~/@types/models'
import { useCustomerContext } from '~/context/customer-context'
import { useProductVariant } from './useProductVariant'
import { formatMoney } from '~/utils/format'
import {
  IBundle,
  IBundleProduct,
  IBundleSectional,
  ICurrentBundle,
  ISectionalTypes,
  TOptions,
  UseBundleProps,
} from './useBundle.types'
import { parseStringKeys, parserCurrent } from './useBundle.utils'

export const useBundle = ({ product }: UseBundleProps) => {
  const [currentBundleState, setCurrentBundle] = useState<{
    label: string
    type: 'products' | 'sectionals'
  } | null>(null)
  const {
    customerType: { trade: isTradeCustomer },
  } = useCustomerContext()
  const productVariant = useProductVariant({ product })
  const [onlyFrames, setOnlyFrames] = useState(true)

  const { bundles, sectionalsProducts, sectionalType, sectionalCushion } = useMemo<{
    bundles?: IBundle
    sectionalsProducts?: ISectionalTypes
    sectionalType?: string | null
    sectionalCushion?: any
  }>((): any => {
    if (product.configuration) {
      const _bundles: IBundle = {}
      const sectionalsTypes: ISectionalTypes = {}
      let sectionalType: string | null = null
      let sectionalCushion: any = null

      product.configuration.forEach(config => {
        config.contents.forEach(item => {
          if (item.products) {
            const _bundle: IBundleProduct = {
              label: parseStringKeys(item.label),
              images: item.image,
              items: [] as ShopifyConfigurationProduct[],
            }
            if (!_bundles.products) _bundles.products = []
            _bundle.items = item.products
            _bundles.products.push(_bundle)
          }

          if (item?.sectionals) {
            const _bundle: IBundleSectional = {
              label: parseStringKeys(item.label),
              images: item.image,
              items: [],
            }
            if (!_bundles.sectionals) _bundles.sectionals = []
            const sectionals = item.sectionals

            sectionals.forEach(sectional => {
              sectional = sectional.split('#')[0].trim()
              if (!sectionalsTypes[sectional]) {
                sectionalsTypes[sectional] = { products: [] }
              }

              const products: ProductVariantType[] = []

              product.variants.forEach(variant => {
                const hasOption = variant.selectedOptions.some(option => {
                  if (!sectionalType && option.value === sectional) {
                    sectionalType = option.name
                  }
                  return option.value === sectional
                })

                if (hasOption) {
                  products.push(variant)
                }
              })

              sectionalsTypes[sectional].products = products
            })

            _bundle.items = item.sectionals.map(sectional => {
              return sectional.split('#')[0].trim()
            })

            _bundles.sectionals.push(_bundle)
          }

          if (item?.cushion) {
            const variants: any = {}

            Object.keys(item.cushion.variants).forEach(key => {
              variants[key.replace('Chair', '').trim()] = item.cushion.variants[key]
            })
            sectionalCushion = {
              variants,
              label: parseStringKeys(item.label),
              images: item.image,
              options: {
                id: 'sectionalCushion',
                label: 'Cushions',
                values: ['Frame Only', 'Frame and Cushion'],
              },
            }
          }
        })
      })

      return {
        bundles: _bundles || {},
        sectionalsProducts: sectionalsTypes,
        sectionalType,
        sectionalCushion,
      }
    }
    return { bundles: null, sectionalsProducts: null, sectionalType: null, sectionalCushion: null }
  }, [])

  const filterProductsVariants = useCallback(
    (bundle?: IBundleProduct) => {
      if (!bundles || !bundle) return null

      const selectedOptions = productVariant.selectedOptions.map(option => option.value)

      if (bundle?.items) {
        const parserBundle = {
          ...bundle,
          items: [] as any,
        }

        bundle.items.forEach(item => {
          let _item: any = {
            ...item,
          }

          if (item?.variants) {
            if (item.variants.length > 1) {
              item.variants.forEach(variant => {
                if (
                  variant.selectedOptions.every(option => selectedOptions.includes(option.value))
                ) {
                  _item = {
                    ...item,
                    price: variant.price,
                    compareAtPrice: variant.compareAtPrice,
                    title: `${item.title} | ${variant.title}`,
                    variants: [variant],
                  }
                  parserBundle.items.push(_item)
                }
              })
            } else {
              _item = {
                ...item,
                price: item.variants[0].price,
                title: item.title,
                compareAtPrice: item.variants[0].compareAtPrice,
                variants: [item.variants[0]],
              }
              parserBundle.items.push(_item)
            }
          } else {
            _item = {
              ...item,
              price: item.price,
              title: item.title,
              variants: [item],
            }

            parserBundle.items.push(_item)
          }
        })

        return parserBundle
      }
    },
    [bundles?.products, productVariant]
  )

  const getSectionalsProducts = (bundle?: IBundleSectional) => {
    if (!bundle?.items || !bundle || !sectionalsProducts) return null

    const optionToMatch = productVariant.selectedOptions.find(
      option => option.name !== sectionalType
    )

    const products: ProductVariantType[] = []

    bundle.items.map(item => {
      const _products = sectionalsProducts[item].products.filter(product => {
        const isMatch = product.selectedOptions.some(option => {
          return option.value === optionToMatch?.value
        })

        return isMatch
      })[0]

      if (_products) {
        products.push(_products)
      }

      return null
    })

    return products
  }

  const { productsOptions, sectionalsOptions } = useMemo(() => {
    const options: Record<string, TOptions> = {}

    if (bundles?.products) {
      const filterBundles = bundles.products.map(item => {
        const _item = filterProductsVariants(item)

        return _item
      })

      const productsOptions = {
        label: 'Selection',
        id: 'configurations',
        stockControl: filterBundles.map(item => {
          return {
            label: (item?.images || item?.label) as string,
            currentlyNotInStock: item?.items.some(item =>
              item?.variants.find(variant => variant.currentlyNotInStock)
            ),
          }
        }),
        values: filterBundles.map(item => {
          return (item?.images || item?.label) as string
        }),
      }

      options['productsOptions'] = productsOptions
    }

    if (bundles?.sectionals && sectionalType) {
      const values: string[] = []
      const stockControl: Array<{
        label: string
        currentlyNotInStock: boolean
      }> = []

      bundles.sectionals.map(item => {
        const products = getSectionalsProducts(item)

        if (products) {
          stockControl.push({
            label: item?.images || item?.label,
            currentlyNotInStock: products.some(item => item.currentlyNotInStock),
            disabled: products.length < item?.items?.length,
          })
        }

        values.push(item.images || item.label)

        return products
      })

      const sectionalsOptions = {
        label: 'Selection',
        id: 'sectionals',
        values,
        stockControl,
      }

      options['sectionalsOptions'] = sectionalsOptions
    }

    return options
  }, [bundles, productVariant])

  const parserBundle = (bundle, isSectional = false) => {
    if (bundle && (productsOptions || sectionalsOptions)) {
      const _bundle = {
        ...bundle,
      }

      if (isSectional) {
        const products = getSectionalsProducts(_bundle)
        _bundle.items = products
      }

      const current = filterProductsVariants(_bundle)

      if (current?.items) {
        // If onlyFrames is false, include the products inside cushions
        if (!onlyFrames && sectionalCushion) {
          const currentOptions = _bundle.label.split('+')

          const cushionVariants: any = []
          currentOptions.map(option => {
            if (sectionalCushion.variants[option.trim()]) {
              cushionVariants.push(sectionalCushion.variants[option.trim()])
            }

            return null
          })

          if (cushionVariants.length > 0) {
            current.frameOnly = false
            current.items.push(...cushionVariants)
          }
        } else if (onlyFrames && sectionalCushion) {
          current.frameOnly = true
        }

        return parserCurrent(current, isTradeCustomer)
      }
    }

    return null
  }

  const currentBundle = useMemo(() => {
    if (!bundles && !productsOptions?.stockControl && !sectionalsOptions?.stockControl) return null
    if (currentBundleState?.type && bundles && bundles[currentBundleState.type]) {
      const bundle = bundles[currentBundleState.type]?.find(
        bundle =>
          bundle.images === currentBundleState.label || bundle.label === currentBundleState.label
      )
      if (!bundle) return null

      const currentlyNotInStock =
        currentBundleState.type === 'sectionals'
          ? sectionalsOptions.stockControl.find(item => item.label === currentBundleState.label)
              ?.currentlyNotInStock
          : productsOptions.stockControl.find(item => item.label === currentBundleState.label)
              ?.currentlyNotInStock
      // const preOrderMessage = currentBundleState.type === 'products'

      const parsedBundle = parserBundle(bundle, currentBundleState.type === 'sectionals')

      return {
        ...parsedBundle,
        currentlyNotInStock,
      }
    } else if (bundles) {
      let currentBundle: IBundleProduct | IBundleSectional | null = null
      let currentlyNotInStock = false
      if (bundles?.products?.length) {
        currentBundle = parserBundle(bundles.products[0])
        currentlyNotInStock = !!productsOptions.stockControl.find(
          item => item.label === currentBundle?.label
        )?.currentlyNotInStock
      } else if (bundles.sectionals) {
        currentBundle = parserBundle(bundles.sectionals[0], true)
        if (currentBundle) {
          currentlyNotInStock = !!currentBundle.products.some(item => item.currentlyNotInStock)
        }
      }

      return {
        ...currentBundle,
        currentlyNotInStock,
      }
    }

    return null
  }, [
    currentBundleState,
    filterProductsVariants,
    productsOptions,
    sectionalsOptions,
    bundles,
    onlyFrames,
  ])

  const handleBundleClick = useCallback(
    (label: string, type: 'products' | 'sectionals') => {
      setCurrentBundle({
        label,
        type,
      })
    },
    [bundles, filterProductsVariants]
  )

  const handleFrameClick = useCallback((frame: boolean) => {
    setOnlyFrames(frame)
  }, [])

  return {
    bundles,
    currentBundle,
    handleBundleClick,
    handleFrameClick,
    productsOptions,
    sectionalsOptions,
    sectionalType,
    sectionalCushion,
  }
}
