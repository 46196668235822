import React, { Fragment, ElementType, ReactNode, useState, useRef, useEffect } from 'react'
import { uniqueId } from 'lodash'
import sanitize from 'sanitize-html'
import { RichTextContainer, RichTextWrapper } from '~/styled/components/richTextContainer'

interface RichText {
  type: string
  children: RichText[]
  value: string
  bold: boolean
  italic: boolean
  listType: string
  url: string
  title: string
  target: string
  level: number
}

export function parseRichTextToComponent(json: RichText): ReactNode | null {
  if (json.type === 'root') {
    return (
      <div>
        {json.children.map((child, index) => (
          <Fragment key={uniqueId(`json.type-${index}`)}>
            {parseRichTextToComponent(child)}
          </Fragment>
        ))}
      </div>
    )
  } else if (json.type === 'paragraph') {
    return (
      <p>
        {json.children.map((child, index) => (
          <Fragment key={uniqueId(`json.type-${index}`)}>
            {parseRichTextToComponent(child)}
          </Fragment>
        ))}
      </p>
    )
  } else if (json.type === 'text') {
    if (json.bold) {
      return <strong>{json.value}</strong>
    }
    if (json.italic) {
      return <em>{json.value}</em>
    }
    return json.value
  } else if (json.type === 'list') {
    const listType = json.listType === 'ordered' ? 'ol' : 'ul'
    return React.createElement(
      listType,
      null,
      json.children.map((child, index) => (
        <li key={uniqueId(`json.type-${index}`)}>{parseRichTextToComponent(child)}</li>
      ))
    ) as JSX.Element
  } else if (json.type === 'list-item') {
    return (
      <li>
        {json.children.map((child, index) => (
          <Fragment key={uniqueId(`json.type-${index}`)}>
            {parseRichTextToComponent(child)}
          </Fragment>
        ))}
      </li>
    )
  } else if (json.type === 'link') {
    const href = json.url || ''
    const title = json.title || ''
    const target = json.target || '_blank'
    return (
      <a href={href} title={title} target={target}>
        {json.children.map((child, index) => (
          <Fragment key={uniqueId(`json.type-${index}`)}>
            {parseRichTextToComponent(child)}
          </Fragment>
        ))}
      </a>
    )
  } else if (json.type.startsWith('heading') && json.level >= 1 && json.level <= 6) {
    const level = json.level
    const HeadingComponent: ElementType = `h${level}` as ElementType
    return (
      <HeadingComponent>
        {json.children.map((child, index) => (
          <Fragment key={uniqueId(`json.type-${index}`)}>
            {parseRichTextToComponent(child)}
          </Fragment>
        ))}
      </HeadingComponent>
    )
  }

  return null
}

export function parseRichText(json: RichText): string {
  if (json.type === 'root') {
    return json.children.map(parseRichText).join('')
  } else if (json.type === 'paragraph') {
    return `<p>${json.children.map(parseRichText).join('')}</p>`
  } else if (json.type === 'text') {
    if (json.bold) {
      return `<strong>${json.value}</strong>`
    }
    if (json.italic) {
      return `<em>${json.value}</em>`
    }
    return json.value
  } else if (json.type === 'list') {
    const listType = json.listType === 'ordered' ? 'ol' : 'ul'
    return `<${listType}>${json.children.map(parseRichText).join('')}</${listType}>`
  } else if (json.type === 'list-item') {
    return `<li>${json.children.map(parseRichText).join('')}</li>`
  } else if (json.type === 'link') {
    const href = json.url || ''
    const title = json.title || ''
    const target = json.target || '_blank'
    return `<a href="${href}" title="${title}" target="${target}">${json.children
      .map(parseRichText)
      .join('')}</a>`
  } else if (json.type.startsWith('heading') && json.level >= 1 && json.level <= 6) {
    const level = json.level
    return `<h${level}>${json.children.map(parseRichText).join('')}</h${level}>`
  }

  return ''
}

export function getDescriptionToComponent(str: string): ReactNode | null {
  try {
    const parsed = JSON.parse(str)
    return parseRichTextToComponent(parsed)
  } catch (e) {
    return str
  }
}

export function getDescription(str: string): string {
  try {
    const parsed = JSON.parse(str)
    return parseRichText(parsed)
  } catch (e) {
    return str
  }
}

export function RenderParserdHTML({ html }: { html: string }) {
  const [isTruncated, setIsTruncated] = useState<'show' | 'hide' | null>('hide')
  const containerRef = useRef<HTMLDivElement>(null)
  const numberOfLines = 2

  useEffect(() => {
    if (containerRef.current) {
      const containerHeight = containerRef.current.clientHeight
      setIsTruncated(containerHeight > numberOfLines * 20 ? 'show' : null)
    }
  }, [])

  const toggleIsTruncated = () => setIsTruncated(isTruncated === 'show' ? 'hide' : 'show')

  return (
    <RichTextWrapper>
      <RichTextContainer
        isTruncated={isTruncated === 'show'}
        numberOfLines={numberOfLines}
        ref={containerRef}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: sanitize(html) }}
      />

      {isTruncated === 'show' && (
        <button type="button" onClick={toggleIsTruncated}>
          Read more
        </button>
      )}
      {isTruncated === 'hide' && (
        <button type="button" onClick={toggleIsTruncated}>
          Read less
        </button>
      )}
    </RichTextWrapper>
  )
}
