/** @jsx jsx */

import React, { FC, Fragment } from 'react'
import { jsx, SxStyleProp, Button } from 'theme-ui'
import { useCartContext } from '~/context/cart-context'
import CartOverlay from '~/components/cart-overlay'
import CartOverlayContent from '~/components/cart-overlay/cart-overlay-content'

type Props = {
  sxProps?: SxStyleProp
}

const HeaderCartOpener: FC<Props> = ({ sxProps }) => {
  const { totalQuantity, overlayOpen, setOverlayOpen } = useCartContext()
  const openOverlay = () => setOverlayOpen(true)
  const closeOverlay = () => setOverlayOpen(false)
  return (
    <Fragment>
      <Button
        variant="noStyle2"
        onClick={openOverlay}
        sx={{
          ...sxProps,
        }}
      >
        Cart ({totalQuantity})
      </Button>
      <CartOverlay showDialog={overlayOpen} close={closeOverlay}>
        <CartOverlayContent closeCartOverlay={closeOverlay} />
      </CartOverlay>
    </Fragment>
  )
}

export default HeaderCartOpener
