/** @jsx jsx */
import { Container, Text, jsx } from 'theme-ui'
import { RenderParserdHTML, getDescription } from '~/utils/richTextFormats'

type Props = {
  description?: string
}

const CollectionDescription = ({ description }: Props) => {
  if (!description) return null

  const parsedDescription = getDescription(description)

  return (
    <Container
      sx={{
        pt: 10,
        pb: 20,
      }}
    >
      <Text
        variant="micro"
        sx={{
          textAlign: 'center',
          display: ['block'],
          '*': {
            fontSize: '12px !important',
          },
        }}
      >
        <div>
          <RenderParserdHTML html={parsedDescription} />
        </div>
      </Text>
    </Container>
  )
}

export default CollectionDescription
