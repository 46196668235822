import { useMemo } from 'react'
import { ProductDetailPageQuery, ProductVariantType } from '~/@types/models'

interface UseProductSwatchesProps {
  product: ProductDetailPageQuery['product']
  productVariant: ProductVariantType
}

export const useProductSwatches = ({ product, productVariant }: UseProductSwatchesProps) => {
  return useMemo(() => {
    const variantSwatches = product.variants.filter(variant => variant.titleSize === 'Swatch')
    if (variantSwatches.length > 0) {
      return variantSwatches
    }
    if (!product.productSwatches) {
      return []
    }

    return product.productSwatches.products
      .filter(product => !product.tags.includes('not-order-swatch'))
      .map(productSwatch => ({
        ...productSwatch,
        image: productSwatch.mainImage && productSwatch.mainImage.srcXSmall,
        id: productSwatch.cheapestVariant?.shopifyId,
      }))
  }, [product.productSwatches])
}
