/** @jsx jsx **/
import { jsx, SxStyleProp } from 'theme-ui'
import { FC } from 'react'
import { Link } from 'gatsby'
import { LogoBenSoleimani } from '~/components/icons/logoBenSoleimani'
import urls from '~/common/urls'
import VisuallyHidden from '@reach/visually-hidden'

interface Props {
  color?: string
  sxProps?: SxStyleProp
  width?: string
}

export const Logo: FC<Props> = ({ color, sxProps, width }) => {
  return (
    <Link
      sx={{
        display: 'block',
        width: ['213px', '213px', '350px'],
        ...sxProps,
      }}
      to={urls.pages.base}
    >
      <VisuallyHidden>Ben Soleimani</VisuallyHidden>
      <LogoBenSoleimani color={color} width={width} />
    </Link>
  )
}
